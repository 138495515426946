import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useCurrentSubmission } from '../../../../hooks/submission';
import { useLazyGetAlternateNamesQuery, usePostAlternateNamesMutation } from '../../../../services/apiSlice';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { Types } from '../../../../ts-types/icubed-types';
import { ColumnType, TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { updateRowData, loadLiteTableRows, resetTable, setupTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { closeCenteredModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';

export type AlternativesNamesAppProps = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
};

type AddressFields = {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipcode: string;
};

export type AlternateNameEditType = {
    id?: number;
    submission: number;
    arch_account_id: string | null;
    action: string;
    processing_status: string | null;
    arch_alternate_name_id: number | null;
    type_code: string;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    vmac_id: number | null;
    processing_info?: string;
};

export enum AlternateNameActionType {
    EDIT = 'Edit',
    DELETE = 'Delete',
    NONE = 'None',
    ABORT = 'Abort',
}

export const AlternateNameTypes = {
    DBA: 'Doing Business As (DBA)',
    AKA: 'Also Known As (AKA)',
    NMI: 'Named Insured (NMI)',
    ANI: 'Additional Named Insured (ANI)',
    GEN: 'General Contractor (GEN)',
    LGL: 'Legal Name (LGL)',
    JVP: 'Joint Venture Partner (JVP)',
    FKA: 'Formerly Known As (FKA)',
    SUB: 'Submission Name (SUB)',
};

const PRIMARY_ADDRESS_INDICATOR = '* ';

const AlternativesNamesApp = (props: AlternativesNamesAppProps) => {
    const [reuseAddressChoices, setReuseAddressChoices] = useState<AddressFields[]>([]);
    const [tableDataDidLoad, setTableDataDidLoad] = useState(false);
    const [originalRowData, setOriginalRowData] = useState<Record<number, TableRowDataType>>({});
    const [isInitiallyEmpty, setIsInitiallyEmpty] = useState<boolean>(true);

    const tableData = useAppSelector(states => states.alternativeNames);
    const currentSubmission = useCurrentSubmission();

    const CONSTANTS = useAppSelector(states => states.global.CONSTANTS);
    const isSmmsSubmission = currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS;

    const [getExistingAltNames, { isFetching, isLoading }] = useLazyGetAlternateNamesQuery();
    const [saveAltNameEdits, { isSuccess, isError, isLoading: postIsLoading, error }] = usePostAlternateNamesMutation();
    const dispatch = useAppDispatch();
    const glossary = LITE_TABLES.ALTERNATIVENAMES.tableGlossary;
    glossary.ac.choices = Object.keys(AlternateNameActionType).map(action => [AlternateNameActionType[action], action]);
    glossary.t.choices = Object.keys(AlternateNameTypes).map(type => [type, AlternateNameTypes[type]]);

    const handleAddNewAltName = () => {
        const newAltNameIndex = tableData.rows.length + 1;
        const newAltName: TableRowDataType = {
            id: newAltNameIndex,
            action: 'Add', // Change this to the constant eventually
            processing_status: null,
            processing_message: null,
            arch_alternate_name_id: null,
            type_code: 'DBA', // Probably should a constant too and dropdown.
            name: null,
            address1: currentSubmission.arch_account.street,
            address2: currentSubmission.arch_account.street2,
            city: currentSubmission.arch_account.city,
            state: currentSubmission.arch_account.state, // probably state from constants
            zipcode: currentSubmission.arch_account.zipcode,
            country: 'US',
        };

        // Handles the case with no pre-existing data
        if (isInitiallyEmpty) {
            dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, [newAltName]));
            setIsInitiallyEmpty(false);
            return;
        }
        const updatedRowData = [];
        Object.keys(tableData.rowData).forEach(rowId => {
            if (rowId !== '_errors') {
                updatedRowData.push(tableData.rowData[rowId]);
            }
        });
        dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, [...updatedRowData, newAltName]));
    };

    const debouncedHandleAddNewAltName = useDebounceCallback(handleAddNewAltName, 500);

    const handleDeleteRow = (rowId: number) => {
        const rowDataKeys = Object.keys(tableData.rowData).filter(key => key !== '_errors');
        const newRowData = [];
        rowDataKeys.forEach(key => {
            const rowData = tableData.rowData;
            if (parseInt(key) < rowId) {
                newRowData.push(rowData[key]);
            } else if (parseInt(key) > rowId) {
                const newId = parseInt(key) - 1;
                newRowData.push({ ...rowData[key], id: newId });
            }
        });

        // Hacking my way through because the deleteRow action does not work for some reason
        dispatch(resetTable(LITE_TABLES.ALTERNATIVENAMES));
        dispatch(setupTable({ tableConfig: LITE_TABLES.ALTERNATIVENAMES }));
        dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, newRowData));
    };

    const renderActionButtons = () => {
        return (
            <div className="row-actions grid-layout gl-1 margin-top-05">
                <button className="tw-bg-grey-light" onClick={debouncedHandleAddNewAltName} disabled={isSmmsSubmission || isLoading || isFetching}>
                    <span className="blue tw-font-semibold">ADD NEW ALTERNATIVE NAME</span>
                </button>
            </div>
        );
    };

    const updateRowBasedOnPreviousAddress = (id: number, update: TableRowDataType, column: ColumnType) => {
        const selectedAddressRowData = reuseAddressChoices[update[column.key] as number];
        const row = tableData.rowData[id];
        const newAction = row.action === null ? 'Edit' : row.action;
        const originalRowDataExists = Object.keys(originalRowData).some(rowId => parseInt(rowId) === id);
        const currentAction = tableData.rowData[id].action;

        if (currentAction === null && newAction === 'Edit' && !originalRowDataExists) {
            setOriginalRowData(previous => {
                return { ...previous, [id]: row };
            });
        }

        const newData = {
            ...row,
            // remove asterisk
            address1: selectedAddressRowData['address1'].slice(
                selectedAddressRowData['address1'].indexOf(PRIMARY_ADDRESS_INDICATOR) < 0 ? 0 : PRIMARY_ADDRESS_INDICATOR.length
            ),
            address2: selectedAddressRowData['address2'],
            city: selectedAddressRowData['city'],
            state: selectedAddressRowData['state'],
            zipcode: selectedAddressRowData['zipcode'],
            action: newAction,
        };

        dispatch(updateRowData(LITE_TABLES.ALTERNATIVENAMES, newData));
    };

    const isDeleteActionAllowed = (id: number, update: TableRowDataType) => {
        return update['action'] === 'Delete' && tableData.rowData[id].action === null;
    };

    const isEditActionAllowed = (id: number, update: TableRowDataType) => {
        return update['action'] === 'Edit' && tableData.rowData[id].action === null;
    };

    const onCellEdit = (index: number, id: number, update: TableRowDataType, column: ColumnType) => {
        if (isSmmsSubmission) {
            return;
        } else if (column.key === 'reuseAddress') {
            updateRowBasedOnPreviousAddress(id, update, column);
        } else if (column.key === 'type_code' && update['type_code'] !== 'DBA' && update['type_code'] !== 'ANI') {
            // Disables changes of Alt name type to something that is not DBA or ANI
            return;
        } else {
            const isActionChanging = column.key === 'action';
            const currentAction = tableData.rowData[id].action;
            let newAction = currentAction;
            if (isActionChanging && update['action'] === AlternateNameActionType.DELETE) {
                if (isDeleteActionAllowed(id, update)) {
                    newAction = AlternateNameActionType.DELETE;
                } else {
                    return;
                }
            }
            if (isActionChanging && update['action'] === AlternateNameActionType.EDIT) {
                if (isEditActionAllowed(id, update)) {
                    newAction = AlternateNameActionType.EDIT;
                } else {
                    return;
                }
            }
            if (
                isActionChanging &&
                update['action'] === AlternateNameActionType.NONE &&
                (currentAction === AlternateNameActionType.NONE || currentAction === null)
            ) {
                return;
            }
            if (!isActionChanging && (currentAction === null || currentAction === AlternateNameActionType.NONE)) {
                newAction = AlternateNameActionType.EDIT;
            }

            const originalRowDataExists = Object.keys(originalRowData).some(rowId => parseInt(rowId) === id);

            if (
                currentAction === null &&
                (newAction === AlternateNameActionType.EDIT || newAction === AlternateNameActionType.DELETE) &&
                !originalRowDataExists
            ) {
                const rowData = tableData.rowData[id];
                setOriginalRowData(previous => {
                    return { ...previous, [id]: rowData };
                });
            }

            if (isActionChanging && update['action'] === AlternateNameActionType.NONE) {
                if (currentAction === AlternateNameActionType.EDIT || currentAction === AlternateNameActionType.DELETE) {
                    const rowData = originalRowData[id];
                    if (rowData) {
                        dispatch(updateRowData(LITE_TABLES.ALTERNATIVENAMES, { ...rowData }));
                        return;
                    }
                } else if (currentAction === 'Add') {
                    handleDeleteRow(id);
                    return;
                }
            }

            if (
                isActionChanging &&
                update['action'] === AlternateNameActionType.ABORT &&
                (currentAction === 'Pending' || currentAction === 'Error' || currentAction === 'Conflict')
            ) {
                newAction = AlternateNameActionType.ABORT;
            }

            dispatch(
                updateRowData(LITE_TABLES.ALTERNATIVENAMES, {
                    ...tableData.rowData[id],
                    [column.key]: update[column.key],
                    action: newAction,
                })
            );
        }
    };

    const handleClose = () => {
        dispatch(closeCenteredModal());
        dispatch(resetTable(LITE_TABLES.ALTERNATIVENAMES));
    };

    const handleSave = () => {
        const filteredEdits: AlternateNameEditType[] = [];
        Object.keys(tableData.rowData)
            .filter(rowId => rowId !== '_errors')
            .forEach(rowId => {
                const row = tableData.rowData[rowId];
                if (row.action !== null && row.action !== 'Main') {
                    filteredEdits.push({
                        id: row.id,
                        action: row.action as string,
                        processing_status: row.processing_status as string,
                        processing_info: row.processing_info as string,
                        arch_alternate_name_id: row.arch_alternate_name_id as number,
                        type_code: row.type_code as string,
                        name: row.name as string,
                        address1: row.address1 as string,
                        address2: row.address2 as string,
                        city: row.city as string,
                        state: row.state as string,
                        zipcode: row.zipcode as string,
                        country: row.country as string,
                        vmac_id: row.vmac_id ? parseInt(row.vmac_id as string) : null,
                        arch_account_id: currentSubmission.arch_account_arch_id as string,
                        submission: currentSubmission.id,
                    });
                }
            });

        try {
            saveAltNameEdits({
                submission_id: currentSubmission.id,
                edits: filteredEdits,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const debouncedHandleSave = useDebounceCallback(handleSave, 500);

    const getErrors = () => {
        if ('data' in error) {
            const data = error.data as Record<string, any>;
            if ('details' in data) {
                // set errors on the record if there's a valid ID
                if ('id' in data) {
                    // setting null first to clear out any previous errors, otherwise UI won't update
                    for (const rowId of Object.keys(tableData.rowData).filter(rowId => rowId !== '_errors')) {
                        dispatch(updateRowData(LITE_TABLES.ALTERNATIVENAMES, { ...tableData.rowData[rowId], _errors: null }));
                    }
                    dispatch(updateRowData(LITE_TABLES.ALTERNATIVENAMES, { ...tableData.rowData[data.id], _errors: data.details }));
                }
                return (
                    <div>
                        <ul>
                            {Object.entries(data.details).map(([key, value]: [string, string[]]) => (
                                <li key={key}>
                                    <b>{key}:</b>
                                    <ul>
                                        {value.map((v: string) => (
                                            <li key={v}>{v}</li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            } else if ('error' in data) {
                return <div>{data.error}</div>;
            }
        }
    };

    useEffect(() => {
        if (!postIsLoading && isSuccess) {
            handleClose();
        } else if (!postIsLoading && isError) {
            dispatch(
                openMessageModal(
                    {
                        title: 'Error Saving Alternate Name Edits',
                        description: (
                            <div>
                                <div>An error occurred when saving the alternate name edits.</div>
                                <br />
                                {getErrors()}
                            </div>
                        ),
                    },
                    CENTERED_MODAL_TYPES.ERROR
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postIsLoading, isSuccess, isError, dispatch]);

    useEffect(() => {
        const keys = Object.keys(tableData.rowData).filter(rowId => !Number.isNaN(parseInt(rowId)));
        if (keys.length > 0 && !tableDataDidLoad) {
            setTableDataDidLoad(true);
            const glossary = LITE_TABLES.ALTERNATIVENAMES.tableGlossary;
            let addressArray: AddressFields[] = keys.map(key => {
                const row = tableData.rowData[key];
                if (row.address1 === null || row.city === null || row.state === null || row.zipcode === null) return;
                return { address1: row.address1, address2: row.address2, city: row.city, state: row.state, zipcode: row.zipcode };
            });

            if (
                currentSubmission.arch_account_street &&
                currentSubmission.arch_account_city &&
                currentSubmission.arch_account_state &&
                currentSubmission.arch_account_zipcode
            ) {
                const primaryAddress: AddressFields = {
                    address1: `${PRIMARY_ADDRESS_INDICATOR}${currentSubmission.arch_account_street}`, // asterisk indicates primary account address
                    address2: null,
                    city: currentSubmission.arch_account_city,
                    state: currentSubmission.arch_account_state,
                    zipcode: currentSubmission.arch_account_zipcode,
                };

                addressArray = [primaryAddress, ...addressArray]; // make primary always first
            }

            const formatAddress = (fields: AddressFields) =>
                `${fields.address1}, ${fields.address2 === null || fields.address2 === '' ? '' : fields.address2 + ','} ${fields.city} ${fields.state}, ${
                    fields.zipcode
                }`;

            const choices = []; // using an array for this is sorta inefficient but there shouldn't be so many entries here anyway
            addressArray.forEach((addr, idx) => {
                if (addr !== undefined) {
                    const completeAddress = formatAddress(addr);
                    if (choices.findIndex(e => e[1] === completeAddress) < 0) {
                        choices.push([`${idx}`, completeAddress]);
                    }
                }
            });
            glossary.ra.choices = choices;
            setReuseAddressChoices(addressArray);
        }
    }, [tableData, tableDataDidLoad, currentSubmission]);

    useEffect(() => {
        const fetchAltNames = async () => {
            const payload = await getExistingAltNames({ submission_id: currentSubmission.id }).unwrap();
            if (payload.length > 0) {
                setIsInitiallyEmpty(false);
            }
        };
        fetchAltNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="grid-layout h-100 gl-1-force no-gap flex-1">
            <LiteTableContainer
                disableTable={isFetching || isLoading}
                onRowSelected={null}
                hasActionPanel={false}
                tableConfig={LITE_TABLES.ALTERNATIVENAMES}
                containerClass="standard-modal-content width-100 no-padding-top h-adjust"
                tableData={tableData}
                showProgressText={isFetching || isLoading}
                onPersistTableData={onCellEdit}
                progressText="Fetching Alternate Names"
                disableTableWithoutProgress={false}
                extraCellRenderData={{
                    currentSubmission,
                    field_name: 'account_agent_id',
                }}
                belowGridContent={renderActionButtons()}
            />
            <div className="flex flex-row margin-bottom-1 tw-space-x-8 tw-justify-center">
                <button className="grey-dark width-20" onClick={handleClose}>
                    Cancel
                </button>
                <button className="blue width-20" onClick={debouncedHandleSave} disabled={isSmmsSubmission || isFetching || isLoading}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default AlternativesNamesApp;
