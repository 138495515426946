import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { logout } from '@archinsurance-viki/property-jslib/src/actions/UserActions';
import { Types } from '../../ts-types/icubed-types';
import AnalyticsLeftPanel from '../layout/Analytics.LeftPanel';
import DefaultLayout from '../layout/DefaultLayout';
import AnalyticsApp from './AnalyticsApp';

const AnalyticsContainer = () => {
    const dispatch = useAppDispatch();
    const me = useAppSelector(state => state.global.me);

    const featureFlags: Types.FeatureFlags = useAppSelector(state => state.global.featureFlags);
    const onLogOut = () => {
        return dispatch(logout());
    };
    const LeftPanel = <AnalyticsLeftPanel onLogOut={onLogOut} featureFlags={featureFlags} me={me} />;
    return (
        <DefaultLayout topPanelHeader="Analytics" leftPanel={LeftPanel}>
            <AnalyticsApp featureFlags={featureFlags} />
        </DefaultLayout>
    );
};

export default AnalyticsContainer;
