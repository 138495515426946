import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { ModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { InboxType, SubmissionType, UserType, VoidFn } from '../../ts-types/DataTypes';
import PreviewPanel from '../panels/PreviewPanel';
import InboxPanelContainer from '../panels/InboxPanel.container';
import LeftPanel from '../layout/LeftPanel';
import DefaultLayout from '../layout/DefaultLayout';
import DocumentsPreviewContainer from '../documents/DocumentsPreviewContainer';
import { getQueryStringParam } from '@archinsurance-viki/property-jslib/src/utils/js-helpers';

import { PagedTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    currentInbox: InboxType;
    inboxes: InboxType[];
    currentSubmissionId: number;
    currentSubmission: SubmissionType;
    onRowSelected: RowSelectedFnType;
    onLoadSubmissionIfNeeded: (submissionId: number, inboxes: InboxType[], forceLoad?: boolean) => void;
    onTakeAction: (
        submissionId: number,
        newStatus: string,
        emailParams: Record<string, any>,
        sendEmailNotification: boolean,
        archClearanceAPIRelatedSID: string
    ) => void;
    onPopEmailForwardModal: (params: Record<string, any>) => void;
    onPopResponseModal: (data: Record<string, any>) => void;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    onOpenMessageModal: (params: Record<string, any>, modalType: string) => void;
    onLogOut: VoidFn;
    onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => void;
    onDownloadDocument: (document: DocumentDataType) => void;
    onDownloadAllSubmissionDocs: (submissionId: number) => void;
    onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => void;
    onRunSOVParser: (documentId: number) => void;

    onClassifySelectedText: (documentId: number, text: string) => void;
    openPanels: Record<string, any>;
    centeredModalData: ModalType;
    centeredModalType: string;
    CONSTANTS: Types.Constants;
    me: UserType;
    ENV: Types.Env;
    featureFlags: Types.FeatureFlags;
    uiState: Record<string, any>;
    tableData: PagedTableDataType;

    onTogglePanel: (panel: string) => void;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    setUiState: (params: Record<string, any>, path: string) => void;

    onCloseCenteredModal: VoidFn;
    openConflictTable: (_sid: any, rowData: SubmissionType) => void;
    onClickDuplicateInTable: (sid: number, rowIndex: number, rowData: any) => void;
};
type stateTypes = {
    isDocViewerExpanded: boolean;
};

class DashboardApp extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.props.onLoadSubmissionIfNeeded(this.props.currentSubmissionId, this.props.inboxes);
        this.state = {
            isDocViewerExpanded: true,
        };
    }

    componentDidUpdate(prevProps: propTypes) {
        this.props.onLoadSubmissionIfNeeded(prevProps.currentSubmissionId, this.props.inboxes);
    }

    render() {
        // This resets PreviewPanel State when the submission changes
        // which is recommended vs trying to detect props changes and reset
        const { currentSubmission } = this.props;

        const previewKey = currentSubmission ? currentSubmission.id : 'none';
        if (getQueryStringParam('screen') === 'full') {
            return (
                <DocumentsPreviewContainer
                    currentInbox={this.props.currentInbox}
                    inboxes={this.props.inboxes}
                    currentSubmissionId={this.props.currentSubmissionId}
                    currentSubmission={this.props.currentSubmission}
                    onLogOut={this.props.onLogOut}
                    onSaveDocumentData={this.props.onSaveDocumentData}
                    onDownloadDocument={this.props.onDownloadDocument}
                    onDownloadAllSubmissionDocs={this.props.onDownloadAllSubmissionDocs}
                    onOcrImage={this.props.onOcrImage}
                    onClassifySelectedText={this.props.onClassifySelectedText}
                    openPanels={this.props.openPanels}
                    CONSTANTS={this.props.CONSTANTS}
                    me={this.props.me}
                    ENV={this.props.ENV}
                    featureFlags={this.props.featureFlags}
                    uiState={this.props.uiState}
                    tableData={this.props.tableData}
                    onSelectInbox={this.props.onSelectInbox}
                    onTogglePanel={this.props.onTogglePanel}
                    setUiState={this.props.setUiState}
                    centeredModalData={this.props.centeredModalData}
                    centeredModalType={this.props.centeredModalType}
                    onCloseCenteredModal={this.props.onCloseCenteredModal}
                />
            );
        }
        const AppLeftPanel = (
            <LeftPanel
                inboxes={this.props.inboxes}
                currentInbox={this.props.currentInbox}
                onSelectInbox={this.props.onSelectInbox}
                onTogglePanel={this.props.onTogglePanel}
                onLogOut={this.props.onLogOut}
                openPanels={this.props.openPanels}
                featureFlags={this.props.featureFlags}
            />
        );
        return (
            <React.Fragment>
                <DefaultLayout leftPanel={AppLeftPanel}>
                    <div className={classNames('main-content-center', 'grid-layout', 'gl-2', 'fixed', 'grow', { expanded: !this.state.isDocViewerExpanded })}>
                        <InboxPanelContainer
                            currentSubmission={this.props.currentSubmission}
                            currentSubmissionId={this.props.currentSubmissionId}
                            tableData={this.props.tableData}
                            currentInbox={this.props.currentInbox}
                            inboxes={this.props.inboxes}
                            uiState={this.props.uiState}
                            me={this.props.me}
                            CONSTANTS={this.props.CONSTANTS}
                            onRowSelected={this.props.onRowSelected}
                            onTakeAction={this.props.onTakeAction}
                            onPopResponseModal={this.props.onPopResponseModal}
                            onPopEmailForwardModal={this.props.onPopEmailForwardModal}
                            setUiState={this.props.setUiState}
                            onOpenCenteredModal={this.props.onOpenCenteredModal}
                            onOpenMessageModal={this.props.onOpenMessageModal}
                            openConflictTable={this.props.openConflictTable}
                            onClickDuplicateInTable={this.props.onClickDuplicateInTable}
                        />
                        <PreviewPanel
                            key={previewKey}
                            CONSTANTS={this.props.CONSTANTS}
                            featureFlags={this.props.featureFlags}
                            currentSubmission={this.props.currentSubmission}
                            onDownloadDocument={this.props.onDownloadDocument}
                            onDownloadAllSubmissionDocs={this.props.onDownloadAllSubmissionDocs}
                            onRunSOVParser={this.props.onRunSOVParser}
                            onSaveDocumentData={this.props.onSaveDocumentData}
                            onOcrImage={this.props.onOcrImage}
                            onClassifySelectedText={this.props.onClassifySelectedText}
                            isContainerVisible={this.state.isDocViewerExpanded}
                        />
                        <a
                            key="handle"
                            className="handle abs top-right action-handle"
                            onClick={() => this.setState(state => ({ isDocViewerExpanded: !state.isDocViewerExpanded }))}
                        >
                            <b className={classNames(!this.state.isDocViewerExpanded ? 'icon-arrow-left2' : 'icon-arrow-right2')} />
                            <span className="text">{this.state.isDocViewerExpanded ? 'Collapse' : 'Expand'}</span>
                        </a>
                    </div>
                </DefaultLayout>
            </React.Fragment>
        );
    }
}

export default DashboardApp;
