import * as React from 'react';

import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import LinkSubmissionApp from '../apps/LinkSubmissionApp';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
};

const LinkSubmissionBody = (_props: bodyPropTypes) => <LinkSubmissionApp />;

const modalTypeObj = {
    Body: LinkSubmissionBody,
    Header: DefaultHeaderWithProps('Link Submission'),
    className: 'add-note-modal width-50',
};

registerType(ICUBED_MODAL_TYPES.LINK_SUBMISSION, modalTypeObj);
export default modalTypeObj;
