import { API } from '@archinsurance-viki/property-jslib';
import * as types from '../constants/ActionTypes';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { InboxType } from '../ts-types/DataTypes';
import { receiveInboxes } from '../reducers/inboxes';

const INBOX_ALL = { id: -1, short_name: 'All' };

export const setInboxes = (inboxes: InboxType[]) => {
    return {
        type: types.RECEIVE_INBOXES,
        inboxes: inboxes,
    };
};

export const setCurrentInbox = (inboxId?: number) => {
    return {
        type: types.SET_CURRENT_INBOX,
        inboxId,
    };
};

export const getInboxes_server = () => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.get,
            {
                params: {},
            },
            dispatch
        )
            .then(function (response) {
                let { status } = response;
                if (status === 200) {
                    dispatch(receiveInboxes([...response.data.results, INBOX_ALL]));
                } else {
                    console.error(response);
                }
            })
            .catch(function (error) {
                console.error(error);
            });
    };
};

export const searchInbox_server = (search: string) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.inbox.searchEmail,
            {
                params: {
                    searchString: search,
                },
            },
            dispatch
        ).then(({ data }) => {
            console.log('DATA', data);
        });
    };
};
