import * as types from '../constants/ActionTypes';

let initialState = {
    glossarys: {},
    insuredReferences: null,
};

export const updateGlossaryObject = (columnData: Record<string, any>) => {
    const glossaryDataMap = {};
    for (let col of columnData.fields) {
        let column = { ...col };
        glossaryDataMap[column.field_name] = column;
    }

    Object.assign(columnData, {
        map: glossaryDataMap,
        tableName: columnData.table.verbose_name,
    });

    return columnData;
};

function debugReducer(state = initialState, action: any) {
    switch (action.type) {
        case types.SET_GLOSSARIES: {
            let results = {};
            for (let key in action.data) {
                let item = action.data[key];
                results[item.table.db_table] = updateGlossaryObject(item);
            }

            return Object.assign({}, state, {
                glossarys: results,
            });
        }
        case types.SET_INSURED_REFERENCES: {
            return Object.assign({}, state, {
                insuredReferences: action.data,
            });
        }
        default:
            return state;
    }
}

export const RELATED_ACTION_TYPES = [types.SET_GLOSSARIES, types.SET_INSURED_REFERENCES];

export default debugReducer;
