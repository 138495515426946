import { useState, useContext, useEffect } from 'react';
import u from 'updeep';
import { useParams } from 'react-router-dom';

import { TextAreaInput, TagInput, TextInput, CheckInput, ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { validateEmail } from '../../../../utils/validators';
import { UnderwritingPlatformType } from '../../../../ts-types/DataTypes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';
import { EMPTY_FWD_OBJECT, CLEARANCE_STATUS } from '../../../../constants/Constants';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions'; // openCenteredModal
import { takeAction } from '../../../../actions/SubmissionActions';
import { VoidFn, PromiseDispatchType, InboxType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';

type propTypes = {
    onClose: (close: boolean) => void;
    disableCCEmails?: boolean;
    modalData: {
        currentSubmissionId: number | string;
        action: string;
        replyToEmail: string;
        additionalReplyToEmails?: string[];
        additionalCCEmails?: string[];
        currentInbox?: InboxType;
        vikiSid?: number;
        archSid?: string;
        submissionWasActioned?: boolean;
    };

    onGetTemplate: VoidFn;
    onGetForwardTemplates?: VoidFn;
    onPutTemplate: (body: string, subject?: string, baseTemplateSubject?: string) => void;
    onResetTemplate?: (subject?: string) => void;
    onSendResponse: (params) => PromiseDispatchType;
    onTakeAction: (action: string, emailParams: any, ccEmails?: string[], replyToEmails?: string[]) => PromiseDispatchType;
    onSearchInbox: (searchTerm: string) => void;
    handleAdditionalEmailsChange?: (emails: string[], submissionId: number) => void;
    handleToggleSecondaryEmailTemplate?: VoidFn;
    isDisabled?: boolean;
    templateKey?: string;
    featureFlags?: Types.FeatureFlags;
};

const ForwardApp = (props: propTypes) => {
    const sidRegexes = {
        viki: /^\d+$/,
        arch: /^S\d+$/,
    };
    const dispatch = useAppDispatch();
    const FWD = useAppSelector(state => state.uiState?.FWD);
    const ENV = useAppSelector(state => state.global.ENV);
    const modalData = useAppSelector(state => state.global?.centeredModalData?.modalData);
    const { submissionId: currentSubmissionId } = useParams<{ submissionId: string }>();
    const sharedContext = useContext(AppContext);
    const isVIKISubmission = modalData?.submissionUWP === UnderwritingPlatformType.VIKI;
    const [additionalEmails, setAdditionalEmails] = useState([]);
    const [validationError, setValidationError] = useState(null);

    useEffect(() => {
        props.onGetTemplate();
        props.onGetForwardTemplates();
        const { externalSid } = modalData;
        const updates = {
            submission_id: externalSid,
            arch_clearance_api_related_sid: getDefaultSid(),
            skip_attachments: false,
            to_emails: ENV.FORWARDING_EMAIL ? [ENV.FORWARDING_EMAIL] : null,
        };
        sharedContext.setUiState({ FWD: u(updates, FWD) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClose = () => {
        props.onPutTemplate(FWD.body, FWD.subject, FWD.base_template_subject);
        console.log('body', FWD.body, 'subject', FWD.subject);
        dispatch(closeCenteredModal());
        onClearFormData();
    };

    const onClearFormData = () => {
        dispatch(updeepUi(EMPTY_FWD_OBJECT, 'FWD'));
    };

    const handleAddAdditionalEmail = (email: string) => {
        setAdditionalEmails([email, ...additionalEmails]);
    };

    const handleAddToEmails = (email: string) => {
        const previousEmails = FWD.to_emails || [];
        const newEmails = [...previousEmails, email];
        sharedContext.setUiState({ FWD: u({ to_emails: newEmails }, FWD) });
    };

    const handleRemoveToEmails = (email: string) => {
        const previousEmails = FWD.to_emails || [];
        const newEmails = previousEmails.filter(e => e !== email);
        sharedContext.setUiState(newEmails, 'FWD.to_emails', true);
    };

    const handleRemoveAdditionalEmail = (email: string) => {
        setAdditionalEmails(additionalEmails.filter(e => e !== email));
    };

    const handleCancel = () => {
        onClose();
    };

    const handleChange = (fieldName: string, value: any) => {
        let updates = {
            [fieldName]: value,
        };
        if (fieldName === 'arch_clearance_api_related_sid' && sidRegexes.viki.test(value)) {
            updates.submission_id = value;
        }
        sharedContext.setUiState({ FWD: u(updates, FWD) });
    };

    const handleOnTemplateChange = (fieldName: string, value: any) => {
        const { templateKey } = props;
        const body = FWD.templates.find(template => template.value === value).body;
        sharedContext.setUiState({
            [templateKey]: u({ ['body']: body, ['subject']: value, ['base_template_subject']: value }, FWD),
        });
    };

    const onTakeAction = (emailParams: Record<string, any>, ccEmails: string[]) => {
        return dispatch(
            takeAction(
                currentSubmissionId,
                CLEARANCE_STATUS.FORWARDED,
                {
                    ...emailParams,
                    clearance_submission_id: currentSubmissionId,
                },
                ccEmails,
                true
            )
        );
    };

    const getDefaultSid = () => {
        return (isVIKISubmission ? modalData?.externalSid : modalData?.archSid) || null;
    };

    const renderSidTextInput = () => {
        const labelPAS = isVIKISubmission ? 'VIKI' : 'Archlink';
        const label = `${labelPAS} SID for ImageRight & Forward`;

        const inputProps = {
            object: FWD,
            maxLength: 5000,
            className: 'tall-textarea',
            onChange: handleChange,
            defaultValue: getDefaultSid(),
            name: 'arch_clearance_api_related_sid',
            label: label,
            errors: validationError ? [validationError] : null,
            style: {
                minHeight: '30px',
            },
        };

        return <TextInput {...inputProps} />;
    };

    const handleForwardNote = () => {
        const { to_emails, submission_id, arch_clearance_api_related_sid } = FWD;
        const forwardingToViki = to_emails && to_emails.some(email => email.includes('ventusrisk.com') && email.includes('viki'));
        const vikiSidValidationError = 'A numeric VIKI submission ID is required to forward to VIKI';

        let validationError = null;
        if (forwardingToViki && !submission_id) {
            validationError = vikiSidValidationError;
        }

        if (validationError === null && submission_id) {
            if (forwardingToViki && !sidRegexes.viki.test(submission_id)) {
                validationError = vikiSidValidationError;
            } else if (
                arch_clearance_api_related_sid &&
                !sidRegexes.viki.test(arch_clearance_api_related_sid) &&
                !sidRegexes.arch.test(arch_clearance_api_related_sid)
            ) {
                validationError = 'Please enter a valid Arch or VIKI submission ID.';
            }
        }

        if (validationError !== null) {
            setValidationError(validationError);
            return;
        }
        onTakeAction(FWD, additionalEmails);
    };

    return (
        <div className="standard-modal-content flex column">
            {renderSidTextInput()}
            <CheckInput
                name="skip_attachments"
                label="Skip Attachments (only send correspondence):"
                className="width-20"
                labelAfter={true}
                disabled={false}
                onChange={handleChange}
                object={FWD}
            />
            <TagInput
                tags={FWD.to_emails || []}
                onRemoveTag={handleRemoveToEmails}
                onAddTag={handleAddToEmails}
                onValidateTag={validateEmail}
                placeholder="To Emails"
                fuzzySearch={'inbox'}
            />
            <TagInput
                tags={additionalEmails}
                onRemoveTag={handleRemoveAdditionalEmail}
                onAddTag={handleAddAdditionalEmail}
                onValidateTag={validateEmail}
                placeholder="CC Emails"
                fuzzySearch={'inbox'}
            />
            {props.featureFlags.enable_new_email_templates ? (
                <div style={{ lineHeight: '20px' }}>
                    <ComboBoxInput
                        // key={selectedSlide.id}
                        object={FWD}
                        label="Forward Template"
                        name="base_template_subject"
                        onChange={handleOnTemplateChange}
                        selectProps={{
                            choices: FWD.templates || [],
                        }}
                        defaultValue={FWD.base_template_subject || FWD.subject}
                    />
                </div>
            ) : null}

            <TextInput
                maxLength={5000}
                style={{ minHeight: '30px' }}
                disabled={false}
                className="tall-textarea"
                name="subject"
                label="Subject"
                onChange={handleChange}
                object={FWD}
            />
            <TextAreaInput
                showValueTooltip={false}
                maxLength={5000}
                style={{ minHeight: '300px' }}
                disabled={false}
                className="tall-textarea"
                name="body"
                label="Email Body"
                onChange={handleChange}
                object={FWD}
                autoFocus={true}
            />
            <div className="button-row grid align-single-to-right">
                <button className="green" onClick={handleForwardNote}>
                    Send Email
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </button>
                <button className="orange" disabled={false} onClick={() => props.onResetTemplate(FWD?.base_template_subject)}>
                    Reset Template
                </button>
            </div>
        </div>
    );
};

ForwardApp.defaultProps = {
    templateKey: 'FWD',
};

export default ForwardApp;
