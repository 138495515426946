import * as React from 'react';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';

import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, UnderwritingPlatformType, UserType } from '../../../../ts-types/DataTypes';
import { takeAction } from '../../../../actions/SubmissionActions';
import { getTemplate_server, putTemplate_server } from '../../../../actions/EmailActions';
import { VoidFn, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { EMPTY_RESPONSE_OBJECT, BOR_NOTIFICATION_ACTION, CLEARANCE_STATUS, POLICY_SERVICES_EMAILS } from '../../../../constants/Constants';
import _ from 'lodash';
import { TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import BorNotificationApp from '../apps/BorNotificationApp';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    borSubmission: SubmissionType;
    incumbentSubmission: TableRowDataType;
    borSubmissions: string[];
    me: UserType;
    ENV: Types.Env;
    featureFlags: Record<string, string>;
    modalData: {
        action: string;
        replyToEmail: string;
        currentSubmissionId: number;
    };
};

type propTypes = {
    onClearFormData: VoidFn;
    onGetTemplate: VoidFn;
    onPutTemplate: (body: string) => void;
    onSearchInbox: (searchTerm: string) => void;
    SubmissionRESPONSE: Record<string, any>;
    onSendResponse: (params) => PromiseDispatchType;
    uiState: {
        RESPONSE: Record<string, any>;
    };
    onTakeAction: (
        submissionId: number,
        relatedSubmissionIds: string[],
        RESPONSE: Record<string, any>,
        ccEmails?: string[],
        replyToEmails?: string[]
    ) => PromiseDispatchType;
} & ownPropTypes;

type reducerStateTypes = {
    uiState: Record<string, any>;
} & Types.RootState;

const mapStateToProps = (state: reducerStateTypes, ownProps: ownPropTypes) => {
    const { uiState } = state;
    const props: Record<string, any> = {
        uiState: state.uiState,
    };
    props.SubmissionRESPONSE = uiState.RESPONSE;
    props.me = state.global.me;
    props.ENV = state.global.ENV;
    props.featureFlags = state.global.featureFlags;
    return props;
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    const borSubmissionId = ownProps.borSubmission.id;
    const incumbentSubmissionId = ownProps.incumbentSubmission.id;
    return {
        onGetTemplate: () =>
            dispatch(getTemplate_server({ submissionId: borSubmissionId, action: BOR_NOTIFICATION_ACTION, incumbentSubmissionId: incumbentSubmissionId })),
        onPutTemplate: body => {
            dispatch(
                putTemplate_server({ submissionId: borSubmissionId, action: BOR_NOTIFICATION_ACTION, incumbentSubmissionId: incumbentSubmissionId, body })
            );
        },
        onTakeAction: (submissionId: number, relatedSubmissionIds: string[], RESPONSE: Record<string, any>, ccEmails?: string[]) => {
            return dispatch(takeAction(submissionId, CLEARANCE_STATUS.PENDING_BOR, RESPONSE, ccEmails, true, null, relatedSubmissionIds));
        },
        onClearFormData: () => {
            dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, 'RESPONSE'));
        },
    };
};

type stateTypes = {
    SubmissionRESPONSE: Record<string, any>;
};

class BorNotification extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            SubmissionRESPONSE: {},
        };
    }

    componentDidUpdate() {
        const stateUpdate: any = {};
        if (this.props.SubmissionRESPONSE && !isEqual(this.props.SubmissionRESPONSE, this.state.SubmissionRESPONSE)) {
            stateUpdate.SubmissionRESPONSE = this.props.SubmissionRESPONSE;
        }
        if (Object.keys(stateUpdate).length > 0) this.setState(stateUpdate);
    }

    handlePendingBor = (action: string, emailParams: any, ccEmails?: string[], replyToEmails?: string[]): PromiseDispatchType => {
        return this.props.onTakeAction(this.props.modalData.currentSubmissionId, this.props.borSubmissions, emailParams, ccEmails, replyToEmails);
    };

    updateModalData = () => {
        const { borSubmission } = this.props;

        const additionalCCEmails = [];
        const replyToEmail =
            borSubmission.underwriting_platform === UnderwritingPlatformType.VIKI ? POLICY_SERVICES_EMAILS.VENTUS : POLICY_SERVICES_EMAILS.ARCH;

        return {
            ...this.props.modalData,
            replyToEmail,
            additionalCCEmails,
        };
    };

    render() {
        const { uiState, onGetTemplate, onPutTemplate } = this.props;

        let modalData = this.updateModalData();
        let updatedResponse = {
            ...uiState.RESPONSE,
            replyToEmail: modalData.replyToEmail,
        };

        const clearUIState = {
            ...uiState,
            RESPONSE: updatedResponse, // Use the updated response object
        };
        const baseProps = {
            ...this.props,
            onTakeAction: this.handlePendingBor,
        };
        const clearProps = {
            ...baseProps,
            uiState: clearUIState,
            sendButtonText: 'Send Emails',
            onGetTemplate: onGetTemplate,
            onPutTemplate: onPutTemplate,
            modalData: { ...modalData },
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <BorNotificationApp {...clearProps} />
            </div>
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BorNotification);
export default connected;
