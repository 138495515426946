import * as React from 'react';
import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import { setOverrideReason } from '../../../../actions/SubmissionActions';

import OverrideBusinessRulesApp from '../apps/OverrideBusinessRulesApp';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { SubmissionType, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    action: string;
    tableData: LiteTableDataType;
    currentSubmission: SubmissionType;
    ENV: Types.Env;
};

type propTypes = {
    onAddOverrideReason: (reason: string, sid: number[]) => PromiseDispatchType;
    performClearanceAction: (reason: string) => void;
} & ownPropTypes;

const mapStateToProps = (_state: any, _ownProps: ownPropTypes) => {
    return {};
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    let { currentSubmission } = ownProps;
    return {
        onAddOverrideReason: (value: string, blockingSids: number[]) => {
            return dispatch(setOverrideReason(currentSubmission.id, value, blockingSids));
        },
    };
};

class OverrideBusinessRules extends React.Component<propTypes> {
    render() {
        return <OverrideBusinessRulesApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(OverrideBusinessRules);
export default connected;
