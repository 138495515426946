import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { VENTUS_URL } from '../../utils/setup-endpoints';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    featureFlags: Types.FeatureFlags;
};

const ANALYTICS_KEY = 'analytics';
const DASHBOARD_KEY = 'dashboard';

const NavPanel = (props: propTypes) => {
    const match = useRouteMatch();
    const ventusUrl = VENTUS_URL;
    return (
        <div className="left-top-content">
            <If condition={!props.featureFlags.hide_navigation}>
                <If condition={props.featureFlags.analytics_tab_enabled}>
                    <div className="panel-option level-0">
                        <a className={classNames('nav-link', { active: match.path.indexOf(ANALYTICS_KEY) >= 0 })} href="/analytics">
                            <div className="nav-header">Analytics</div>
                        </a>
                    </div>
                </If>
                <div className="panel-option level-0">
                    <a className={classNames('nav-link', { active: match.path.indexOf(DASHBOARD_KEY) >= 0 })} href="/">
                        <div className="nav-header">Clearance</div>
                    </a>
                </div>
                <div className="panel-option level-0">
                    <a className="nav-link" href={ventusUrl + '/submissions'}>
                        <div className="nav-header">Submissions</div>
                    </a>
                </div>
                <div className="panel-option level-0">
                    <a className="nav-link" href={ventusUrl + '/tasks'}>
                        <div className="nav-header">Tasks</div>
                    </a>
                </div>
            </If>
        </div>
    );
};

export default NavPanel;
