import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { getMondays, getDatesInRange, getFirstDatesOfMonth } from '@archinsurance-viki/property-jslib/src/utils/chart-helpers';
export type Series = { name: string; data: number[] };

export type ApiResponseType = {
    data: any[];
    submissions: {
        submission_id: number;
        action: string;
        date_actioned: string;
    }[];
};
export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_DATE = moment(new Date()).format(DEFAULT_DATE_FORMAT);
export const ONE_MONTH_BEHIND_DATE = moment(CURRENT_DATE, DEFAULT_DATE_FORMAT).subtract(1, 'months').format(DEFAULT_DATE_FORMAT);
export const MONTH_LIST = [
    { value: 1, display: 'January' },
    { value: 2, display: 'Feburary' },
    { value: 3, display: 'March' },
    { value: 4, display: 'April' },
    { value: 5, display: 'May' },
    { value: 6, display: 'June' },
    { value: 7, display: 'July' },
    { value: 8, display: 'August' },
    { value: 9, display: 'September' },
    { value: 10, display: 'October' },
    { value: 11, display: 'November' },
    { value: 12, display: 'December' },
];
// mocked response that should be updated after api developed
// API request sample
// units, 0 = daily, 1 = monthly, 2 = weekly ...
// action: B: BLOCK, C: Clear etc.
// {
//     "end": "2023-05-16",
//     "start" "2023-01-01",
//     "units": 0,
//     "action": 'B'
// }
// API response sample
// [
//     data: [
//         {"timestamp": "2023-01-01", block: 50, decline: 12, clear: 5, unprocessed: 0},
//         {}
//     ],
//     "submisions": [
//         {"submission_id": 12313, "action": clear, "date_actioned": "2023-01-15"}
//         ..
//     ]
// ]
export const MOCK_SUBMISSIONS_RESPONSE = [
    { submission_id: 323361, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323362, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323363, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323364, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323365, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323366, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323367, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323368, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323369, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323370, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323371, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323372, action: 'clear', date_actioned: '2023-01-15' },
    { submission_id: 323373, action: 'clear', date_actioned: '2023-01-15' },
];

export const getMockResponse = (end: string, start: string, unit: number, action: string) => {
    let timeStamps = [];
    let responseData = [];
    if (unit === 0) {
        timeStamps = getDatesInRange(new Date(start), new Date(end));
    } else if (unit === 1) {
        timeStamps = getMondays(new Date(start), new Date(end));
    } else {
        timeStamps = getFirstDatesOfMonth(new Date(start), new Date(end));
    }
    console.log('action requested', action);
    timeStamps.map(item => {
        responseData.push({
            timestamp: item,
            Blocked: Math.floor(Math.random() * 100),
            Cleared: Math.floor(Math.random() * 100),
            Declined: Math.floor(Math.random() * 100),
            Unprocessed: Math.floor(Math.random() * 100),
        });
    });

    const response = {
        data: responseData,
        submissions: MOCK_SUBMISSIONS_RESPONSE,
    };

    return response;
};
