import * as React from 'react';

import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import BlockSubmission from '../containers/BlockSubmission.container';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    modalData: {
        action: string;
        replyToEmail: string;
        currentSubmissionId: number;
        should_use_arch_underwriters: boolean;
    };
};

class BlockSubmissionBody extends React.Component<bodyPropTypes> {
    render() {
        return <BlockSubmission {...this.props} />;
    }
}

type headerPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
};

class BlockSubmissionHeader extends React.Component<headerPropTypes> {
    render() {
        let { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Blocking Submission ${currentSubmission.id}`);
    }
}

const modalTypeObj = {
    Body: BlockSubmissionBody,
    Header: BlockSubmissionHeader,
    className: 'debug-info-modal wide',
};

registerType(ICUBED_MODAL_TYPES.BLOCK_SUBMISSION, modalTypeObj);
export default modalTypeObj;
