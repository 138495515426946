import { API } from '@archinsurance-viki/property-jslib';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { loadLiteTableRows } from '@archinsurance-viki/property-jslib/src/actions/TableActions';

import * as types from '../constants/ActionTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';

export const setClientMatches = (matches: any[]) => {
    return {
        type: types.SET_CURRENT_CLIENT_MATCHES,
        matches: matches,
    };
};

export const getMatchedClients_server = (submissionId: number, searchString: string, limit: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.insured.getFuzzy,
            {
                params: {
                    searchString: searchString,
                    limit: limit,
                    submissionId: submissionId,
                },
            },
            dispatch
        ).then(
            response => {
                dispatch(loadLiteTableRows(LITE_TABLES.CLIENTMATCHES, response));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};

export const getMatchedBrokers_server = (searchString: string, subDivisionId: number, limit: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.producingAgent.getFuzzy,
            {
                params: {
                    searchString: searchString,
                    subDivisionId: subDivisionId,
                    limit: limit,
                },
            },
            dispatch
        ).then(
            response => {
                dispatch(loadLiteTableRows(LITE_TABLES.BROKERMATCHES, response));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};

export const getMatchedBrokerOffices_server = (searchString: string, siteId: number, inboxId: number, state: string) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.producer.getFuzzy,
            {
                params: {
                    searchString,
                    siteId,
                    inboxId,
                    state,
                },
            },
            dispatch
        ).then(
            response => {
                dispatch(loadLiteTableRows(LITE_TABLES.BROKEROFFICEMATCHES, response));
            },
            xhr => {
                alert(xhr.errors);
            }
        );
    };
};
export const createAccountBroker = (submissionId: number, payload: any) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.producingAgent.create,
            {
                submissionId,
                payload,
                showProgress: true,
                hideDialogForErrors: true,
            },
            dispatch
        );
    };
};

export const getArchAccountMatches_server = (submissionId: number, searchParams: Record<string, any>, limit: number) => {
    return (dispatch: DispatchType) => {
        const params = {
            limit,
            submissionId,
            ...searchParams,
        };
        return API.doRequest(
            API.endpoints.archAccount.search,
            {
                params,
            },
            dispatch
        ).then(response => {
            dispatch(loadLiteTableRows(LITE_TABLES.ACCOUNT_SEARCH_RESULTS, response));
            return response.data;
        });
    };
};
