import React, { useState } from 'react';
import { useAppDispatch } from '../../../../hooks/redux';

import { TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';

import { linkSubmissionAction } from '../../../../actions/SubmissionActions';
import { useCurrentSubmission } from '../../../../hooks/submission';
import { UnderwritingPlatformType } from '../../../../ts-types/DataTypes';

const LinkSubmissionApp = () => {
    const dispatch = useAppDispatch();

    const currentSubmission = useCurrentSubmission();
    const [submissionLinkForm, setSubmissionLinkForm] = useState({});

    const handleChange = (fieldName: string, value: string) => {
        setSubmissionLinkForm({ ...submissionLinkForm, [fieldName]: value });
    };

    const handleLinkForm = () => {
        dispatch(linkSubmissionAction(currentSubmission.id, submissionLinkForm)).then(() => handleCancel());
    };

    const handleCancel = () => {
        dispatch(closeCenteredModal());
    };

    return (
        <div className="standard-modal-content flex column">
            <TextInput
                maxLength={50}
                style={{ minHeight: '30px' }}
                disabled={false}
                className="tall-textarea"
                name={currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI ? 'viki_submission_id' : 'arch_submission_id'}
                label={currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI ? 'VIKI Submission ID' : 'Archlink Submission ID'}
                onEveryChange={handleChange}
                object={submissionLinkForm}
            />

            <div className="button-row">
                <button className="green" disabled={false} onClick={handleLinkForm}>
                    Link
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default LinkSubmissionApp;
