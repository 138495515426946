import { useAppDispatch } from '../../../../hooks/redux';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions'; //
import { VoidFn } from '../../../../ts-types/DataTypes';

type propTypes = {
    confirmAction: VoidFn;
};

const BorDecisionEmailConfirmApp = (props: propTypes) => {
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(closeCenteredModal());
    };

    const handleConfirmClick = () => {
        props.confirmAction();
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <div className="flex column pad-bottom-1">
            <div className="button-row">
                <button className="green" onClick={handleConfirmClick}>
                    Confirm
                </button>
                <div className="spacer wide" />
                <button
                    className="red"
                    onClick={() => {
                        handleCancel();
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default BorDecisionEmailConfirmApp;
