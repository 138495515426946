import * as React from 'react';
import _ from 'lodash';

import { Tag, TagInput, TextInput, MaskedTextInput, ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { VMAC_BROKER_CREATE_CLICK_EVENT } from '../../../../constants/AnalyticsConstants';

type propTypes = {
    onClose: (close: boolean) => void;
    CONSTANTS: Types.Constants;
    currentSubmissionId: number;
    onAddAccountBroker: (submissionId: number, payload: any) => PromiseDispatchType;
    onGetSubmissionData: (currentSubmissionId: number) => PromiseDispatchType;
};

type stateTypes = {
    isEditing: boolean;
    first_name: string;
    last_name: string;
    direct_phone_number: string;
    email: string;
    offices: Record<string, unknown>[];
    sub_divisions: Record<string, unknown>[];
    parent: number;
    _errors: Record<string, any>;
    non_field_error: string;
};

export default class AddAccountBrokerApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);
        this.state = {
            isEditing: false,
            first_name: null,
            last_name: null,
            direct_phone_number: null,
            email: null,
            offices: [],
            sub_divisions: [],
            parent: null,
            _errors: {},
            non_field_error: null,
        };
    }

    handleChange = (fieldName: string, value: any) => {
        const newState = { [fieldName]: value } as Pick<string, any>;
        this.setState(newState);
    };

    handleCreateAccountBroker = () => {
        let payload = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            direct_phone_number: this.state.direct_phone_number,
            parent: this.state.parent,
            sub_divisions: this.state.sub_divisions.map(sd => {
                return sd.id;
            }),
            offices: this.state.offices.map(o => {
                return o.id;
            }),
        };

        this.setState({
            _errors: {},
            non_field_error: null,
        });

        let brokerCreateSuccess = false;
        this.props
            .onAddAccountBroker(this.props.currentSubmissionId, payload)
            .then(
                _response => {
                    this.props.onClose(true);
                    this.props.onGetSubmissionData(this.props.currentSubmissionId);
                    brokerCreateSuccess = true;
                },
                _xhr => {
                    if (_xhr.data && _xhr.data.field_errors) {
                        this.setState({
                            _errors: _xhr.data.field_errors,
                        });
                    } else if (_xhr.data && _xhr.data.error) {
                        this.setState({
                            non_field_error: _xhr.data.error,
                        });
                    } else {
                        this.setState({
                            non_field_error: _xhr.message,
                        });
                    }
                }
            )
            .then(() => {
                this.context.analytics.track(VMAC_BROKER_CREATE_CLICK_EVENT, { submission_id: this.props.currentSubmissionId, success: brokerCreateSuccess });
            });
    };

    handleCancel = () => {
        this.props.onClose(true);
    };

    handleRenderSubDivisionTags = () => {
        return this.state.sub_divisions.map((tag: any, idx: number) => {
            return <Tag key={idx} tagId={tag.id} onDelete={this.handleRemoveSubDivision} name={tag.name} />;
        });
    };

    handleAddSubDivision = (subdivision: any) => {
        const { sub_divisions } = this.state;
        this.setState({
            sub_divisions: [subdivision, ...sub_divisions],
        });
    };

    handleRemoveSubDivision = (subdivision: any) => {
        const { sub_divisions } = this.state;
        this.setState({
            sub_divisions: sub_divisions.filter(s => {
                return s.id !== subdivision.tagId;
            }),
        });
    };

    handleRenderOfficeTags = () => {
        return this.state.offices.map((tag: any, idx: number) => {
            return <Tag key={idx} tagId={tag.id} onDelete={this.handleRemoveOffice} name={`[VIKI ID ${tag.import_object_id}] - ${tag.name} (${tag.office})`} />;
        });
    };

    handleAddOffice = (office: any) => {
        const { offices } = this.state;
        this.setState({
            offices: [office, ...offices],
        });
    };

    handleRemoveOffice = (office: any) => {
        const { offices } = this.state;
        this.setState({
            offices: offices.filter(o => {
                return o.id !== office.tagId;
            }),
        });
    };

    render() {
        if (!this.props.currentSubmissionId) {
            return null;
        }

        let { CONSTANTS } = this.props;
        let nonFieldError = null;
        if (this.state.non_field_error) {
            nonFieldError = (
                <div style={{ color: 'red' }}>
                    <b>Warning</b>, {this.state.non_field_error}
                </div>
            );
        }
        let { _errors, offices } = this.state;
        let officesHasError = _errors && _.has(_errors, 'offices');
        let subDivisionsHasError = _errors && _.has(_errors, 'sub_divisions');
        let selectedOffices = _.map(offices, 'id');
        let defaultOfficeChoices = CONSTANTS.PRODUCERS_WITH_NAME_AND_CITY.filter(officeInfo => {
            let officeId = officeInfo[0];
            return selectedOffices.indexOf(officeId) !== -1;
        });
        return (
            <div className="standard-modal-content flex column">
                <TextInput
                    maxLength={50}
                    style={{ minHeight: '30px' }}
                    disabled={false}
                    className="tall-textarea"
                    name="first_name"
                    label="First"
                    onEveryChange={this.handleChange}
                    object={this.state}
                />
                <TextInput
                    maxLength={50}
                    style={{ minHeight: '30px' }}
                    disabled={false}
                    className="tall-textarea"
                    name="last_name"
                    label="Last"
                    onEveryChange={this.handleChange}
                    object={this.state}
                />
                <TextInput
                    maxLength={50}
                    style={{ minHeight: '30px' }}
                    disabled={false}
                    className="tall-textarea"
                    name="email"
                    label="Email"
                    onEveryChange={this.handleChange}
                    object={this.state}
                />
                <MaskedTextInput
                    format={{ mask: 'PHONE_NUMBER' }}
                    maxLength={100}
                    className=""
                    name="direct_phone_number"
                    label="Phone Number"
                    onEveryChange={this.handleChange}
                    object={this.state}
                />

                <TagInput
                    tags={this.state.offices}
                    hasError={officesHasError}
                    overrideHandleRemoveTag={this.handleRemoveOffice}
                    overrideHandleAddTag={this.handleAddOffice}
                    overrideRenderTags={this.handleRenderOfficeTags}
                    getItemName={item => {
                        let itemDisplay = `[VIKI ID ${item.import_object_id}] - ${item.name} (${item.office}`;
                        if (item.street) {
                            itemDisplay += `, ${item.street}`;
                        }
                        if (item.city) {
                            itemDisplay += `, ${item.city}`;
                        }
                        if (item.state) {
                            itemDisplay += `, ${item.state}`;
                        }
                        if (item.zipcode) {
                            itemDisplay += `, ${item.zipcode}`;
                        }
                        itemDisplay += `)`;

                        return itemDisplay;
                    }}
                    placeholder="Offices"
                    searchResultsLimit={200}
                    fuzzySearch={'producer'}
                />
                <ComboBoxInput
                    object={this.state}
                    label="Default Office"
                    name="parent"
                    onChange={this.handleChange}
                    selectProps={{ choices: defaultOfficeChoices }}
                />
                <TagInput
                    tags={this.state.sub_divisions}
                    hasError={subDivisionsHasError}
                    overrideHandleRemoveTag={this.handleRemoveSubDivision}
                    overrideHandleAddTag={this.handleAddSubDivision}
                    overrideRenderTags={this.handleRenderSubDivisionTags}
                    placeholder="Subdivisions"
                    fuzzySearch={'subDivision'}
                />
                {nonFieldError}
                <div className="button-row">
                    <button className="green" disabled={false} onClick={this.handleCreateAccountBroker}>
                        Create account Broker
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="red"
                        onClick={() => {
                            this.handleCancel();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}
