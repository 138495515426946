import * as React from 'react';
import u from 'updeep';

import { TextAreaInput, TagInput, TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { validateEmail } from '../../../../utils/validators';
import { VoidFn, PromiseDispatchType, InboxType } from '../../../../ts-types/DataTypes';

type propTypes = {
    hideReplyToEmail?: boolean;
    sendButtonText: string;
    onClose: (close: boolean) => void;
    uiState: {
        RESPONSE: Record<string, any>;
    };
    modalData: {
        currentSubmissionId: number;
        action: string;
        replyToEmail: string;
        additionalReplyToEmails?: string[];
        additionalCCEmails?: string[];
        currentInbox?: InboxType;
    };

    onClearFormData: VoidFn;
    onGetTemplate: VoidFn;
    onPutTemplate: (body: string) => void;
    onSendResponse: (params) => PromiseDispatchType;
    onTakeAction: (action: string, emailParams: any, ccEmails?: string[], replyToEmails?: string[]) => PromiseDispatchType;
    onSearchInbox: (searchTerm: string) => void;
    handleAdditionalEmailsChange?: (emails: string[], submissionId: number) => void;
    isDisabled?: boolean;
};

type stateTypes = {
    isEditing: boolean;
    additionalEmails: string[];
    replyToEmail: string;
};

export default class BorNotificationApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    static defaultProps = {
        sendButtonText: 'Send Email',
        isDisabled: false,
    };

    constructor(props: propTypes) {
        super(props);
        this.state = {
            isEditing: false,
            replyToEmail: props.modalData.replyToEmail,
            additionalEmails: props.modalData.additionalCCEmails || [],
        };
        this.props.onGetTemplate();
    }

    handleChange = (fieldName: string, value: any) => {
        let { RESPONSE } = this.props.uiState;
        this.context.setUiState({
            RESPONSE: u({ [fieldName]: value }, RESPONSE),
        });
    };

    handleSendReply = () => {
        const { action } = this.props.modalData;
        const { RESPONSE } = this.props.uiState;
        const { additionalEmails } = this.state;

        this.props.onTakeAction(action, RESPONSE, additionalEmails).then(
            _response => {
                this.props.onClearFormData();
                this.props.onClose(true);
            },
            _xhr => {
                this.props.onClose(true);
            }
        );
    };

    handleCancel = () => {
        this.props.onClearFormData();
        this.props.onClose(true);
    };

    componentWillUnmount() {
        const { RESPONSE } = this.props.uiState;
        this.props.onPutTemplate(RESPONSE.body);
    }

    handleAddAdditionalEmail = (email: string) => {
        const { additionalEmails } = this.state;
        const { modalData, handleAdditionalEmailsChange } = this.props;
        const newAdditionalEmails = [email, ...additionalEmails];
        this.setState({ additionalEmails: newAdditionalEmails });
        handleAdditionalEmailsChange && handleAdditionalEmailsChange(newAdditionalEmails, modalData.currentSubmissionId);
    };

    handleRemoveAdditionalEmail = (email: string) => {
        const { additionalEmails } = this.state;
        const { modalData, handleAdditionalEmailsChange } = this.props;
        const newAdditionalEmails = additionalEmails.filter(e => e !== email);
        this.setState({ additionalEmails: newAdditionalEmails });
        handleAdditionalEmailsChange && handleAdditionalEmailsChange(newAdditionalEmails, modalData.currentSubmissionId);
    };

    render() {
        const { modalData, uiState, sendButtonText } = this.props;
        const didInitializeWithAdditionalEmails = this.props.modalData?.additionalReplyToEmails?.length > 0;
        let { isDisabled } = this.props;
        if (!modalData) {
            return null;
        }
        let { RESPONSE } = uiState;
        isDisabled = isDisabled || RESPONSE.body === '';
        const sendButtonDisabled = isDisabled || didInitializeWithAdditionalEmails;
        return (
            <div className="standard-modal-content flex column jc_sb">
                <TextInput
                    maxLength={50}
                    style={{ minHeight: '30px' }}
                    disabled={true}
                    className="tall-textarea"
                    name="replyToEmail"
                    label="Reply To Email"
                    object={this.state}
                />

                <TagInput
                    tags={this.state.additionalEmails}
                    onRemoveTag={this.handleRemoveAdditionalEmail}
                    onAddTag={this.handleAddAdditionalEmail}
                    placeholder="CC Emails"
                    onValidateTag={validateEmail}
                    fuzzySearch={'inbox'}
                />

                <TextAreaInput
                    showValueTooltip={false}
                    maxLength={5000}
                    disabled={isDisabled}
                    className="tall-textarea"
                    name="body"
                    label="Email Body"
                    onChange={this.handleChange}
                    object={RESPONSE}
                    autoFocus={true}
                />
                <div className="button-row">
                    <button className="green" disabled={sendButtonDisabled} onClick={this.handleSendReply}>
                        {sendButtonText}
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="red"
                        onClick={() => {
                            this.handleCancel();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}
