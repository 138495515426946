import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import AlternativesNamesApp from '../apps/AlternativesNamesApp';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
};

const AlternativeNamesBody = (props: bodyPropTypes) => <AlternativesNamesApp {...props} />;

const modalTypeObj = {
    Body: AlternativeNamesBody,
    Header: DefaultHeaderWithProps('Edit Alternative Names'),
    className: 'client-matching-modal',
};

registerType(ICUBED_MODAL_TYPES.ALTERNATIVE_NAMES, modalTypeObj);
export default modalTypeObj;
