import * as React from 'react';

import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import AddAccountBrokerApp from '../apps/AddAccountBrokerApp';
import { createAccountBroker } from '../../../../actions/ClientActions';
import { getSubmissionDataFromServer } from '../../../../actions/SubmissionActions';
import { Types } from '../../../../ts-types/icubed-types';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmissionId: number;
};

type propTypes = {
    onClose: (close: boolean) => void;
    CONSTANTS: Types.Constants;
    currentSubmissionId: number;
    onAddAccountBroker: (submissionId: number, payload: any) => PromiseDispatchType;
    onGetSubmissionData: (currentSubmissionId: number) => PromiseDispatchType;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    let { currentSubmissionId } = _ownProps;
    return {
        CONSTANTS: state.global.CONSTANTS,
        currentSubmissionId: currentSubmissionId,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onAddAccountBroker: (submissionId: number, payload: any) => {
            return dispatch(createAccountBroker(submissionId, payload));
        },
        onGetSubmissionData: currentSubmissionId => {
            return dispatch(getSubmissionDataFromServer(currentSubmissionId));
        },
    };
};

class AddAccountBroker extends React.Component<propTypes> {
    render() {
        return <AddAccountBrokerApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AddAccountBroker);
export default connected;
