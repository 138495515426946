import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { VoidFn, UserType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import NavPanel from './NavPanel';

type propTypes = {
    onLogOut: VoidFn;
    featureFlags: Types.FeatureFlags;
    me: UserType;
};

const AnalyticsLeftPanel = (props: propTypes) => {
    const userSettings = useSelector((state: Types.RootState) => state.global.userSettings);

    return (
        <div className={classNames('main-left-panel main-panel', { closed: !userSettings.left_panel_opened })}>
            <div className="main-left-content">
                <NavPanel featureFlags={props.featureFlags} />

                <div className="left-bottom-content">
                    <div className="username">{props.me.username}</div>
                    <button
                        onClick={() => {
                            props.onLogOut();
                        }}
                        className="blue"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsLeftPanel;
