import * as React from 'react';

import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import MapScheduleViewApp from '../apps/MapScheduleViewApp';
import { getSubmissionBuildingLocations } from '../../../../utils/submission-helpers';

type ownPropTypes = {
    children?: React.ReactNode;
    ENV: Record<string, any>;
    currentIndex?: number;
};

type propTypes = {
    onClose: (close: boolean) => void;
    editable: boolean;
    locations: any[];
    zoom: any;
} & ownPropTypes;

const mapStateToProps = (state: any, _ownProps: ownPropTypes) => {
    const submissionId = state.submissionsLog.selectedRowId ?? state.submissionsLog.rows[_ownProps.currentIndex ?? 0];
    const locations = getSubmissionBuildingLocations(submissionId, state.submissionsLog.rowData);
    return {
        locations,
        ENV: state.global.ENV,
        locationsAreLoading: state.submissionsLog.rowData[submissionId].locationsAreLoading,
    };
};

const mapDispatchToProps = (_dispatch: DispatchType, _ownProps: ownPropTypes) => {
    return {
        // onClearFormData: () => {
        //     //dispatch(updeepUi(EMPTY_NOTE_OBJECT, 'noteObj'));
        // },
    };
};

class MapScheduleView extends React.Component<propTypes> {
    render() {
        return <MapScheduleViewApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(MapScheduleView);
export default connected;
