import React from 'react';
import { useMemo } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import ValidationBadge from '@archinsurance-viki/property-jslib/src/components/widgets/ValidationBadge';
import { RowValidationType } from '@archinsurance-viki/property-jslib/src/ts-types/tables/data';
import { useAppDispatch } from '../hooks/redux';
import { useCurrentSubmission } from '../hooks/submission';
import api, { useValidateQuery } from '../services/apiSlice';
import { ARCH_DATA_CHECK_STATUSES, ICONS } from '../constants/Constants';
import ArchlinkStatusPanel from '../components/panels/ArchlinkStatusPanel';
import { ApiStatusType, ArchClearanceApiStatus, SubmissionType, ValidationErrorType } from '../ts-types/DataTypes';
type StatusPanelProps = {
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
};

type ErrorPanelWrapperPropsType = {
    children: React.ReactNode;
    numberOfErrors: number;
};

export const expandApiStatus = (statuses: ValidationErrorType[], CONSTANTS: Record<string, any>): ValidationErrorType[] => {
    return (statuses || []).map((status: ValidationErrorType) => {
        return { name: CONSTANTS.ARCH_CLEARANCE_WORKFLOW_STATUS[status.name], type: ICONS[status.type].icon };
    });
};

export const arrayOfStringsToValidationErrors = (a: string[], type = 'ERROR'): ValidationErrorType[] => {
    return (a || []).map(error => {
        return { type, name: error };
    });
};

const checkMessageForBadge = (message: string, keywords: string[]) => {
    let isMatch = false;
    //TODO: hardcoded for generate a badge depending on existance of keywords in error message
    keywords.forEach(keyword => {
        const regex = new RegExp(keyword, 'i');
        if (regex.test(message)) {
            isMatch = true;
            return;
        }
    });
    return isMatch;
};

export const getTables = (props: StatusPanelProps) => {
    const { accountStatuses, submissionStatuses } = props._arch_integration;
    return (
        <>
            <div className="grid-layout gl-2">
                <div className="insured-matching-status stndrd l">
                    <div className="toggle-header no-border-bottom no-max-width">
                        <label>Account Status :</label>
                    </div>

                    <div className="status-messages">
                        {accountStatuses.map((row: ValidationErrorType, idx: number) => {
                            return (
                                <div key={idx} className="errors-wrapper status modal">
                                    <div className="errors-content-item">
                                        <div className={`status-icons green`}>
                                            <span className="material-icons">{row.type}</span>
                                        </div>

                                        <div className="message">{row.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="insured-matching-status stndrd r">
                    <div className="toggle-header no-border-bottom no-max-width">
                        <label>Submission Status :</label>
                    </div>

                    <div className="status-messages">
                        {submissionStatuses.map((row: ValidationErrorType, idx: number) => {
                            return (
                                <div key={idx} className="errors-wrapper status modal">
                                    <div className="errors-content-item">
                                        <div className={`status-icons green`}>
                                            <span className="material-icons">{row.type}</span>
                                        </div>

                                        <div className="message">{row.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export const getConfictsPanel = (props: StatusPanelProps) => {
    const { accountStatuses, submissionStatuses } = props._arch_integration;
    const dataCheckStatuses = ((props._arch_integration || {}).apiStatuses || {}).dataCheckStatuses || {};

    const archNotCalledYet =
        dataCheckStatuses &&
        accountStatuses &&
        submissionStatuses &&
        accountStatuses.length === 0 &&
        submissionStatuses.length === 0 &&
        dataCheckStatuses.name === ARCH_DATA_CHECK_STATUSES.CLIENT_VALIDATION_FAILED;

    const content = <div className={'width-100'}>{getTables(props)}</div>;
    const contentNotCall = (
        <div className="viki-loader validating">
            <div className="txt">Arch Api Calls Pending</div>
        </div>
    );

    return {
        handleContent: <ArchlinkStatusPanel />,
        singleHeight: true,
        className: 'conflicts-status-panel',
        content: archNotCalledYet ? contentNotCall : content,
    };
};

const getValidationPanelErrors = (validations: RowValidationType, currentSubmission: SubmissionType) => {
    const map = new Map<string, string>();
    let errors = validations?.ERROR;
    if (errors) {
        Object.entries(errors).forEach(([key, values], _) => {
            if (key !== 'id') {
                let typedValues = Object.assign([], values);
                typedValues.forEach(value => {
                    let errorMessage = null;
                    if (checkMessageForBadge(value, ['Field is required'])) {
                        // if (value === 'Field is required') {
                        errorMessage = `Empty ${key}. Not calling Arch Clearance API`;
                    } else {
                        errorMessage = value;
                    }

                    if (!map.get(errorMessage)) {
                        map.set(errorMessage, errorMessage);
                    }
                });
            }
        });
    }
    let idErrors = errors?.id ? Object.assign([], errors.id) : [];
    let archErrors = [];
    const reconciliationStatuses = [ArchClearanceApiStatus.RETRY_CLEAR, ArchClearanceApiStatus.RETRY, ArchClearanceApiStatus.ERROR];
    const currentSubmissionRequiresReconciliation = reconciliationStatuses.indexOf(currentSubmission.arch_clearance_api_status) >= 0;
    if (currentSubmissionRequiresReconciliation && currentSubmission.arch_clearance_api_error) {
        // TODO: Sometimes these are JSONs, the backend should handle this
        // see _arch_clearance_process_update_submission_status_response
        archErrors = [{ type: 'ERROR', name: currentSubmission.arch_clearance_api_error }];
    }

    if (currentSubmission.arch_clearance_api_warning) {
        // PRINT-15505
        archErrors = [{ type: 'WARNING', name: currentSubmission.arch_clearance_api_warning }];
    }

    const errorFields = { description_of_operations: 'Desc. of Operations', ofac_check_result: 'OFAC' };
    for (const key in errorFields) {
        const error = validations?.ERROR?.[key];
        if (error) {
            idErrors.push(`${errorFields[key]}: ${error[0]}`);
        }
    }
    idErrors.forEach(message => {
        if (!map.get(message)) {
            map.set(message, message);
        }
    });

    // add OFAC warnings so they appear like errors
    const warnings = validations?.WARN;
    if (warnings) {
        Object.entries(warnings).forEach(([key, values], _) => {
            if (key === 'ofac_check_result') {
                map.set(values[0], values[0]);
            } else if (key === 'id') {
                (values as string[]).forEach(error => {
                    if (error.indexOf('Arch account is in') >= 0) {
                        map.set(error, error);
                    }
                });
            }
        });
    }
    return arrayOfStringsToValidationErrors(Array.from(map.values()), 'ERROR').concat(archErrors);
};

const useValidationPanelErrors = (validations: RowValidationType, currentSubmission: SubmissionType) => {
    return useMemo(() => {
        return getValidationPanelErrors(validations, currentSubmission);
    }, [validations, currentSubmission]);
};

const ValidationsPanelWrapper = ({ children }: { children: React.ReactNode }) => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId });

    return (
        <div className={`insured-matching-status error-panel no-pad`}>
            <div className="errors-wrapper status panel-area tw-flex tw-flex-col">{children}</div>
        </div>
    );
};

/**
 * Formats error messages and retuns them.
 * @param error The error message to be formatted
 * @returns The formatted error message
 */
function formatErrors(error: string): string {
    // error is sometimes a string and sometimes an object
    if (typeof error === 'string' && error.toLowerCase().includes('httpconnectionpool')) {
        return 'The Archlink API timed out. Please retry in a little while.';
    }
    return error;
}

const ErrorPanelLabelContent = () => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    const currentSubmission = useCurrentSubmission();
    const { data } = useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId });

    const validations = data?.validations;
    const validationErrors = useValidationPanelErrors(validations, currentSubmission);

    return (
        <>
            <div className="flex">
                <div className="text">Errors</div>
                <div className="number red-txt">{validationErrors.length}</div>
            </div>
        </>
    );
};

const ErrorsPanelWrapper = ({ children, numberOfErrors }: ErrorPanelWrapperPropsType) => {
    return (
        <div className={'grid-layout gl-2 errors-wrapper panel-area'}>
            <div className="errors-warnings-content pad-1">
                <h1>
                    <span>{numberOfErrors}</span> Errors
                </h1>
                {numberOfErrors > 0 && (
                    <table key={'error-table'} className={classNames('errors-warnings-table', 'error')}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <legend className="small">{null}</legend>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{children}</tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export const ValidationErrorPanel = () => {
    const { submissionId } = useParams<{ submissionId?: string }>();
    const dispatch = useAppDispatch();
    const currentSubmission = useCurrentSubmission();
    const { data, isFetching } = useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId });
    usePusherEvent(`SUBMISSIONS-${submissionId}`, 'validation-dirty', _message => {
        dispatch(api.util.invalidateTags([{ type: 'SubmissionValidation', id: +submissionId }]));
        dispatch(api.util.invalidateTags([{ type: 'ArchStatus', id: +submissionId }]));
    });

    const validations = data?.validations;
    const validationErrors = useValidationPanelErrors(validations, currentSubmission);
    if (isFetching) {
        return (
            <ValidationsPanelWrapper>
                <div className="viki-loader validating">
                    <div className="txt">Validating</div>
                    <div className="progress-indicator below large" />
                </div>
            </ValidationsPanelWrapper>
        );
    }

    return (
        <ErrorsPanelWrapper numberOfErrors={validationErrors.length || 0}>
            <>
                {validationErrors.map((error: ValidationErrorType, index: number) => {
                    // Hardcoded, should be updated as API developed
                    let messageBadges = [];
                    if (checkMessageForBadge(error.name, ['ARCH'])) {
                        const isWarning = error?.name.indexOf('Arch Account Conflict.') === 0;
                        messageBadges.push(<ValidationBadge caption="ARCH API" isError={!isWarning} isWarning={isWarning} />);
                    }

                    if (checkMessageForBadge(error.name, ['empty', 'missing', 'is required'])) {
                        messageBadges.push(<ValidationBadge caption="MISSING DATA" isWarning />);
                    }

                    if (checkMessageForBadge(error.name, ['permission'])) {
                        messageBadges.push(<ValidationBadge caption="MISSING PERMISSION" isError />);
                    }

                    if (checkMessageForBadge(error.name, ['Arch Account Conflict'])) {
                        messageBadges.push(<ValidationBadge caption="ACCOUNT CONFLICT" isError />);
                    }

                    if (checkMessageForBadge(error.name, [' OP ', 'OFAC'])) {
                        messageBadges.push(<ValidationBadge caption="OFAC" isError />);
                    }

                    if (messageBadges.length === 0 && error.type === 'WARNING') {
                        messageBadges.push(<ValidationBadge caption="MISC" isWarning={true} />);
                    }

                    return (
                        <tr key={`${index}_${error.name}`}>
                            <td>{index + 1}</td>
                            <td>
                                <div className="message">{formatErrors(error.name)}</div>
                            </td>
                            <td>{messageBadges}</td>
                        </tr>
                    );
                })}
            </>
        </ErrorsPanelWrapper>
    );
};

export const ValidationRefetchWrapper = () => {
    //TODO: Add for refetching validation errors when submission data changes, should be removed when optimized solution found
    const { submissionId } = useParams<{ submissionId?: string }>();
    const dispatch = useAppDispatch();
    useValidateQuery({ submissionId: +submissionId }, { skip: !submissionId, refetchOnMountOrArgChange: true });
    usePusherEvent(`SUBMISSIONS-${submissionId}`, 'validation-dirty', _message => {
        dispatch(api.util.invalidateTags([{ type: 'SubmissionValidation', id: +submissionId }]));
        dispatch(api.util.invalidateTags([{ type: 'ArchStatus', id: +submissionId }]));
    });
    return null;
};

export const getValidationErrorsPanel = () => {
    return {
        handleContent: <ErrorPanelLabelContent />,
        singleHeight: false,
        className: 'validation-error-panel',
        content: <ValidationErrorPanel />,
    };
};
