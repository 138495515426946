import { useEffect } from 'react';

import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LiteTableDataType, TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, VoidFn } from '../../../../ts-types/DataTypes';
import { useGetImageRightLogQuery } from '../../../../services/apiSlice';

type propTypes = {
    onClose: (close: boolean) => void;
    tableGlossary: TableGlossaryType;
    currentSubmission: SubmissionType;
    CONSTANTS: Types.Constants;
    tableData: LiteTableDataType;
    resetTable: VoidFn;
};

const ImageRightLogApp = (props: propTypes) => {
    const { currentSubmission, tableData, resetTable } = props;
    useGetImageRightLogQuery({ submission_id: currentSubmission.id }, { skip: !currentSubmission?.id });
    useEffect(() => {
        return () => resetTable();
    }, [resetTable]);

    if (!currentSubmission) {
        return null;
    }

    return (
        <>
            <LiteTableContainer
                onRowSelected={null}
                hasActionPanel={false}
                tableConfig={LITE_TABLES.IMAGERIGHTLOGS}
                containerClass="standard-modal-content wide tall"
                tableData={tableData}
            />
        </>
    );
};

export default ImageRightLogApp;
