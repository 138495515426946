export const HOT_KEYS = {
    D: {
        shift: true,
        ctrl: true,
        event: 'handleShowDebugPanel',
    },
    I: {
        shift: true,
        ctrl: true,
        event: 'handleShowImageRightLogPanel',
    },
};
