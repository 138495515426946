import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { getSubmissionDataFromServer, getBuildingDistribution } from './SubmissionActions';
import { setRowToDirty } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';

export function handleRefeshSubmission(data: any) {
    return (dispatch: DispatchType) => {
        let submissionIds = [data.updateData.submission_id];
        if (data.updateData.submission_ids) {
            submissionIds = data.updateData.submission_ids;
        }
        submissionIds.map(sid => {
            dispatch(getSubmissionDataFromServer(sid));
            dispatch(getBuildingDistribution(sid));
        });
    };
}

export function handleRowIsDirty(data: Record<string, any>) {
    return (dispatch: DispatchType) => {
        const submissionId = data.row_id;
        dispatch(setRowToDirty(PAGED_TABLES.SUBMISSIONS, submissionId));
    };
}
