import * as React from 'react';
import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import DebugInfoApp from '../apps/DebugInfoApp';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, VoidFn, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { getDeltaRecords, getInsuredReferences } from '../../../../actions/DebugActions';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { COMBINED_TABLE_GLOSSARY } from '../../../../constants/CombinedTableGlossary';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    insuredReferences: any[];
};

type propTypes = {
    CONSTANTS: Types.Constants;
    tableGlossary: TableGlossaryType;
    onGetDeltaRecords: VoidFn;
    onGetInsuredReferences: () => PromiseDispatchType;
    resetTable: VoidFn;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    return {
        uiState: state.uiState,
        tableData: state.deltaRecords,
        CONSTANTS: state.global.CONSTANTS,
        insuredReferences: state.debug.insuredReferences,
        tableGlossary: COMBINED_TABLE_GLOSSARY.submission,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onGetDeltaRecords: () => {
            return dispatch(getDeltaRecords(ownProps.currentSubmission.id));
        },
        onGetInsuredReferences: () => {
            return dispatch(getInsuredReferences(ownProps.currentSubmission.id));
        },
        resetTable: () => {
            dispatch(resetTable(LITE_TABLES.DELTARECORDS));
        },
    };
};

class DebugInfo extends React.Component<propTypes> {
    componentDidMount() {
        this.props.onGetInsuredReferences().then(() => {
            this.props.onGetDeltaRecords();
        });
    }
    render() {
        return <DebugInfoApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(DebugInfo);
export default connected;
