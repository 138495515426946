import * as React from 'react';
import VikiMap from '@archinsurance-viki/property-jslib/src/components/maps/VIKIMap';

type propTypes = {
    onClose: (close: boolean) => void;
    editable: boolean;
    locations: any[];
    zoom: any;
    ENV: Record<string, any>;
    locationsAreLoading?: boolean;
};

export default class MapScheduleViewApp extends React.Component<propTypes> {
    handleCancel = () => {
        this.props.onClose(true);
    };

    render() {
        const { locations, zoom, editable, ENV, locationsAreLoading } = this.props;
        return (
            <VikiMap
                ENV={ENV}
                className="tall modal-fixed-size map-height-unset"
                editable={editable}
                currentIndex={undefined}
                simple={true}
                zoom={zoom}
                locations={locations}
                onMovedPin={undefined}
                onMarkerClick={undefined}
                locationsAreLoading={locationsAreLoading}
            />
        );
    }
}
