import { combineReducers } from 'redux';
import { filterActions } from 'redux-ignore';
import pagedTableReducer from '@archinsurance-viki/property-jslib/src/reducers/paged_table';
import liteTableReducer from '@archinsurance-viki/property-jslib/src/reducers/lite_table';

import userReducer, { RELATED_ACTION_TYPES as USER_RELATED_ACTION_TYPES } from '@archinsurance-viki/property-jslib/src/reducers/user';
import notesReducer, { RELATED_ACTION_TYPES as NOTES_RELATED_ACTION_TYPES } from '@archinsurance-viki/property-jslib/src/reducers/notes';
import jslibReducers from '@archinsurance-viki/property-jslib/src/reducers';

import debugReducer, { RELATED_ACTION_TYPES as DEBUG_RELATED_ACTION_TYPES } from './debug';
import inboxSlice from './inboxes';

import { UserType } from '../ts-types/DataTypes';
import { Types } from '../ts-types/icubed-types';

import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import api from '../services/apiSlice';

const filterUnrelatedActions = relatedActions => action => relatedActions.indexOf(action.type) >= 0;
const filterUnrelatedTableActions = tableName => action => action.tableConfig && action.tableConfig.tableName && action.tableConfig.tableName === tableName;

const rootReducer = combineReducers({
    ...jslibReducers<Types.FeatureFlags, Types.Constants, UserType>(),
    inboxes: inboxSlice,
    userState: filterActions(userReducer, filterUnrelatedActions(USER_RELATED_ACTION_TYPES)),
    notes: filterActions(notesReducer, filterUnrelatedActions(NOTES_RELATED_ACTION_TYPES)),
    debug: filterActions(debugReducer, filterUnrelatedActions(DEBUG_RELATED_ACTION_TYPES)),
    accountSearchResults: filterActions(
        liteTableReducer(LITE_TABLES.ACCOUNT_SEARCH_RESULTS.tableName),
        filterUnrelatedTableActions(LITE_TABLES.ACCOUNT_SEARCH_RESULTS.tableName)
    ),
    submissionsLog: filterActions(pagedTableReducer(PAGED_TABLES.SUBMISSIONS.tableName), filterUnrelatedTableActions(PAGED_TABLES.SUBMISSIONS.tableName)),
    accountConflicts: filterActions(
        liteTableReducer(LITE_TABLES.ACCOUNTCONFLICTS.tableName),
        filterUnrelatedTableActions(LITE_TABLES.ACCOUNTCONFLICTS.tableName)
    ),
    submissionConflicts: filterActions(
        liteTableReducer(LITE_TABLES.SUBMISSIONCONFLICTS.tableName),
        filterUnrelatedTableActions(LITE_TABLES.SUBMISSIONCONFLICTS.tableName)
    ),
    deltaRecords: filterActions(liteTableReducer(LITE_TABLES.DELTARECORDS.tableName), filterUnrelatedTableActions(LITE_TABLES.DELTARECORDS.tableName)),
    imageRightLogs: filterActions(liteTableReducer(LITE_TABLES.IMAGERIGHTLOGS.tableName), filterUnrelatedTableActions(LITE_TABLES.IMAGERIGHTLOGS.tableName)),
    clientMatches: filterActions(liteTableReducer(LITE_TABLES.CLIENTMATCHES.tableName), filterUnrelatedTableActions(LITE_TABLES.CLIENTMATCHES.tableName)),
    brokerMatches: filterActions(liteTableReducer(LITE_TABLES.BROKERMATCHES.tableName), filterUnrelatedTableActions(LITE_TABLES.BROKERMATCHES.tableName)),
    brokerOfficeMatches: filterActions(
        liteTableReducer(LITE_TABLES.BROKEROFFICEMATCHES.tableName),
        filterUnrelatedTableActions(LITE_TABLES.BROKEROFFICEMATCHES.tableName)
    ),
    duplicateSubmissions: filterActions(
        liteTableReducer(LITE_TABLES.DUPLICATESUBMISSIONS.tableName),
        filterUnrelatedTableActions(LITE_TABLES.DUPLICATESUBMISSIONS.tableName)
    ),
    conflictTable: filterActions(liteTableReducer(LITE_TABLES.CONFLICTTABLE.tableName), filterUnrelatedTableActions(LITE_TABLES.CONFLICTTABLE.tableName)),
    borSubmissions: filterActions(liteTableReducer(LITE_TABLES.BORSUBMISSIONS.tableName), filterUnrelatedTableActions(LITE_TABLES.BORSUBMISSIONS.tableName)),
    producerSearchResults: filterActions(
        liteTableReducer(LITE_TABLES.PRODUCER_SEARCH_RESULTS.tableName),
        filterUnrelatedTableActions(LITE_TABLES.PRODUCER_SEARCH_RESULTS.tableName)
    ),
    [api.reducerPath]: api.reducer,
    alternativeNames: filterActions(
        liteTableReducer(LITE_TABLES.ALTERNATIVENAMES.tableName),
        filterUnrelatedTableActions(LITE_TABLES.ALTERNATIVENAMES.tableName)
    ),
});

export default rootReducer;
