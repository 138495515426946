import * as React from 'react';

import isEqual from 'react-fast-compare';
import { debounce } from 'lodash';
import { Types } from '../../../../ts-types/icubed-types';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { VoidFn, SubmissionType, ApiStatusType, InboxType, PromiseDispatchType, ValidationErrorType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import {
    VMAC_NO_ACCOUNT_CONFLICTS_EVENT,
    VMAC_CLIENT_MATCHING_SEARCH_EVENT,
    VMAC_ACCOUNT_MATCHING_SELECTION_EVENT,
} from '../../../../constants/AnalyticsConstants';
import { ARCH_API_MESSAGES, ARCH_DATA_CHECK_STATUSES, ARCH_STATUSES } from '../../../../constants/Constants';
import { arrayOfStringsToValidationErrors } from '../../../../utils/account-and-submission-conflct-utils';
import ErrorsAndWarningsPanel from '../../../panels/ErrorsAndWarningsPanel';
import { ComboBoxInput, TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';

type propTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onSelectNewAccount: (archId: string) => PromiseDispatchType;
    onSearchArchAccounts: (name: string, street: string, fein: string, state: string) => PromiseDispatchType;
    resetTable: VoidFn;
    onMarkRowAsSelected: (rowId: number) => void;
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    onGetApiStatuses: () => void;
    currentInbox: InboxType;
};

type stateTypes = {
    city: string;
    street: string;
    state: string;
    fein: string;
    name: string;
    errors?: string[];
    progressText: string;
    showProgress: boolean;
    didRetryArch: boolean;
    archOverrideAccountId?: string;
};

const COMMON_BLOCKING_ERRORS = ['is not a valid Underwriter'];
const ARCH_BLOCKING_ERRORS = [
    ...COMMON_BLOCKING_ERRORS,
    'A valid ProducerId is required',
    'Unable to connect to conflict service',
    'Account number in the request and account number in the submission are mismatched.',
];
const IGNORABLE_BLOCKING_ERRORS = ['Empty insured fields. Not calling Arch Clearance API'];
const RETRY_ARCH_BLOCKING_ERRORS = [...COMMON_BLOCKING_ERRORS];

export default class AccountSearchApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    initialSearchValue = '';
    didPerformInitialArchRequest = false;
    lastSelectedArchAccount: null;

    constructor(props: propTypes) {
        super(props);

        const { currentSubmission } = props;

        const city = currentSubmission.arch_account_city || '';
        const street = currentSubmission.arch_account_street || '';
        const state = currentSubmission.arch_account_state || '';
        const fein = currentSubmission.arch_account_fein || '';
        const name = currentSubmission.arch_account_name || currentSubmission.arch_account_name_as_parsed;

        const isValidState = state && props.CONSTANTS.STATES.filter(s => s.value === state).length > 0;

        this.state = {
            name,
            fein,
            city,
            street,
            errors: null,
            showProgress: false,
            didRetryArch: false,
            archOverrideAccountId: null,
            state: isValidState ? state : '',
            progressText: 'Searching Arch Accounts...',
        };

        this.getMatches();
    }

    componentWillUnmount() {
        this.props.resetTable();
    }

    _logEvent(onPageLoad: boolean) {
        const { currentSubmission } = this.props;
        this.context.analytics.track(VMAC_NO_ACCOUNT_CONFLICTS_EVENT, {
            onPageLoad,
            submission_id: currentSubmission.id,
            archData: this.props._arch_integration,
            archStatus: currentSubmission.arch_clearance_api_status,
        });
    }

    shouldComponentUpdate(nextProps: propTypes, nextState: Record<string, any>) {
        const shouldUpdate = !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
        return shouldUpdate;
    }

    getCommonBlockingErrors = (): string[] => {
        return [...COMMON_BLOCKING_ERRORS, ...Object.values(this.props.CONSTANTS.ARCH_CLEARANCE_VALIDATION_ERRORS)];
    };

    getArchBlockingEerrors = (): string[] => {
        return [...ARCH_BLOCKING_ERRORS, ...this.getCommonBlockingErrors()].filter(err => IGNORABLE_BLOCKING_ERRORS.indexOf(err) < 0);
    };

    getRetryArchLinkBlockingErrors = (): string[] => {
        return [...this.getCommonBlockingErrors(), ...RETRY_ARCH_BLOCKING_ERRORS];
    };

    handleCancel = () => {
        this.props.onClose(true);
    };

    shouldPerformSearch = () => {
        const { name, street, fein, state } = this.state;
        return name || fein || street || state;
    };

    getMatches = debounce(() => {
        const { name, street, fein, state } = this.state;
        if (this.shouldPerformSearch()) {
            this.setState({ showProgress: true });
            this.props.onSearchArchAccounts(name, street, fein, state).finally(() => {
                this.setState({ showProgress: false });
            });
        }
    }, 500);

    handleChange = (field: any, e: any) => {
        this.setState({ [field]: e.target.value } as stateTypes);
        this.getMatches();
        this.context.analytics.track(VMAC_CLIENT_MATCHING_SEARCH_EVENT, { searchTerm: e.target.value });
    };

    handleChangeState = (_key: string, value: any) => {
        this.setState({ state: value });
        this.getMatches();
    };

    onSelectOverride = (rowId: number, _newRowIndex: number, newRowData: any) => {
        const { currentSubmission, currentInbox, onMarkRowAsSelected, onSelectNewAccount } = this.props;
        const { arch_id, name } = newRowData;
        this.setState({ showProgress: true, progressText: `Selecting ${arch_id}...` });
        onMarkRowAsSelected(rowId);
        onSelectNewAccount(arch_id)
            .then(() => this.setState({ showProgress: false }))
            .catch(e => {
                this.setState({ showProgress: false });
                console.log('AccountSearchApp.onSelectOverride exception', e);
            });
        this.context.analytics.track(VMAC_ACCOUNT_MATCHING_SELECTION_EVENT, {
            account_name: name,
            account_number: arch_id,
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
    };

    renderSearchBarIcon() {
        return this.state.showProgress ? (
            <div className="icon search-progress">
                <div className="progress-indicator small black" />
            </div>
        ) : (
            <div className="icon icon-search"></div>
        );
    }

    getArchValidationMessages(): ValidationErrorType[] {
        const apiStatuses = this.props._arch_integration.apiStatuses;
        if (!apiStatuses) {
            return [];
        }
        if (apiStatuses.globalStatus === ARCH_STATUSES.ERROR) {
            return [];
        }
        const { accountStatuses, submissionStatuses } = this.props._arch_integration;
        const dataCheckStatuses = ((this.props._arch_integration || {}).apiStatuses || {}).dataCheckStatuses || {};
        const archNotCalledYet =
            this.props._arch_integration.apiStatuses.globalStatus === 'NOT_CALLED' ||
            (dataCheckStatuses &&
                accountStatuses &&
                submissionStatuses &&
                accountStatuses.length === 0 &&
                submissionStatuses.length === 0 &&
                dataCheckStatuses.name === ARCH_DATA_CHECK_STATUSES.CLIENT_VALIDATION_FAILED);

        return archNotCalledYet ? [] : arrayOfStringsToValidationErrors([ARCH_API_MESSAGES[apiStatuses.globalStatus]], apiStatuses.globalStatus);
    }

    isSystemInProgress() {
        return this.state.showProgress;
    }

    handleAccountOverride = (key: string, value: any) => {
        this.setState({ archOverrideAccountId: value, errors: null });
    };

    handleSetOverride = () => {
        let archId = this.state.archOverrideAccountId;
        if (archId && archId.length > 1) {
            if (archId[0].toUpperCase() === 'A') {
                archId = archId.substr(1);
            }
            this.props.onSelectNewAccount(archId);
            this.setState({ archOverrideAccountId: null });
            this.props.onClose(true);
        }
    };

    getVikiValidationMessages(): ValidationErrorType[] {
        const { currentSubmission } = this.props;
        const errors = currentSubmission && currentSubmission._validations && currentSubmission._validations.ERROR && currentSubmission._validations.ERROR.id;
        return arrayOfStringsToValidationErrors(errors, ARCH_STATUSES.ERROR);
    }

    getAllValidationMessages(): ValidationErrorType[] {
        const validationMessages = this.getVikiValidationMessages().concat(this.getArchValidationMessages());
        // sort s.t. validations that block calls to AL are first
        validationMessages.sort((a, b) => {
            const blockingErrors = this.getArchBlockingEerrors();
            const aContained = blockingErrors.filter(e => a.name.indexOf(e) >= 0).length > 0;
            const bContained = blockingErrors.filter(e => b.name.indexOf(e) >= 0).length > 0;
            return aContained && bContained ? 0 : aContained && !bContained ? -1 : 1;
        });
        return validationMessages;
    }

    filterValidationErrors = (errorList: string[]): ValidationErrorType[] => {
        return this.getAllValidationMessages().filter(v => errorList.filter(e => v.name.indexOf(e) >= 0).length > 0);
    };

    renderGuidancePanel() {
        const { currentSubmission } = this.props;
        const { errors } = this.state;
        const showProgress = this.isSystemInProgress();

        let validationItems = [];
        console.log('AccountSearchApp.renderGuidancePanel', currentSubmission, currentSubmission.arch_clearance_api_error);
        if (currentSubmission.arch_clearance_api_error && String(currentSubmission.arch_clearance_api_error).indexOf('submission conflict') >= 0) {
            validationItems = [{ type: 'ERROR', name: 'ArchLink submission conflict. Please Retry ArchLink and re-action.' }];
        }
        const isSynced = validationItems.length === 1 && validationItems[0]['type'] === ARCH_STATUSES.SUCCESS;

        return (
            <div className="insured-matching-status padding-standard">
                <div className="toggle-header no-border-bottom">
                    <label>Guidance :</label>
                    <If condition={showProgress}>
                        <div className="progress-indicator inline number" />
                    </If>
                    <If condition={!showProgress}>
                        <If condition={!isSynced && validationItems.length > 0}>
                            <span className="material-icons red">report</span>
                        </If>
                        <If condition={isSynced || validationItems.length === 0}>
                            <span className="material-icons green">check_circle</span>
                        </If>
                    </If>

                    <div className="flex f-row override-arch-id">
                        <label>Override Arch Account ID :</label>
                        <TextInput
                            errors={errors}
                            onEveryChange={this.handleAccountOverride}
                            object={this.state}
                            name="archOverrideAccountId"
                            noWrapper={true}
                            disabled={currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                        />
                        <button
                            disabled={!this.state.archOverrideAccountId || currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                            onClick={this.handleSetOverride}
                            className="grey-dark"
                        >
                            Override
                        </button>
                    </div>
                </div>

                <div className="status-messages">
                    <div className="errors-wrapper status modal">
                        <ErrorsAndWarningsPanel items={validationItems} currentSubmission={this.props.currentSubmission} />
                    </div>
                </div>
            </div>
        );
    }

    shouldDisableSearch = () => {
        return false;
    };

    render() {
        const { CONSTANTS, currentSubmission, tableData, ENV } = this.props;
        const { street, fein, name, progressText } = this.state;
        if (!currentSubmission) {
            return null;
        }

        const searchDisabled = this.shouldDisableSearch();
        const isSystemInProgress = this.isSystemInProgress();
        const className = 'gl-1-force';

        let overlayText = null;

        const optionalProps: Record<string, any> = {};
        return (
            <React.Fragment>
                <div className="padding-standard">
                    <div className="flex-table-row row clmn-headers padding-right">
                        <div className="width-100">
                            <label>Search Criteria</label>
                            <div className="tbl-attachment margin-right">
                                <div className="attached-item search-bar grow">
                                    <div className="icon icon-search"></div>
                                    <input value={name} onChange={this.handleChange.bind(this, 'name')}></input>
                                </div>
                                <div className="attached-item search-bar grow">
                                    <input placeholder="Street" value={street} onChange={this.handleChange.bind(this, 'street')}></input>
                                </div>
                                <div className="attached-item search-bar grow">
                                    <input placeholder="FEIN" value={fein} onChange={this.handleChange.bind(this, 'fein')}></input>
                                </div>
                                <div className="attached-item search-bar grow border-adjust">
                                    <ComboBoxInput
                                        name="state"
                                        object={this.state}
                                        noWrapper={true}
                                        onChange={this.handleChangeState}
                                        selectProps={{ choices: CONSTANTS.STATES, nullable: true }}
                                    />
                                </div>
                                <button disabled={searchDisabled} onClick={this.getMatches} className="grey-dark">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="divider-line"></div>

                <div className="grid-layout gl-1 margin-top">{this.renderGuidancePanel()}</div>
                <div className={`grid-layout h-100 ${className} no-gap`}>
                    <div>
                        <div className="clmn-headers padding-standard padding-bottom-none">
                            <label>ARCH ACCOUNT DATA</label>
                        </div>

                        <LiteTableContainer
                            {...optionalProps}
                            disableTable={isSystemInProgress}
                            onRowSelected={null}
                            hasActionPanel={false}
                            tableConfig={LITE_TABLES.ACCOUNT_SEARCH_RESULTS}
                            containerClass="standard-modal-content width-100 no-padding-top h-adjust"
                            tableData={tableData}
                            selectedRowId={2}
                            showProgressText={isSystemInProgress}
                            progressText={progressText}
                            overlayText={overlayText}
                            actionHelperFns={{
                                onSelectNewMatch: this.onSelectOverride,
                            }}
                            extraCellRenderData={{
                                env: ENV.ENV,
                                ARCH_BASE_URL: ENV.ARCH_BASE_URL,
                                currentSubmission,
                                field_name: 'arch_account_arch_id',
                                field_label: 'INSURED',
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
