import * as React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { SubmissionType, ApiStatusType, InboxType, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import ClientMatchingApp from '../apps/ClientMatchingApp';
import { openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';

import { Types } from '../../../../ts-types/icubed-types';
import {
    markRowAsSelected,
    getApiStatuses,
    getAccountConflicts,
    retrySubmissionWithArch,
    resolveArchAccountConflict,
    selectArchAccountForSubmission,
} from '../../../../actions/ArchActions';
import { expandApiStatus } from '../../../../utils/account-and-submission-conflct-utils';
import { getCurrentInbox } from '../../../../utils/inbox-helpers';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    archTableData: LiteTableDataType;
};

type propTypes = {
    onPersistRowData: (field: string, value: any) => void;
    onGetMatchedClient: (field: string) => PromiseDispatchType;
    onSelectNewClient: (newClientId: number) => PromiseDispatchType;
    onGetAccountConflicts: (overrideId?: string) => PromiseDispatchType;
    onSelectAccountId: (accountId: string) => PromiseDispatchType;
    onResolveAccountConflict: (accountId: string) => PromiseDispatchType;
    onRetrySubmissionWithArch: (submissionId: number) => PromiseDispatchType;
    onMarkRowAsSelected: (accountId: number) => void;
    onOpenMessageModal: (params: Record<string, any>, modalType: string) => void;
    resetTable: () => void;
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
    featureFlags: Types.FeatureFlags;
    CONSTANTS: Types.Constants;
    onGetApiStatuses: () => void;
    onSaveFn: (id: number, data: any) => void;
    currentInbox: InboxType;
    ENV: Types.Env;
} & ownPropTypes;

const NEW_INSURED_WARNING = 'This is a new insured. Only select this row if there are no existing insureds that match.';

function appendCurrentInsuredToMatchList(state, currentSubmission) {
    let {
        insured_id,
        insured_name,
        insured_city,
        insured_state,
        insured_street,
        insured_zipcode,
        insured_external_id,
        custom_insured_id,
        custom_insured_name,
        custom_insured_city,
        custom_insured_state,
        custom_insured_street,
        custom_insured_zipcode,
        custom_insured_fein,
    } = currentSubmission;

    let clientMatches: LiteTableDataType = _.cloneDeep(state.clientMatches);

    // want to make sure the list has both the current selected and the custom insured
    let currentInsured = clientMatches.rowData[insured_id];
    if (!currentInsured && insured_id) {
        currentInsured = {
            id: insured_id,
            name: insured_name,
            state: insured_state,
            street: insured_street,
            city: insured_city,
            zipcode: insured_zipcode,
            import_object_id: insured_external_id,
            props: {},
            _errors: {},
        };

        clientMatches.rows.unshift(insured_id);
        clientMatches.rowData[insured_id] = currentInsured;
    }

    let customInsured = clientMatches.rowData[custom_insured_id];
    if (!customInsured && custom_insured_id) {
        customInsured = {
            id: custom_insured_id,
            name: custom_insured_name,
            state: custom_insured_state,
            street: custom_insured_street,
            city: custom_insured_city,
            zipcode: custom_insured_zipcode,
            fein: custom_insured_fein,
            import_object_id: null,
            props: {},
        };
        clientMatches.rows.unshift(custom_insured_id);
        clientMatches.rowData[custom_insured_id] = customInsured;
    }

    // add a warning about creating a new insured
    for (let insured of Object.values(clientMatches.rowData)) {
        if (insured.id === custom_insured_id) {
            insured._validations = {
                WARN: {
                    select: NEW_INSURED_WARNING,
                    name: true,
                },
                ERROR: {},
            };
        }
    }

    return clientMatches;
}

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { CONSTANTS, ENV, featureFlags } = state.global;

    // this is needed here because it fixes the issue rows in the left grid of the insured
    // modal were not being selected.
    const currentSubmission = state.submissionsLog.rowData[ownProps.currentSubmission.id];
    const { apiStatuses } = currentSubmission;

    const currentInbox = getCurrentInbox(state);

    return {
        ENV,
        featureFlags,
        currentSubmission,
        CONSTANTS,
        currentInbox,
        uiState: state.uiState,
        tableData: appendCurrentInsuredToMatchList(state, ownProps.currentSubmission),
        archTableData: state.accountConflicts,
        _arch_integration:
            {
                submissionStatuses: expandApiStatus(apiStatuses && apiStatuses['submission'], CONSTANTS),
                accountStatuses: expandApiStatus(apiStatuses && apiStatuses['account'], CONSTANTS),
                apiStatuses,
            } || {},
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    // note, this tableData is parentTableData, not the tableData powering the litetable used in the modal.
    let { currentSubmission } = ownProps;
    return {
        onGetApiStatuses: () => {
            dispatch(getApiStatuses(currentSubmission.id));
        },
        onGetAccountConflicts: () => {
            return dispatch(getAccountConflicts(currentSubmission.id));
        },
        onSelectAccountId: (accountId: string) => {
            return dispatch(selectArchAccountForSubmission(currentSubmission.id, accountId));
        },
        onResolveAccountConflict: (accountId: string) => {
            return dispatch(resolveArchAccountConflict(currentSubmission.id, accountId));
        },
        onMarkRowAsSelected: (accountId: number) => {
            dispatch(markRowAsSelected(accountId));
        },
        onRetrySubmissionWithArch: (submissionId: number) => {
            dispatch(retrySubmissionWithArch(submissionId));
        },
        onOpenMessageModal: (modalData, modalType) => {
            dispatch(openMessageModal(modalData, modalType));
        },
    };
};

class ClientMatching extends React.Component<propTypes> {
    render() {
        return <ClientMatchingApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(ClientMatching);
export default connected;
