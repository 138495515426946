import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import classNames from 'classnames';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { ModalType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { AppConstants } from '@archinsurance-viki/property-jslib';
import { InboxType, SubmissionType, UserType, VoidFn } from '../../ts-types/DataTypes';
import PanelView from '../layout/PanelView';
import DocumentsViewer from '@archinsurance-viki/property-jslib/src/components/documents/DocumentsViewer';
import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import { PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { Types } from '../../ts-types/icubed-types';
import { SUBMISSION_EMAIL } from '../../constants/Constants';
import PreviewLeftPanel from '../layout/PreviewLeftPanel';

type propTypes = {
    currentInbox: InboxType;
    inboxes: InboxType[];
    currentSubmissionId: number;
    currentSubmission: SubmissionType;
    onLogOut: VoidFn;
    onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => void;
    onDownloadDocument: (document: DocumentDataType) => void;
    onDownloadAllSubmissionDocs: (submissionId: number) => void;
    onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => void;

    onClassifySelectedText: (documentId: number, text: string) => void;
    openPanels: Record<string, any>;
    centeredModalData: ModalType;
    centeredModalType: string;
    CONSTANTS: Types.Constants;
    me: UserType;
    ENV: Types.Env;
    featureFlags: Types.FeatureFlags;
    uiState: Record<string, any>;
    tableData: PagedTableDataType;

    onTogglePanel: (panel: string) => void;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    setUiState: (params: Record<string, any>, path: string) => void;
    onCloseCenteredModal: VoidFn;
};

const filterDocuments = (docs: DocumentDataType[], typeConstants: Types.Constants) => {
    return _.filter(docs, d => {
        if (d.filename === null || d.filename === undefined) return false;
        let ext = d.filename.substring(d.filename.lastIndexOf('.') + 1, d.filename.length) || d.filename;
        return !typeConstants.NON_PREVIEWABLE_TYPES.includes(ext);
    });
};

const DocumentsPreviewContainer = (props: propTypes) => {
    const [selectedDocIndex, setSelectedDocIndex] = useState(0);
    const [isScreenCapturing, setIsScreenCapturing] = useState(false);

    const onSelectDocument = (document: Record<string, any>, selectedDocIndex: number) => {
        setSelectedDocIndex(selectedDocIndex);
    };
    const onDownloadDocument = (id: number) => {
        let doc = _.find(props.currentSubmission.documents, function (d) {
            return d.id === id;
        });

        doc = {
            ...doc,
            secure_url: `${AppConstants.FILE_PROXY_BASE_URL}?document_id=${doc.id}`,
        };

        return props.onDownloadDocument(doc);
    };

    const filteredDocuments = useMemo(() => filterDocuments(props.currentSubmission.documents, props.CONSTANTS), [props.currentSubmission, props.CONSTANTS]);
    const selectedDocument = filteredDocuments[selectedDocIndex];
    const toggleIsScreenCapturing = () => {
        setIsScreenCapturing(!isScreenCapturing);
    };

    const _renderMainContent = () => {
        let { onOcrImage, CONSTANTS, featureFlags, onClassifySelectedText } = props;

        if (!props.currentSubmission || !props.currentSubmission.id) {
            return <div className="center-text">Please select a submission</div>;
        }

        const { documents } = props.currentSubmission;

        if (!props.currentSubmission.id || !documents) {
            return <div className="center-text">Loading...</div>;
        }

        if (!documents.length) {
            return <div className="center-text">This submission has no documents</div>;
        }

        const onDownloadAllDocuments = () => {
            return props.onDownloadAllSubmissionDocs(props.currentSubmission.id);
        };
        if (!selectedDocument) {
            throw new Error('Preview not supported for this document');
        }

        return (
            <DocumentsViewer
                renderAnnotations={false}
                inline={true}
                dontStealFocus={true}
                allowEditDocType={selectedDocument.type !== SUBMISSION_EMAIL}
                documents={filteredDocuments}
                selectDocument={onSelectDocument}
                selectedDocumentId={selectedDocument.id}
                selectedDocumentIndex={selectedDocIndex}
                onDownloadDocument={onDownloadDocument}
                onDownloadAllDocuments={onDownloadAllDocuments}
                onSaveDocumentData={props.onSaveDocumentData}
                onOcrImage={onOcrImage}
                onClassifySelectedText={onClassifySelectedText}
                documentTypes={CONSTANTS.DOCUMENT_TYPE}
                featureFlags={featureFlags}
                toggleIsScreenCapturing={toggleIsScreenCapturing}
                isScreenCapturing={isScreenCapturing}
                isFullScreenMode
            />
        );
    };

    if (!props.currentSubmission.id || !props.currentSubmission.documents) {
        return (
            <div id="documents-preview" className={classNames('doc-viewer')}>
                <div className={classNames('root-container')}>
                    <div className="center-text">Loading...</div>
                </div>
            </div>
        );
    }

    const AppLeftPanel = (
        <PreviewLeftPanel
            inboxes={props.inboxes}
            onSelectInbox={props.onSelectInbox}
            onLogOut={props.onLogOut}
            openPanels={props.openPanels}
            featureFlags={props.featureFlags}
            documents={filteredDocuments}
            selectDocument={onSelectDocument}
            selectedDocumentId={selectedDocument.id}
            selectedDocumentIndex={selectedDocIndex}
            onDownloadDocument={onDownloadDocument}
            onDownloadAllDocuments={() => {
                props.onDownloadAllSubmissionDocs(props.currentSubmission.id);
            }}
        />
    );
    return ReactDOM.createPortal(
        <React.Fragment>
            <div id="documents-preview" className={classNames('doc-viewer')}>
                <div className={classNames('root-container')}>
                    <CenteredModal
                        CONSTANTS={props.CONSTANTS}
                        uiState={props.uiState}
                        ENV={props.ENV}
                        modalData={props.centeredModalData}
                        modalType={props.centeredModalType}
                        onClose={props.onCloseCenteredModal}
                    />
                    <PanelView
                        currentSubmission={props.currentSubmission}
                        openPanels={props.openPanels}
                        ENV={props.ENV}
                        uiState={props.uiState}
                        featureFlags={props.featureFlags}
                        documents={filteredDocuments}
                        selectedDocumentIndex={selectedDocIndex}
                        leftPanel={AppLeftPanel}
                        isFullScreenViewMode={true}
                    >
                        <div className="main-content-center grid-layout gl-1 fixed grow">{_renderMainContent()}</div>
                    </PanelView>
                </div>
            </div>
        </React.Fragment>,
        document.body
    );
};

export default DocumentsPreviewContainer;
