import _ from 'lodash';

import { calculateExposureGroup } from '@archinsurance-viki/property-jslib/src/utils/exposure-helpers';
import { ARCH_CLEARANCE_API_SUBMISSION_STATUSES } from './Constants';

type HeaderButtonType = {
    buttonName: string;
    callbackFn: string;
    disabledFn: any;
    throttleSeconds?: number;
};

export const CLEARANCE_INFOBLOCKS: { [s: string]: any } = {
    // TODO: delete broker block then we deprecate producer on submission
    broker: {
        header: 'Broker',
        rows: ['panp', 'pai', 'lai', 'pppc', 'pid', 'rui', 'oui', 'rte'],
        hideBlock: (props: Record<string, any>) => {
            const { CONSTANTS, currentSubmission } = props;
            return currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.RETAIL || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS;
        },
        headerButtons: (props: Record<string, any>) => {
            const { CONSTANTS, currentSubmission } = props;
            const buttons: HeaderButtonType[] = [
                {
                    callbackFn: 'onHandleBrokerMatching',
                    buttonName: currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS ? 'Lookup Broker Office' : 'Lookup Broker',
                    disabledFn: currentSubmission => currentSubmission.status === 'A' || currentSubmission.is_renewal_linked,
                },
                {
                    callbackFn: 'onHandleAddAccountBroker',
                    buttonName: 'Add Broker',
                    disabledFn: currentSubmission => currentSubmission.status === 'A' || currentSubmission.is_renewal_linked,
                },
            ];
            return buttons;
        },
    },
    retail_broker: {
        header: 'Broker',
        type: 'Retail',
        rows: ['rbonp', 'rbon', 'pppc', 'rpol', 'rabcn', 'rui', 'oui', 'rte'],
        hideBlock: (props: Record<string, any>) => {
            const { CONSTANTS, currentSubmission, featureFlags } = props;
            // if the submission is in the retail inbox or the smms inbox, then show the block if the enable_notouch_clearance feature flag is disabled
            return (
                (currentSubmission.inbox_id !== CONSTANTS.INBOX_IDS.RETAIL && currentSubmission.inbox_id !== CONSTANTS.INBOX_IDS.SMMS) ||
                featureFlags.enable_notouch_clearance
            );
        },
        headerButtons: (props: Record<string, any>) => {
            const { CONSTANTS } = props;
            const buttons: HeaderButtonType[] = [
                {
                    callbackFn: 'onHandleBrokerMatching',
                    buttonName: 'Lookup Broker Office',
                    disabledFn: currentSubmission => currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS,
                },
            ];
            return buttons;
        },
    },
    arch_broker: {
        header: 'Broker',
        rows: ['panp', 'pan', 'lai', 'pn', 'apn', 'rui', 'oui', 'rte'],
        hideBlock: (props: Record<string, any>) => {
            return true;
        },
        headerButtons: (props: Record<string, any>) => {
            const { CONSTANTS } = props;
            const buttons: HeaderButtonType[] = [
                {
                    callbackFn: 'onHandleProducerSearch',
                    buttonName: 'Search Producers',
                    disabledFn: currentSubmission =>
                        currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS || currentSubmission.is_renewal_linked,
                },
                {
                    callbackFn: 'onHandleProducerContactSearch',
                    buttonName: 'Search Contacts',
                    disabledFn: currentSubmission =>
                        currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS || currentSubmission.is_renewal_linked,
                },
                {
                    callbackFn: 'onHandleAddProducerContact',
                    buttonName: 'Add Contact',
                    disabledFn: currentSubmission =>
                        currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS || currentSubmission.is_renewal_linked,
                },
            ];
            return buttons;
        },
    },
    insured: {
        header: 'Insured',
        rows: ['aanid', 'inp', 'iname', 'istreet', 'icity', 'istate', 'iz', 'if'],
        enableClientMatching: true,
        headerButtons: (props: any) => {
            const { CONSTANTS, currentSubmission, featureFlags } = props;
            const buttons: HeaderButtonType[] = [
                {
                    callbackFn: 'onHandleAccountSearch',
                    buttonName: 'Search',
                    disabledFn: currentSubmission =>
                        currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS || currentSubmission.is_renewal_linked,
                },
            ];

            const enableEarlyAccountAltNamesFF = featureFlags.enable_early_account_alt_names_ff;
            if (currentSubmission.arch_clearance_api_status === ARCH_CLEARANCE_API_SUBMISSION_STATUSES.ACCOUNT_CONFLICT) {
                buttons.push({
                    callbackFn: 'onHandleConflictResolution',
                    buttonName: 'Resolve Conflict',
                    disabledFn: currentSubmission => currentSubmission.status === 'A' || currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS,
                });
            } else if (enableEarlyAccountAltNamesFF) {
                buttons.push({
                    buttonName: 'Create New',
                    callbackFn: 'onHandleCreateAccount',
                    disabledFn: () =>
                        currentSubmission.arch_account_arch_id ||
                        currentSubmission._validations?.ERROR?.id?.includes(CONSTANTS.ARCH_CLEARANCE_VALIDATION_ERRORS.EMPTY_ARCH_ACCOUNT),
                    throttleSeconds: 20,
                });
            }

            if (!enableEarlyAccountAltNamesFF) {
                const currentSubmissionLinkedToArchAccount =
                    currentSubmission && currentSubmission.arch_account && currentSubmission.arch_account_arch_id && currentSubmission.status === 'U';

                if (currentSubmissionLinkedToArchAccount) {
                    buttons.push({
                        buttonName: 'Create New',
                        callbackFn: 'onHandleUnlinkAccount',
                        disabledFn: _currentSubmission => currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS || currentSubmission.is_renewal_linked,
                        throttleSeconds: 20,
                    });
                }
            }

            return buttons;
        },
    },
    dates: {
        header: 'Dates',
        rows: ['iinception', 'iexpiration', 'inb', 'dr', 'dti'],
    },
    target_terms: {
        header: 'Target Terms',
        rows: ['ibd', 'itarget', 'irate', 'ir'],
    },
    sov_information: {
        header: 'SOV Info',
        rows: ['sovtype', 'sovt', 'sovtc', 'sovtbi', 'sovtt', 'sovbc', 'sovbt'],
        readonly: true,
        headerButtons: [
            { callbackFn: 'onPopScheduleView', buttonName: 'View Buildings' },
            { callbackFn: 'onHandleViewBuildingDistributions', buttonName: 'View Distributions' },
        ],
    },
    conflicting_information: {
        header: 'Conflicting Info',
        rows: ['bbo', 'bbfn', 'bdtr', 'besid', 'bin', 'btiv', 'beffdt'],
        hideBlock: (props: Record<string, any>) => {
            let { currentSubmission } = props;
            //return !currentSubmission || !currentSubmission.is_likely_blocked;\
            return (
                !currentSubmission ||
                !currentSubmission.is_likely_blocked ||
                (currentSubmission.is_likely_blocked &&
                    _.isNull(currentSubmission.blocking_external_submission_id) &&
                    _.isNull(currentSubmission.blocking_duplicate_submission_id))
            );
        },
    },
    submission_assignment: {
        header: 'Assignment',
        rows: ['atid', 'arf', 'igp'],
    },
    debug_statistics: {
        header: 'Jobs',
        rows: [
            'last_archlink_ofac_check_at',
            'insured_last_ofac_submit_status',
            'insured_last_ofac_submit_by_name',
            'last_sov_bot_received_on',
            'arch_clearance_api_change_on',
            'arch_clearance_api_received_on',
        ],
    },
    ofac: {
        header: 'OFAC',
        rows: ['apoc', 'hs', 'bs'],
        headerButtons: (props: any) => {
            return props.featureFlags.debug_features
                ? [
                      {
                          callbackFn: 'onHandleViewOfacResults',
                          buttonName: 'View OFAC Results',
                      },
                  ]
                : [];
        },
    },
    tbbo: {
        name: 'Blocking Broker Office',
        field_name: 'tbbo',
        readonly: true,
    },
    tbb: {
        name: 'Blocking Broker',
        field_name: 'tbb',
        readonly: true,
    },
    tdtr: {
        name: 'Date/Time Received',
        field_name: 'tdtr',
        readonly: true,
    },
    tvsid: {
        name: 'VIKI SID',
        field_name: 'tvsid',
        readonly: true,
    },
    tcn: {
        name: 'Temp Client Name',
        field_name: 'tcn',
        readonly: true,
    },
    ttiv: {
        name: 'TIV (total)',
        field_name: 'ttiv',
        readonly: true,
    },
    iname: {
        label: ({ helperFunctions, extraData }) => (
            <span className="tw-flex tw-gap-2">
                Arch Account Name
                {extraData?.featureFlags.alternate_names_enabled_ff && (
                    <div className="status-badge w-icon blue margin-left-05 cursor-pointer">
                        <span className="material-icons" onClick={() => helperFunctions?.handleOpenAlternativeNames?.()}>
                            dns
                        </span>
                    </div>
                )}
            </span>
        ),
        field_name: 'iname',
        readonly: true,
    },
    ted: {
        name: 'Effective Date',
        field_name: 'ted',
        readonly: true,
    },
    dist_by_construction: {
        header: 'Distribution by Const Type',
        readonly: true,
        rowObjectGetter: (props: any) => {
            let { currentSubmission } = props;
            if (!currentSubmission || !currentSubmission.distribution) {
                return [];
            }
            return calculateExposureGroup(currentSubmission.distribution, 'construction_type');
        },
        columns: [
            { key: 'type', className: 'width-60' },
            { key: 'bldgs', className: 'width-10' },
            { key: 'tiv', className: 'width-30' },
        ],
    },
    dist_by_occupancy: {
        header: 'Distribution by Occupancy',
        readonly: true,
        rowObjectGetter: (props: any) => {
            let { currentSubmission } = props;
            if (!currentSubmission || !currentSubmission.distribution) {
                return [];
            }
            return calculateExposureGroup(currentSubmission.distribution, 'occupancy__description');
        },
        columns: [
            { key: 'type', className: 'width-60' },
            { key: 'bldgs', className: 'width-10' },
            { key: 'tiv', className: 'width-30' },
        ],
    },
    general_notes: {
        header: 'Notes',
        className: 'grid-2-wide',
        alwaysShowAddRowIcon: true,
        type: 'NOTE',
        readonly: false,
        rowObjectGetter: 'currentNotes',
        columns: [
            { key: 'created_by_full_name', className: 'width-10' },
            {
                key: 'is_draft',
                customRenderFn: note => (
                    <div key={note.id} className="input-div flex text-input draft-icon width-1">
                        {note.is_draft && <span className="material-icons blue-txt">pending</span>}
                    </div>
                ),
            },
            { key: 'created', className: 'width-10' },
            { key: 'subject', className: 'width-20' },
            { key: 'message', className: 'width-50' },
        ],
    },
    message: {
        name: 'Message',
        readonly: true,
    },
    wsp: {
        field_name: 'sov_was_parsed',
        readonly: true,
        name: 'ACORD Parsing',
        field_format: { subtype: 'badge' },
    },
    change_by_id: {
        name: 'Changed By',
        readonly: true,
    },
    change_by_full_name: {
        name: 'Changed By',
        readonly: true,
    },
    subject: {
        name: 'Subject',
        readonly: true,
    },
};
