import React from 'react';

const BorPermission = () => {
    return (
        <div className="w-100 flex column overflow-y">
            <div className="flex fd-r jc_c">
                <div className="info-block row-editable extra-padding">
                    <div className="header">
                        <div className="header-text">Permissions</div>
                    </div>
                    <div className="info-rows">
                        <div>Permission Error</div>

                        <b className="red">
                            <div className="badge badge-danger w-fit material-icons md-12">do_not_disturb_on</div> You do not have permission to review this BOR
                            request, please contact your manager.
                        </b>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BorPermission;
