import * as React from 'react';

import { SubmissionType } from '../../ts-types/DataTypes';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';

import TopPanel from './TopPanel';

import classNames from 'classnames';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    children: React.ReactNode;
    currentSubmission?: SubmissionType;
    openPanels: Record<string, any>;
    ENV: Types.Env;
    uiState: Record<string, any>;
    featureFlags: Types.FeatureFlags;
    isFullScreenViewMode?: boolean;
    documents?: DocumentDataType[];
    selectedDocumentIndex?: number;
    leftPanel: React.ReactNode;
    topPanelHeader?: string;
};

const PanelView = (props: propTypes) => {
    const filename = props.documents?.[props.selectedDocumentIndex]?.title ?? '';
    return (
        <>
            <div className={classNames('main-content')}>
                {props.leftPanel}
                {props.children}
            </div>
            <TopPanel
                currentSubmission={props.currentSubmission}
                ENV={props.ENV}
                uiState={props.uiState}
                openPanels={props.openPanels}
                featureFlags={props.featureFlags}
                isFullScreenMode={props.isFullScreenViewMode}
                filename={filename}
                topPanelHeader={props.topPanelHeader}
            />
        </>
    );
};

export default PanelView;
