import * as React from 'react';
import u from 'updeep';
import moment from 'moment';
import _ from 'lodash';

import { TextInput, MaskedTextInput, ComboBoxInput, DateInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';

import { VMAC_OCR_SAVE_DATA, VMAC_OCR_COPY_TO_CLIPBOARD, VMAC_OCR_CHANGE_TAB } from '../../../../constants/AnalyticsConstants';
import { Types } from '../../../../ts-types/icubed-types';
import { PromiseDispatchType } from '../../../../ts-types/DataTypes';

type propTypes = {
    onClose: (close: boolean) => void;
    modalData: {
        submission_id: number;
        parsed_data: Record<string, any>;
        rowData: any;
        ocred_text?: string;
    };
    onPersistRowData: (field: string, value: any) => void;
    onSelectNewClient: () => PromiseDispatchType;
    CONSTANTS: Types.Constants;
};

type stateTypes = {
    clientInformation: Record<string, any>;
    currentOcrTab: string;
    dataParsedCorrectly: boolean;
    currentDateSelection: string;
    isSavingData: boolean;
    copyToClipboard: boolean;
    ocrTexted: string;
};

const MODAL_VIEW_STATES = {
    INSURED_ALL: 'INSURED_ALL',
    INSURED_ADDRESS: 'INSURED_ADDRESS',
    INSURED_NAME: 'INSURED_NAME',
    INSURED_FEIN: 'INSURED_FEIN',
    DATE: 'DATE',
};

const DATE_SELECTIONS = [
    { value: 'inception_date', display: 'Inception Date' },
    { value: 'expiration_date', display: 'Expiration Date' },
];

const calculateSelectedTab = apiResults => {
    if (apiResults.is_date) {
        return MODAL_VIEW_STATES['DATE'];
    } else if (apiResults.is_address) {
        return MODAL_VIEW_STATES['INSURED_ALL'];
    } else if (apiResults.is_fein) {
        return MODAL_VIEW_STATES['INSURED_FEIN'];
    } else if (apiResults.is_text) {
        return MODAL_VIEW_STATES['INSURED_NAME'];
    } else {
        return MODAL_VIEW_STATES['INSURED_ADDRESS'];
    }
};

export default class OcrResultsApp extends React.Component<propTypes, stateTypes> {
    textAreaRef: any;

    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);
        this.textAreaRef = React.createRef();
        this.state = {
            ocrTexted: this.props.modalData.ocred_text,
            clientInformation: this.props.modalData.parsed_data,
            currentOcrTab: calculateSelectedTab(this.props.modalData.parsed_data),
            dataParsedCorrectly: true,
            currentDateSelection: 'inception_date',
            isSavingData: false,
            copyToClipboard: false,
        };
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    handleSave = () => {
        let { clientInformation, currentOcrTab, isSavingData } = this.state;
        if (isSavingData || this.disableSaveButton()) {
            return null;
        }
        this.setState({ isSavingData: true });

        let ajaxData = clientInformation[currentOcrTab];
        if (currentOcrTab === MODAL_VIEW_STATES['DATE']) {
            ajaxData = { [this.state.currentDateSelection]: moment(ajaxData['results']).format('YYYY-MM-DD') };
        }

        this.props.onPersistRowData(currentOcrTab, ajaxData);
        this.setState({ isSavingData: false });
        this.props.onClose(true);

        this.context.analytics.track(VMAC_OCR_SAVE_DATA, { currentOcrTab, ajaxData });
    };

    handleChangeTab = tab => {
        this.setState({ currentOcrTab: tab });
        this.context.analytics.track(VMAC_OCR_CHANGE_TAB, { tab });
    };

    handleToggleDateSelection = (field: string, value: any) => {
        this.setState({ [field]: value } as any);
    };

    handleChange = (fieldName: string, value: any) => {
        let { clientInformation, currentOcrTab } = this.state;
        this.setState({
            dataParsedCorrectly: false,
            clientInformation: u({ [currentOcrTab]: { [fieldName]: value } }, clientInformation),
        });
    };

    handleCopyToClipboard = () => {
        this.setState({ copyToClipboard: true }, () => {
            this.textAreaRef.current.select();
            document.execCommand('copy');
        });
        _.delay(() => {
            this.setState({ copyToClipboard: false });
        }, 1000);

        this.context.analytics.track(VMAC_OCR_COPY_TO_CLIPBOARD, {});
    };

    disableSaveButton() {
        const { currentOcrTab, clientInformation } = this.state;
        const uniques = new Set(Object.values(clientInformation[currentOcrTab]));
        return uniques && uniques.size === 1 && uniques.has(null);
    }

    render() {
        const { CONSTANTS } = this.props;
        let { clientInformation, currentOcrTab, isSavingData, copyToClipboard } = this.state;
        let obj = clientInformation[currentOcrTab];

        const showError = currentOcrTab !== MODAL_VIEW_STATES['DATE'] && currentOcrTab !== MODAL_VIEW_STATES['INSURED_FEIN'];

        return (
            <div className="standard-modal-content no-padding">
                <div className="padding-standard" id="ocr-text-display">
                    <textarea defaultValue={this.state.ocrTexted} ref={this.textAreaRef} disabled={!copyToClipboard}></textarea>
                    <button className="blue" onClick={this.handleCopyToClipboard}>
                        Copy
                    </button>
                </div>

                <div className="divider-line"></div>

                <div className="padding-standard padding-top-light">
                    <fieldset className="panel-inline modal-padding">
                        <h5 className="panel-label-header" id="user-instructions">
                            Notes
                        </h5>
                        <div className="notes-message warning">
                            <i className="material-icons">warning</i>
                            Correct scanned data and field mappings below before saving
                        </div>

                        <div className="divider-line dl-margin-default"></div>

                        <div className="padding-standard padding-bottom-none padding-top-none">
                            <If condition={currentOcrTab === MODAL_VIEW_STATES['INSURED_ALL'] || currentOcrTab === MODAL_VIEW_STATES['INSURED_NAME']}>
                                <TextInput
                                    maxLength={100}
                                    className=""
                                    name="arch_account_name"
                                    label="Client Name"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />
                            </If>
                            <If condition={currentOcrTab === MODAL_VIEW_STATES['INSURED_ALL'] || currentOcrTab === MODAL_VIEW_STATES['INSURED_ADDRESS']}>
                                <TextInput
                                    maxLength={100}
                                    className=""
                                    name="arch_account_street"
                                    label="Client Street"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />
                                <TextInput
                                    maxLength={100}
                                    className=""
                                    name="arch_account_city"
                                    label="Client City"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />

                                <ComboBoxInput
                                    object={obj}
                                    label="Client State"
                                    name="arch_account_state"
                                    onChange={this.handleChange}
                                    selectProps={{ choices: CONSTANTS.STATES }}
                                />
                                <MaskedTextInput
                                    format={{ mask: 'ZIPCODE' }}
                                    maxLength={100}
                                    className=""
                                    name="arch_account_zipcode"
                                    label="Client Zipcode"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />
                            </If>
                            <If condition={currentOcrTab === MODAL_VIEW_STATES['INSURED_FEIN']}>
                                <MaskedTextInput
                                    format={{ mask: 'FEIN' }}
                                    maxLength={100}
                                    className=""
                                    name="arch_account_fein"
                                    label="Client FEIN"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />
                            </If>
                            <If condition={currentOcrTab === MODAL_VIEW_STATES['DATE']}>
                                <ComboBoxInput
                                    object={this.state}
                                    label="Date Field"
                                    name="currentDateSelection"
                                    onChange={this.handleToggleDateSelection}
                                    selectProps={{ choices: DATE_SELECTIONS }}
                                />
                                <DateInput
                                    format={{ dateDisplayFormat: DISPLAY_DATE_FORMAT }}
                                    maxLength={100}
                                    className=""
                                    name="results"
                                    label="Parsed Date"
                                    onEveryChange={this.handleChange}
                                    object={obj}
                                />
                            </If>
                            <If condition={showError && clientInformation.error}>
                                <div style={{ color: 'red' }}>{clientInformation.error}</div>
                            </If>
                        </div>
                    </fieldset>
                </div>

                <div className="divider-line"></div>

                <div className="padding-standard padding-top-none">
                    <div className="button-row">
                        <button
                            onClick={this.handleChangeTab.bind(this, MODAL_VIEW_STATES['INSURED_ALL'])}
                            className={currentOcrTab === MODAL_VIEW_STATES['INSURED_ALL'] ? 'green' : 'grey'}
                        >
                            Insured Name &amp; Address
                        </button>
                        <div className="spacer wide" />
                        <button
                            onClick={this.handleChangeTab.bind(this, MODAL_VIEW_STATES['INSURED_ADDRESS'])}
                            className={currentOcrTab === MODAL_VIEW_STATES['INSURED_ADDRESS'] ? 'green' : 'grey'}
                        >
                            Insured Address
                        </button>
                        <div className="spacer wide" />
                        <button
                            onClick={this.handleChangeTab.bind(this, MODAL_VIEW_STATES['INSURED_NAME'])}
                            className={currentOcrTab === MODAL_VIEW_STATES['INSURED_NAME'] ? 'green' : 'grey'}
                        >
                            Insured Name
                        </button>
                        <div className="spacer wide" />
                        <button
                            onClick={this.handleChangeTab.bind(this, MODAL_VIEW_STATES['INSURED_FEIN'])}
                            className={currentOcrTab === MODAL_VIEW_STATES['INSURED_FEIN'] ? 'green' : 'grey'}
                        >
                            Insured FEIN
                        </button>
                        <div className="spacer wide" />
                        <button
                            onClick={this.handleChangeTab.bind(this, MODAL_VIEW_STATES['DATE'])}
                            className={currentOcrTab === MODAL_VIEW_STATES['DATE'] ? 'green' : 'grey'}
                        >
                            Date
                        </button>
                    </div>
                </div>

                <div className="divider-line"></div>

                <div className="padding-standard padding-top-none">
                    <div className="button-row">
                        <div className="spacer wide" />
                        <button className={!this.disableSaveButton() ? 'blue' : 'grey'} onClick={this.handleSave}>
                            {isSavingData ? 'Saving Results ...' : 'Save'}
                        </button>
                        <div className="spacer wide" />
                        <button className="red" onClick={this.handleCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
