import React from 'react';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import ReleasePendingApprovalApp from '../apps/ReleasePendingApprovalApp';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';

type headerPropTypes = {
    currentSubmission: SubmissionType;
};

class ReleasePendingApprovalHeader extends React.Component<headerPropTypes> {
    render() {
        const { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Release BOR - Insured: A${currentSubmission.arch_account_arch_id || ''} ${currentSubmission.insured_name || ''}`);
    }
}

const modalTypeObj = {
    Header: ReleasePendingApprovalHeader,
    Body: ReleasePendingApprovalApp,
    className: 'debug-info-modal wide',
};

registerType(ICUBED_MODAL_TYPES.RELEASE_PENDING_APPROVAL, modalTypeObj);
export default modalTypeObj;
