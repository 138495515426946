import * as React from 'react';
import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { CLEARANCE_STATUS } from '../../../../constants/Constants';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import BorSubmissionMatchingApp from '../apps/BorSubmissionMatchingApp';
import { resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { VoidFn, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { takeAction } from '../../../../actions/SubmissionActions';
import { searchArchSubmissions } from '../../../../actions/ArchActions';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    sendEmailNotification: boolean;
};

type propTypes = {
    onRestTable: VoidFn;
    onClickDuplicateInTable: (sid: number) => void;
    onSearchSubmissions: (query: string) => PromiseDispatchType;
    onTakeAction: (submissionId: number, relatedSubmissionIds: string[]) => PromiseDispatchType;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    return {
        tableData: state.borSubmissions,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    return {
        onRestTable: () => dispatch(resetTable(LITE_TABLES.BORSUBMISSIONS)),
        onSearchSubmissions: (accountId: string) => dispatch(searchArchSubmissions(accountId)),
        onTakeAction: (submissionId: number, relatedSubmissionIds: string[]) => {
            return dispatch(takeAction(submissionId, CLEARANCE_STATUS.PENDING_BOR, null, null, false, null, relatedSubmissionIds));
        },
        onOpenCenteredModal: (modalData: Record<string, any>, centerModalType: string) => {
            return dispatch(openCenteredModal(modalData, centerModalType));
        },
    };
};

class BorSubmissionMatching extends React.Component<propTypes> {
    constructor(props: propTypes) {
        super(props);
    }

    componentWillUnmount() {
        this.props.onRestTable();
    }

    render() {
        return <BorSubmissionMatchingApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BorSubmissionMatching);
export default connected;
