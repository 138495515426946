export const VMAC_BOTTOM_PANEL_EVENT = 'vmac-bottom-panel-save-event';

export const VMAC_CLIENT_MATCHING_SEARCH_EVENT = 'vmac-client-matching-search-event';
export const VMAC_CLIENT_MATCHING_SELECTION_EVENT = 'vmac-client-matching-selection-event';
export const VMAC_ACCOUNT_MATCHING_SELECTION_EVENT = 'vmac-account-matching-selection-event';
export const VMAC_CLIENT_MATCHING_SWITCHING_CLIENT = 'vmac-client-matching-switch-client';
export const VMAC_CLIENT_MATCHING_CLICK_EVENT = 'vmac-client-matching-click-event';
export const VMAC_ACCOUNT_SEARCH_CLICK_EVENT = 'vmac-account-search-click-event';
export const VMAC_PRODUCER_SEARCH_CLICK_EVENT = 'vmac-producer-search-click-event';

export const VMAC_BROKER_MATCHING_SEARCH_EVENT = 'vmac-broker-matching-search-event';
export const VMAC_BROKER_MATCHING_SWITCHING_CLIENT = 'vmac-broker-matching-switch-client';

export const VMAC_BROKER_OFFICE_MATCHING_SEARCH_EVENT = 'vmac-broker-office-matching-search-event';
export const VMAC_BROKER_OFFICE_MATCHING_SWITCHING_CLIENT = 'vmac-broker-office-matching-switch-client';

export const VMAC_OCR_COPY_TO_CLIPBOARD = 'vmac-ocr-copy-to-clipboard';
export const VMAC_OCR_CHANGE_TAB = 'vmac-ocr-change-tab';
export const VMAC_OCR_SAVE_DATA = 'vmac-ocr-save-data';

// TODO: these are already stored in icubed/core/constants.py, we should figure a way to pull them
//      from there to avoid duplication
export const VMAC_CLEAR_CLICK_EVENT = 'vmac-clear-click-event';
export const VMAC_BLOCK_CLICK_EVENT = 'vmac-block-click-event';
export const VMAC_REPLY_CLICK_EVENT = 'vmac-reply-click-event';
export const VMAC_DECLINE_CLICK_EVENT = 'vmac-decline-click-event';
export const VMAC_ARCHIVE_CLICK_EVENT = 'vmac-archive-click-event';
export const VMAC_MARK_PENDING_BOR_CLICK_EVENT = 'vmac-mark-pending-bor-click-event';
export const VMAC_RESOLVE_PENDING_BOR_CLICK_EVENT = 'vmac-resolve-pending-bor-click-event';
export const VMAC_RELEASE_PENDING_APPROVAL_CLICK_EVENT = 'vmac-release-pending-approval-click-event';
export const VMAC_FORWARD_CLICK_EVENT = 'vmac-forward-click-event';

export const VMAC_BROKER_ADD_CLICK_EVENT = 'vmac-broker-add-click-event';
export const VMAC_BROKER_CREATE_CLICK_EVENT = 'vmac-broker-create-click-event';

export const VMAC_NO_ACCOUNT_CONFLICTS_EVENT = 'vmac-no-account-conflicts';

export const VMAC_ROW_SELECTED = 'vmac-row-selected';
