import * as React from 'react';

import _ from 'lodash';

import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';

import { AppConstants } from '@archinsurance-viki/property-jslib';

import { SUBMISSION_EMAIL } from '../../constants/Constants';
import DocumentsNav from '../documents/DocumentsNav';
import DocumentsViewer from '@archinsurance-viki/property-jslib/src/components/documents/DocumentsViewer';
import DocumentUploadContainer from '@archinsurance-viki/property-jslib/src/components/documents/DocumentUploadContainer';
import DocumentsViewerFullScreen from '@archinsurance-viki/property-jslib/src/components/documents/DocumentsViewerFullScreen';

import { SubmissionType, PromiseDispatchType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';

import { resendEmail } from '../../actions/EmailActions';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import { connect } from 'react-redux';

type ownPropTypes = {
    currentSubmission: SubmissionType;
    onDownloadDocument: (document: DocumentDataType) => void;
    onDownloadAllSubmissionDocs: (submissionId: number) => void;
    onSaveDocumentData: (submissionId: number, documentId: number, data: Record<string, any>) => void;
    onOcrImage: (documentId: number, page: number, canvasDims: Record<string, any>) => void;
    onClassifySelectedText: (documentId: number, text: string) => void;
    CONSTANTS: Types.Constants;
    featureFlags: Types.FeatureFlags;
    onRunSOVParser: (documentId: number) => void;
    isContainerVisible: boolean;
};

type propTypes = {
    onRetryEmail: (emailId: number) => PromiseDispatchType;
} & ownPropTypes;

type stateTypes = {
    selectedDocIndex: number;
    isScreenCapturing: boolean;
    uploadMessage?: string;
    uploadErrors: string[];
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch: DispatchType, props: ownPropTypes) => {
    let SubmissionId = props.currentSubmission.id;
    return {
        onRetryEmail: emailId => {
            return dispatch(resendEmail(emailId, SubmissionId));
        },
    };
};

class PreviewPanel extends React.Component<propTypes, stateTypes> {
    docViewer: any;

    constructor(props: propTypes) {
        super(props);
        this.state = {
            selectedDocIndex: 0,
            isScreenCapturing: false,
            uploadMessage: null,
            uploadErrors: [],
        };
    }

    componentDidUpdate(prevProps: propTypes) {
        let { currentSubmission } = this.props;
        if (currentSubmission && prevProps.currentSubmission && currentSubmission.id !== prevProps.currentSubmission.id) {
            this.setState({
                selectedDocIndex: 0,
            });
        }
    }

    onSelectDocument = (document: Record<string, any>, selectedDocIndex: number) => {
        this.setState({ selectedDocIndex, isScreenCapturing: false });
    };

    toggleIsScreenCapturing = () => {
        this.setState({ isScreenCapturing: !this.state.isScreenCapturing });
    };

    onDownloadDocument = (id: number) => {
        let { currentSubmission } = this.props;
        if (currentSubmission) {
            let doc = _.find(currentSubmission.documents, function (d) {
                return d.id === id;
            });

            doc = {
                ...doc,
                secure_url: `${AppConstants.FILE_PROXY_BASE_URL}?document_id=${doc.id}`,
            };

            return this.props.onDownloadDocument(doc);
        }
    };

    onDownloadAllDocuments = () => {
        let { currentSubmission } = this.props;
        if (currentSubmission) {
            this.props.onDownloadAllSubmissionDocs(currentSubmission.id);
        }
    };
    onUploadStatusUpdate = (update: Record<string, any>) => {
        this.setState({
            uploadMessage: update.message,
            uploadErrors: update.errors,
        });

        if (update.done) {
            setTimeout(() => {
                this.setState({ uploadMessage: null });
            }, 2000);
        }
    };

    onDoubleClick = (docId: number) => {
        if (this.docViewer) {
            this.docViewer.openViewer(docId);
        }
    };
    onRunSOVParser = (docId: number) => {
        return this.props.onRunSOVParser(docId);
    };

    _renderPanelContent = () => {
        let { currentSubmission, CONSTANTS, onOcrImage, featureFlags, onClassifySelectedText } = this.props;
        let { selectedDocIndex } = this.state;

        if (!currentSubmission || !currentSubmission.id) {
            return <div className="center-text">Please select a submission</div>;
        }

        const { documents } = currentSubmission;

        if (!currentSubmission.id || !documents) {
            return <div className="center-text">Loading...</div>;
        }
        if (this.state.uploadMessage) {
            return <div className="center-text">{this.state.uploadMessage}</div>;
        }

        if (!documents.length) {
            return <div className="center-text">This submission has no documents</div>;
        }

        const filteredDocuments = _.filter(documents, d => {
            let ext = d.filename.substring(d.filename.lastIndexOf('.') + 1, d.filename.length) || d.filename;
            return !CONSTANTS.NON_PREVIEWABLE_TYPES?.includes(ext);
        });
        const selectedDocument = filteredDocuments[selectedDocIndex];
        if (!selectedDocument) {
            console.warn('Selected document index invalid');
            return null;
        }
        return [
            <DocumentsViewerFullScreen
                key="full-screen-viewer"
                ref={ref => {
                    this.docViewer = ref;
                }}
                onDownloadDocument={this.onDownloadDocument}
                documentsList={filteredDocuments}
            />,
            <DocumentsNav key="panel" documents={filteredDocuments} onSelectDocument={this.onSelectDocument} selectedDocumentIndex={selectedDocIndex} />,
            <DocumentsViewer
                renderAnnotations={false}
                onDoubleClick={this.onDoubleClick}
                onRetryEmail={this.props.onRetryEmail}
                key="viewer"
                inline={true}
                dontStealFocus={true}
                allowEditDocType={selectedDocument.type !== SUBMISSION_EMAIL}
                documents={filteredDocuments}
                selectDocument={this.onSelectDocument}
                selectedDocumentId={selectedDocument.id}
                selectedDocumentIndex={selectedDocIndex}
                onDownloadDocument={this.onDownloadDocument}
                onDownloadAllDocuments={this.onDownloadAllDocuments}
                onSaveDocumentData={this.props.onSaveDocumentData}
                onOcrImage={currentSubmission.status === 'U' ? onOcrImage : null}
                onClassifySelectedText={onClassifySelectedText}
                documentTypes={CONSTANTS.DOCUMENT_TYPE}
                featureFlags={featureFlags}
                toggleIsScreenCapturing={this.toggleIsScreenCapturing}
                isScreenCapturing={this.state.isScreenCapturing}
                onRunSOVParser={this.onRunSOVParser}
                submissionId={this.props.currentSubmission.id}
                showFullscreenButton
                onUploadStatusUpdate={this.onUploadStatusUpdate}
            />,
        ];
    };

    render() {
        return (
            <div className="right-half">
                <DocumentUploadContainer
                    className="submission-file-editor flex grow height-100"
                    uploadParams={{ submission_id: this.props.currentSubmission.id }}
                    uploadAlias="document"
                    onStatusUpdate={this.onUploadStatusUpdate}
                    isContainerVisible={this.props.isContainerVisible}
                >
                    {this._renderPanelContent()}
                    <div className="instructions upload">
                        <div className="txt">{this.state.uploadMessage || 'Drag and drop files to add here'}</div>
                        <span className="icon-file-text2" />
                        <span className="icon-folder-open" />
                        <span className="icon-file-picture" />
                        <span className="icon-smile2" />
                    </div>
                </DocumentUploadContainer>
            </div>
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(PreviewPanel);
export default connected;
