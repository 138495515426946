import classNames from 'classnames';
import { useAppDispatch } from '../../../../hooks/redux';

import { Types } from '../../../../ts-types/icubed-types';
import { getAccountNumberFromString } from '@archinsurance-viki/property-jslib/src/utils/arch-helpers';
import { openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { useCurrentSubmission } from '../../../../hooks/submission';
import { unlinkSubmissionAction } from '../../../../actions/SubmissionActions';
import { CLEARANCE_STATUS } from '../../../../constants/Constants';

export type LinkSubmissionAppProps = {
    ENV: Types.Env;
    isVisible: boolean;
    isVikiRenewal: boolean;
    id: number | string | null; // If isVikiRenewal, then ID is the VIKI SID, otherwise, it is the Arch ID
    setVisibility: (arg0: boolean) => void;
};

const SubmissionLinkOptionApp = (props: LinkSubmissionAppProps) => {
    const dispatch = useAppDispatch();
    const { ENV, isVisible, id, isVikiRenewal } = props;
    const currentSubmission = useCurrentSubmission();

    const handleClick = () => {
        const url = isVikiRenewal
            ? `${ENV.VIKI_BASE_URL}/submissions/${id}/current/overview`
            : `${ENV.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(id)}`;
        props.setVisibility(false);
        window.open(url, '_blank');
    };

    const onOpenCenteredModal = (modalData, modalType) => {
        dispatch(openCenteredModal(modalData, modalType));
    };

    const handleLinkSubmissionClick = () => {
        props.setVisibility(false);
        const modalProps = {
            archId: id,
        };
        onOpenCenteredModal(modalProps, ICUBED_MODAL_TYPES.LINK_SUBMISSION);
    };

    const handleUnlink = () => {
        dispatch(unlinkSubmissionAction(currentSubmission.id));
        props.setVisibility(false);
    };
    return (
        <div className={classNames('archlink-dialog', { hidden: !isVisible })} data-testid="submission-link-option-app">
            <div className="insured-matching-status grid-3-wide">
                <h1>Choose An Option:</h1>
                <div className="toggle-button-group button-group undefined">
                    <button
                        className="blue"
                        disabled={Boolean(currentSubmission.external_sid || id)}
                        onClick={handleLinkSubmissionClick}
                        data-testid="link-to-button"
                    >
                        {isVikiRenewal ? 'Link to VIKI submission' : 'Link to Archlink submission'}
                    </button>

                    {(currentSubmission.external_sid || id) && currentSubmission.status !== CLEARANCE_STATUS.CLEARED ? (
                        <>
                            <div className="spacer wide" />
                            <button className="blue" onClick={handleUnlink} data-testid="unlink-button">
                                {isVikiRenewal ? 'Unlink from VIKI submission' : 'Unlink from Archlink submission'}
                            </button>
                        </>
                    ) : null}
                    <div className="spacer wide" />
                    <button className="grey-dark" disabled={!(currentSubmission.external_sid || id)} onClick={handleClick} data-testid="go-to-button">
                        {isVikiRenewal ? 'GO TO VIKI' : 'GO TO ARCHLINK'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubmissionLinkOptionApp;
