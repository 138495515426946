import * as React from 'react';
import { useParams } from 'react-router-dom';
import GlobalStatus from '../../components/misc/GlobalStatus';
import { useGetArchStatusQuery } from '../../services/apiSlice';

const ArchlinkStatusPanel = () => {
    const { submissionId } = useParams<{ submissionId: string }>();
    const { data: apiStatuses, isFetching } = useGetArchStatusQuery({ submissionId }, { skip: !submissionId });
    const status = isFetching ? 'IN_PROGRESS' : apiStatuses?.api_status;

    return (
        <>
            <div className="text">
                Archlink Status
                <GlobalStatus status={status} />
            </div>
        </>
    );
};

export default ArchlinkStatusPanel;
