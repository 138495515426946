import { ViewSettingsConfig } from '@archinsurance-viki/property-jslib/src/utils/tables/view-settings';
import { COMBINED_TABLE_GLOSSARY } from './CombinedTableGlossary';

const accountsearchresults: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sela', 'aid', 'n'],
        columnList: ['as', 'st', 'c', 's', 'z', 'f'],
    },
};
const clientmatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'n'],
        columnList: ['ioi', 'st', 'c', 's', 'z', 'f'],
    },
};
const brokermatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'n'],
        columnList: ['e', 'c', 'o'],
    },
};
const brokerofficematches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'ai', 'n'],
        columnList: ['sta', 'c', 'str', 'zi'],
    },
};
const duplicatesubmissions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['a', 'src'],
        columnList: ['i', 'sd', 'cn', 'cstreet', 'ccity', 'cstate', 'czipcode', 'b', 'bco', 'cfein', 'inc', 'exp', 'dtr', 'dti', 'bldc', 'tiv', 'uwbd', 'uwsd'],
    },
};
const borsubmissionmatches: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'i',
        sortDirection: 'ASC',
        fixedColumnList: ['a'],
        columnList: ['i', 'rno', 'agnt', 'proc', 'sd', 'cn', 'inc', 'exp', 'dtr', 'dti'],
    },
};
const deltarecords: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'hd',
        sortDirection: 'DESC',
        fixedColumnList: ['hd', 'had', 'ovn'],
        columnList: ['cf', 'co', 'cn', 'cr', 'hun'],
    },
};
const conflictingsubmissions: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'an',
        sortDirection: 'DESC',
        fixedColumnList: ['an', 'n'],
        columnList: ['addr', 'addr1', 'sid', 'pas', 'c', 's', 'z', 'ct', 'effd', 'expd', 'div', 'sdiv'],
    },
};
const accountconflicts: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'an',
        sortDirection: 'DESC',
        fixedColumnList: ['sel', 'an', 'n'],
        columnList: ['expdt', 'addr', 'c', 's', 'z', 'ct', 'st'],
    },
};
const producersearchresults: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['sel', 'pid', 'n', 'std'],
        columnList: ['s', 'e', 'c', 'rt'],
    },
};

const conflicttable: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        fixedColumnList: ['si', 'ai'],
        columnList: [
            // 'src',
            'c',
            // 'rsi',
            'sd',
            'cn',
            'cstreet',
            'apt',
            'ccity',
            'cstate',
            'czipcode',
            'pas',
            'b',
            'bco',
            'cfein',
            'effd',
            'dproc',
            'dti',
            'tiv',
            'bldc',
        ],
    },
};

const imagerightlogs: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 150,
        sortable: false,
        readonly: true,
    },
    tableProps: {
        sortColumn: 'i',
        sortDirection: 'ASC',
        fixedColumnList: ['c', 'i'],
        columnList: ['sid', 'ad', 'sd', 'did'],
    },
};

const alternativenames: ViewSettingsConfig = {
    type: 'basic',
    columnDefaults: {
        w: 100,
        sortable: false,
        readonly: false,
    },
    tableProps: {
        columnList: Object.keys(COMBINED_TABLE_GLOSSARY.alternativenames),
    },
};

export const LITE_TABLE_VIEW_SETTINGS = {
    accountsearchresults,
    clientmatches,
    brokermatches,
    brokerofficematches,
    duplicatesubmissions,
    borsubmissionmatches,
    deltarecords,
    conflictingsubmissions,
    accountconflicts,
    conflicttable,
    producersearchresults,
    imagerightlogs,
    alternativenames,
};
