import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useGetDuplicatesQuery } from '../../../../services/apiSlice';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { EXTERNAL_SOURCES } from '../../../../constants/Constants';
import { conflictBadges } from '../../../../constants/CombinedTableGlossary';
import { loadLiteTableRows } from '@archinsurance-viki/property-jslib/src/actions/TableActions'; //resetTable
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

type propTypes = {
    onClickDuplicateInTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickSubmissionIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickAccountIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickRenewalSIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
};

const ConflictsTableApp = ({
    onClickDuplicateInTable,
    onClickSubmissionIDInConflictTable,
    onClickAccountIDInConflictTable,
    onClickRenewalSIDInConflictTable,
}: propTypes) => {
    const dispatch = useAppDispatch();
    const { submissionId: currentSubmissionId } = useParams<{ submissionId: string }>();
    const { data: submissionConflicts, isFetching } = useGetDuplicatesQuery({ submissionId: currentSubmissionId });

    const onGoToDupe = (sid: number, rowIndex: any, rowData: any) => {
        const { source } = rowData;
        let submissionId = sid;

        if (rowData.conflict === 'possibleDuplicate') {
            // VIKI-8224
            if (source === EXTERNAL_SOURCES.VIKI || source === EXTERNAL_SOURCES.ARCH) {
                submissionId = rowData.external_id;
            }
            _.delay(() => {
                onClickDuplicateInTable(submissionId, rowIndex, rowData);
            }, 1000);
        } else if (rowData.conflict === 'submissionConflict') {
            _.delay(() => {
                onClickSubmissionIDInConflictTable(submissionId, rowIndex, rowData);
            }, 1000);
        }
    };

    const onGoToAccountId = (sid: number, rowIndex: any, rowData: any) => {
        const submissionId = sid;
        _.delay(() => {
            onClickAccountIDInConflictTable(submissionId, rowIndex, rowData);
        }, 1000);
    };

    const onGoToRenewal = (sid: number, rowIndex: any, rowData: any) => {
        const submissionId = sid;
        _.delay(() => {
            onClickRenewalSIDInConflictTable(submissionId, rowIndex, rowData);
        }, 1000);
    };

    const renewal_by_number_ff = useAppSelector(state => state.global.featureFlags.renewals_in_duplicates_modal_ff);
    const renewal_by_name_ff = useAppSelector(state => state.global.featureFlags.renewals_by_name_in_duplicates_modal_ff);
    const tableData = useAppSelector(state => state.conflictTable);
    const badges = conflictBadges;

    useEffect(() => {
        if (isFetching) {
            dispatch(loadLiteTableRows(LITE_TABLES.CONFLICTTABLE, []));
        } else {
            dispatch(loadLiteTableRows(LITE_TABLES.CONFLICTTABLE, submissionConflicts));
        }
    }, [submissionConflicts, dispatch, isFetching]);

    return (
        <LiteTableContainer
            disableTable={isFetching}
            showProgressText={isFetching}
            progressText={'Getting conflicts...'}
            onRowSelected={null}
            hasActionPanel={false}
            tableConfig={LITE_TABLES.CONFLICTTABLE}
            containerClass="standard-modal-content wide"
            tableData={tableData}
            actionHelperFns={{
                onGoToDupe: onGoToDupe,
                onGoToAccountId: onGoToAccountId,
                onGoToRenewal: onGoToRenewal,
            }}
        >
            <div className="conflict-key">
                {badges.likelyBlocked.description}
                {badges.likelyBlocked.badge}
                {badges.internationalSubmission.description}
                {badges.internationalSubmission.badge}
                {badges.possibleDuplicate.description}
                {badges.possibleDuplicate.badge}
                {badges.submissionConflict.description}
                {badges.submissionConflict.badge}
                {renewal_by_name_ff || renewal_by_number_ff ? badges.renewal.description : null}
                {renewal_by_name_ff || renewal_by_number_ff ? badges.renewal.badge : null}
            </div>
        </LiteTableContainer>
    );
};

export default ConflictsTableApp;
