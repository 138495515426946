import React, { useRef, useState } from 'react';
import '../../styles/misc/bor_approval.scss';
import { BorState } from '../../ts-types/DataTypes';
import {
    useApproveBorMutation,
    useDeclineBorMutation,
    useGetEmailTemplateQuery,
    usePutEmailTemplateMutation,
    usePutResetEmailTemplateMutation,
    useUpdateBorMetadataMutation,
} from '../../services/apiSlice';

import { BOR_NOT_APPROVED, BOR_APPROVED_TO_SUBMITTER, BOR_APPROVED_TO_INCUMBENT } from '../../constants/Constants';
import { TextInput, TagInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { useAppDispatch } from '../../hooks/redux';
import { openMessageModal, openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { ICUBED_MODAL_TYPES } from '../../constants/Modals';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { dismissModalInAFewSeconds } from '../../actions/EmailActions';
import { validateEmail } from '../../utils/validators';

type BorDecisionEmailProps = {
    metadata_id: string;
    currentState: BorState;
    bor_decision_to_email: string;
    incumbent_decision_to_email: string;
    bor_submission: Record<string, any>;
    incumbent_submission: Record<string, any>;
    bor_decision_cc_emails: string[];
    incumbent_decision_cc_emails: string[];
};

const BorDecisionEmail = (props: BorDecisionEmailProps) => {
    const {
        metadata_id,
        currentState,
        incumbent_submission,
        bor_submission,
        bor_decision_to_email,
        incumbent_decision_to_email,
        bor_decision_cc_emails,
        incumbent_decision_cc_emails,
    } = props;
    const isApproval = currentState === BorState.APPROVAL_EMAILS;

    const dispatch = useAppDispatch();

    const incumbentSubjectRef = useRef(null);
    const borSubjectRef = useRef(null);

    const incumbentBodyRef = useRef(null);
    const borBodyRef = useRef(null);

    const [updateMetadataServer] = useUpdateBorMetadataMutation();
    const [approveBorMutation] = useApproveBorMutation();
    const [declineBorMutation] = useDeclineBorMutation();
    const [putEmailTemplateMutation] = usePutEmailTemplateMutation();
    const [putResetEmailTemplateMutation] = usePutResetEmailTemplateMutation();

    const incumbentEmailAction = BOR_APPROVED_TO_INCUMBENT;
    const { data: incumbentTemplateData, isLoading: incumbentTemplateIsLoading } = useGetEmailTemplateQuery({
        submission_id: incumbent_submission.id,
        action: incumbentEmailAction,
    });

    const borEmailAction = isApproval ? BOR_APPROVED_TO_SUBMITTER : BOR_NOT_APPROVED;
    const { data: borTemplateData, isLoading: borTemplateIsLoading } = useGetEmailTemplateQuery({
        submission_id: incumbent_submission.id,
        action: borEmailAction,
    });

    const [metadata, updateMetadataState] = useState({ bor_decision_to_email, incumbent_decision_to_email });
    const [borCcEmails, updateCcEmailsState] = useState(bor_decision_cc_emails);
    const [incumbentCcEmails, updateIncumbentCcEmailsState] = useState(incumbent_decision_cc_emails);
    const openEmailConfirmModal = (modalData: Record<string, unknown>, modalType: string) => {
        dispatch(openCenteredModal(modalData, modalType));
    };
    const [sendEmail, updateSendEmailState] = useState(true);

    const clickAction = async (sendEmail = true) => {
        const args = { metadata_id, data: { send_email: sendEmail } };

        if (sendEmail) {
            const draftSaved = await saveDraft(false);
            if (!draftSaved) {
                openModal('Email failed to save. Please try again.', true);
                return;
            }
        }

        const emailAction = () => (isApproval ? approveBorMutation(args) : declineBorMutation(args));
        const modalData = {
            confirmAction: emailAction,
            disableModalResizable: true,
            isApproval: isApproval,
        };
        openEmailConfirmModal(modalData, ICUBED_MODAL_TYPES.BOR_DECISION_EMAIL_CONFIRM);
    };

    const persistMetadataUpdate = (field, value) => {
        updateMetadataState({ ...metadata, [field]: value });
        const borOrIncumbentToEmail = field === 'bor_decision_to_email' ? 'incumbent_decision_to_email' : 'bor_decision_to_email';
        if (!validateEmail(value) || !validateEmail(metadata[borOrIncumbentToEmail])) {
            updateSendEmailState(false);
        } else {
            updateSendEmailState(true);
        }
        updateMetadataServer({ metadata_id, data: { [field]: value } });
    };

    const openModal = (description, confirm) => {
        dispatch(
            openMessageModal(
                {
                    title: 'Result',
                    description,
                    modalData: confirm
                        ? {
                              confirmLabelTitle: 'Ok',
                              onOk: () => {
                                  return;
                              },
                          }
                        : null,
                    disableModalResizable: true,
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
    const handleAddAdditionalEmail = (email: string) => {
        const newList = borCcEmails.concat(email);

        updateCcEmailsState(newList);
        updateMetadataServer({ metadata_id, data: { bor_decision_cc_emails: newList } });
    };

    const handleRemoveAdditionalEmail = (emailToDelete: string) => {
        const newList = borCcEmails.filter(e => e !== emailToDelete);
        updateCcEmailsState(newList);
        updateMetadataServer({ metadata_id, data: { bor_decision_cc_emails: newList } });
    };

    const handleIncumbentEmail = (email: string) => {
        const newList = incumbentCcEmails.concat(email);

        updateIncumbentCcEmailsState(newList);
        updateMetadataServer({ metadata_id, data: { incumbent_decision_cc_emails: newList } });
    };

    const handleRemoveIncumbentEmail = (emailToDelete: string) => {
        const newList = incumbentCcEmails.filter(e => e !== emailToDelete);
        updateIncumbentCcEmailsState(newList);
        updateMetadataServer({ metadata_id, data: { incumbent_decision_cc_emails: newList } });
    };
    const saveSingleDraft = (subjectRef, bodyRef, action) => {
        if (bodyRef?.current?.value && subjectRef?.current?.value) {
            return putEmailTemplateMutation({
                subject: subjectRef.current.value,
                body: bodyRef.current.value,
                submission_id: incumbent_submission.id,
                action: action,
            });
        }
    };

    const saveDraft = async (displayModal = true): Promise<boolean> => {
        const emptyBorCheck = (borSubjectRef.current && !borSubjectRef.current.value) || (borBodyRef.current && !borBodyRef.current.value);
        const emptyIncumbentCheck =
            (incumbentSubjectRef.current && !incumbentSubjectRef.current.value) || (incumbentBodyRef.current && !incumbentBodyRef.current.value);

        if (emptyBorCheck || emptyIncumbentCheck) {
            openModal('Email subject and body are required fields.', true);
            return false;
        }

        const borEmailMutation = await saveSingleDraft(borSubjectRef, borBodyRef, borEmailAction);
        const incumbentEmailMutation = await saveSingleDraft(incumbentSubjectRef, incumbentBodyRef, incumbentEmailAction);

        if ((borEmailMutation && 'error' in borEmailMutation) || (incumbentEmailMutation && 'error' in incumbentEmailMutation)) {
            if (displayModal) {
                openModal('Email failed to save. Please try again.', true);
            }
            return false;
        } else {
            if (displayModal) {
                openModal('Email saved successfully.', false);
                dismissModalInAFewSeconds(dispatch);
            }
        }

        return true;
    };

    const resetEmailDrafts = action => {
        return putResetEmailTemplateMutation({
            submission_id: incumbent_submission.id,
            action: action,
        });
    };

    const cancelBorChanges = async () => {
        const borResetEmailMutation = await resetEmailDrafts(borEmailAction);
        const incumbentResetEmailMutation = await resetEmailDrafts(incumbentEmailAction);

        if ((borResetEmailMutation && 'error' in borResetEmailMutation) || (incumbentResetEmailMutation && 'error' in incumbentResetEmailMutation)) {
            openModal('Email failed to reset email templates.', true);
        }

        await updateMetadataServer({
            metadata_id: metadata_id,
            data: {
                progress_state: BorState.DECISION,
            },
        });
    };

    return (
        <div className="flex column padding-right">
            <div className="flex fd-r jc_c">
                {isApproval && (
                    <div className="info-block row-editable margin-right w-33">
                        <div className="header">
                            <div className="header-text">Incumbent Email</div>
                        </div>
                        {!incumbentTemplateIsLoading && (
                            <div className="info-rows bor">
                                <div className="input-label flex">
                                    <div className="txt-overflow">From</div>
                                </div>
                                <div className="input-div flex viki-combo-box">
                                    <div className="combo-box">
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={incumbent_submission.inbox_reply_email}
                                            className="combobox-input-element input-element"
                                        />
                                    </div>
                                </div>

                                <hr />

                                <div className="input-label flex">
                                    <div className="txt-overflow">To</div>
                                </div>

                                <TextInput maxLength={50} name="incumbent_decision_to_email" object={metadata} onChange={persistMetadataUpdate} />
                                <hr />
                                <div className="w-100">
                                    <div className="input-label flex">
                                        <div className="txt-overflow">CC</div>
                                    </div>
                                </div>
                                <div className="w-100 [&_.tag-box-container]:tw-max-h-20 [&_.tag-box-container]:tw-overflow-auto">
                                    <TagInput
                                        tags={incumbentCcEmails}
                                        onRemoveTag={handleRemoveIncumbentEmail}
                                        onAddTag={handleIncumbentEmail}
                                        onValidateTag={validateEmail}
                                        fuzzySearch={'inbox'}
                                        placeholder={''}
                                    />
                                </div>
                                <hr />

                                <div className="input-label flex">
                                    <div className="txt-overflow">Subject</div>
                                </div>
                                <div className="input-div flex">
                                    <input
                                        type="text"
                                        className="input-element"
                                        defaultValue={incumbentTemplateData.subject || incumbent_submission.approval_email_subject}
                                        ref={incumbentSubjectRef}
                                    />
                                </div>

                                <hr />

                                <div className="input-label flex">
                                    <div className="txt-overflow">Body</div>
                                </div>
                                <div className="input-label flex"></div>
                                <div className="input-label flex"></div>
                                <div className="input-div flex">
                                    <textarea className="ta-lrg" ref={incumbentBodyRef}>
                                        {incumbentTemplateData.body}
                                    </textarea>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className="info-block row-editable margin-right-none w-33">
                    <div className="header">
                        <div className="header-text">BOR Broker Email</div>
                    </div>
                    {!borTemplateIsLoading && (
                        <div className="info-rows bor">
                            <div className="input-label flex">
                                <div className="txt-overflow">From</div>
                            </div>
                            <div className="input-div flex viki-combo-box">
                                <div className="combo-box">
                                    <input type="text" className="combobox-input-element input-element" value={bor_submission.inbox_reply_email} />
                                </div>
                            </div>

                            <hr />

                            <div className="input-label flex">
                                <div className="txt-overflow">To</div>
                            </div>
                            <TextInput maxLength={50} name="bor_decision_to_email" object={metadata} onChange={persistMetadataUpdate} />
                            <hr />
                            <div className="w-100">
                                <div className="input-label flex">
                                    <div className="txt-overflow">CC</div>
                                </div>
                            </div>
                            <div className="w-100 [&_.tag-box-container]:tw-max-h-20 [&_.tag-box-container]:tw-overflow-auto">
                                <TagInput
                                    tags={borCcEmails}
                                    onRemoveTag={handleRemoveAdditionalEmail}
                                    onAddTag={handleAddAdditionalEmail}
                                    onValidateTag={validateEmail}
                                    fuzzySearch={'inbox'}
                                    placeholder={''}
                                />
                            </div>
                            <hr />

                            <div className="input-label flex">
                                <div className="txt-overflow">Subject</div>
                            </div>
                            <div className="input-div flex">
                                <input
                                    type="text"
                                    className="input-element"
                                    defaultValue={borTemplateData.subject || bor_submission.approval_email_subject}
                                    ref={borSubjectRef}
                                />
                            </div>

                            <hr />

                            <div className="input-label flex">
                                <div className="txt-overflow">Body</div>
                            </div>
                            <div className="input-label flex"></div>
                            <div className="input-label flex"></div>
                            <div className="input-div flex">
                                <textarea className="ta-lrg" ref={borBodyRef}>
                                    {borTemplateData.body}
                                </textarea>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="button-row">
                <button className="green" disabled={!sendEmail} onClick={() => clickAction(true)}>
                    {isApproval ? 'Approve' : 'Decline'} + Send Email
                </button>
                <div className="spacer"></div>
                <button className="green" onClick={() => clickAction(false)}>
                    {isApproval ? 'Approve' : 'Decline'} Without Email
                </button>
                <div className="spacer"></div>
                <button className="blue" onClick={() => saveDraft()}>
                    Save Draft
                </button>
                <div className="spacer"></div>
                <button className="grey-dark" onClick={() => cancelBorChanges()}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default BorDecisionEmail;
