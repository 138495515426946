import React from 'react';

type propTypes = {
    status: 'ERROR' | 'IN_PROGRESS' | 'SUCCESS' | 'NOT_CALLED';
};

const PROGRESS_INDICATOR_INLINE = <div className="progress-indicator inline number" />;
const SUCCESS_INDICATOR_INLINE = <div className="status-handle-icons material-icons green">check_circle</div>;
const ERROR_INDICATOR_INLINE = <div className="status-handle-icons material-icons red">report</div>;
const WARN_INDICATOR_INLINE = <div className="status-handle-icons material-icons orange">warning</div>;

const GlobalStatus = (props: propTypes) => {
    let statusHtml;
    if (!props.status) return null;

    switch (props.status) {
        case 'ERROR':
            statusHtml = ERROR_INDICATOR_INLINE;
            break;
        case 'IN_PROGRESS':
            statusHtml = PROGRESS_INDICATOR_INLINE;
            break;
        case 'SUCCESS':
            statusHtml = SUCCESS_INDICATOR_INLINE;
            break;
        case 'NOT_CALLED':
            statusHtml = WARN_INDICATOR_INLINE;
            break;
        default:
            console.error(`status=${props.status} is not supported.`);
            break;
    }

    return <>{statusHtml}</>;
};

export default GlobalStatus;
