import * as React from 'react';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useCurrentSubmission } from '../../hooks/submission';
import PanelView from '../layout/PanelView';
import { getTaskSubtitles } from '../../utils/task-helpers';

import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import VIKITasksContainer from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasksContainer';

import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    children: React.ReactNode;
    leftPanel: React.ReactNode;
    topPanelHeader?: string;
};

const DefaultLayout = (props: propTypes) => {
    const dispatch = useAppDispatch();
    const uiState = useAppSelector(state => state.uiState);
    const ENV = useAppSelector(state => state.global.ENV) as Types.Env;
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const centeredModalData = useAppSelector(state => state.global.centeredModalData);
    const centeredModalType = useAppSelector(state => state.global.centeredModalType);
    const openPanels = useAppSelector(state => state.uiState.openPanels);

    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const currentSubmission = useCurrentSubmission();
    const onCloseCenteredModal = () => {
        dispatch(closeCenteredModal());
    };
    return (
        <>
            <CenteredModal
                CONSTANTS={CONSTANTS}
                uiState={uiState}
                ENV={ENV}
                modalData={centeredModalData}
                modalType={centeredModalType}
                onClose={onCloseCenteredModal}
            />
            <VIKITasksContainer getSubtitles={getTaskSubtitles} />
            <PanelView
                currentSubmission={currentSubmission}
                openPanels={openPanels}
                ENV={ENV}
                uiState={uiState}
                featureFlags={featureFlags}
                isFullScreenViewMode={false}
                leftPanel={props.leftPanel}
                topPanelHeader={props.topPanelHeader}
            >
                {props.children}
            </PanelView>
        </>
    );
};

export default DefaultLayout;
