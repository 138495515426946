import * as React from 'react';

import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LiteTableDataType, TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { SubmissionType, VoidFn } from '../../../../ts-types/DataTypes';

type propTypes = {
    onClose: (close: boolean) => void;
    tableGlossary: TableGlossaryType;
    currentSubmission: SubmissionType;
    CONSTANTS: Types.Constants;
    tableData: LiteTableDataType;
    resetTable: VoidFn;
    ENV: Types.Env;
};

export default class SubmissionConflictsApp extends React.Component<propTypes> {
    componentWillUnmount() {
        this.props.resetTable();
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    render() {
        let { currentSubmission, tableData, ENV } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <React.Fragment>
                <LiteTableContainer
                    extraCellRenderData={{ ARCH_BASE_URL: ENV.ARCH_BASE_URL }}
                    onRowSelected={null}
                    hasActionPanel={false}
                    tableConfig={LITE_TABLES.SUBMISSIONCONFLICTS}
                    containerClass="standard-modal-content wide tall"
                    tableData={tableData}
                    selectedRowId={0}
                />
            </React.Fragment>
        );
    }
}
