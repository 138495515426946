export const getSubmissionBuildingLocations = (sid: number, rowData: Record<string, any>) => {
    const buildings = rowData[sid].buildings || [];
    return buildings.map(data => {
        if (!data) {
            return {};
        }

        let { id, geo, street_address, city, state, zip_code, description } = data;
        return {
            lat: geo && geo.coordinates && geo.coordinates[0],
            lng: geo && geo.coordinates && geo.coordinates[1],
            locationUnknown: false,
            id,
            address: `${street_address} ${city}, ${state} ${zip_code}`,
            description,
        };
    });
};
