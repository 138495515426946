import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InboxStateType } from '../ts-types/DataTypes';
import lockr from 'lockr';

const INBOX_ID_KEY = 'INBOX_ID';

let initialState: InboxStateType = {
    list: [],
    inboxId: lockr.get(INBOX_ID_KEY) || null,
};

const inboxSlice = createSlice({
    name: 'inbox',
    initialState: initialState as InboxStateType,
    reducers: {
        receiveInboxes(state, action: PayloadAction<any>) {
            let inboxes = action.payload;
            let { inboxId } = state;

            if (!inboxId) {
                state.inboxId = inboxes[0].id;
            }
            state.list = inboxes;
        },
        setCurrentInbox(state, action: PayloadAction<any>) {
            let inboxId = action.payload;
            lockr.set(INBOX_ID_KEY, inboxId);
            state.inboxId = inboxId;
        },
    },
});

export const { receiveInboxes, setCurrentInbox } = inboxSlice.actions;

export default inboxSlice.reducer;
