import React from 'react';
import { UnderwritingPlatformType } from '../../ts-types/DataTypes';

type BorDecisionProps = {
    arch_account_name: string;
    effective_date: string;
    incumbent_submission: Record<string, any>;
    bor_submission: Record<string, any>;
    handleOnClickApproveOrDecline: (isApproved: boolean) => void;
};
const BorDecision = (props: BorDecisionProps) => {
    const { arch_account_name, effective_date, incumbent_submission, bor_submission, handleOnClickApproveOrDecline } = props;

    const getSubmissionText = (submission: typeof incumbent_submission) => {
        if (!submission) return;
        return submission.underwriting_platform === UnderwritingPlatformType.VIKI ? 'VIKI SID' : 'AL SID';
    };
    const getSubmissionId = (submission: typeof incumbent_submission) => {
        if (!submission) return;
        return submission.underwriting_platform === UnderwritingPlatformType.VIKI ? submission.viki_sid : submission.al_sid;
    };

    return (
        <div className="w-100 flex column overflow-y">
            <div className="flex fd-r jc_c">
                <div className="info-block row-editable extra-padding">
                    <div className="header">
                        <div className="header-text">Decision</div>
                    </div>
                    <div className="info-rows">
                        <div className="input-label flex mrg-bottom">
                            <div className="txt-overflow">Name Insured</div>
                        </div>
                        <div className="input-static">
                            <div className="txt-overflow">{arch_account_name}</div>
                        </div>

                        <div className="input-label flex">
                            <div className="txt-overflow">Effective Date</div>
                        </div>
                        <input type="text" value={effective_date} readOnly />

                        <div className="input-label flex">
                            <div>
                                Incumbent Submission
                                <span className="info-label dsply-blk">Broker:</span>
                                <span className="info-label dsply-blk">{getSubmissionText(incumbent_submission)}</span>
                                <span className="info-label dsply-blk">Underwriter:</span>
                                <span className="info-label dsply-blk">Sub Division:</span>
                            </div>
                        </div>
                        <div className="input-static">
                            <br />
                            <input type="text" value={incumbent_submission && incumbent_submission.broker} readOnly />
                            <br />
                            <input type="text" value={getSubmissionId(incumbent_submission)} readOnly />
                            <br />
                            <input type="text" value={incumbent_submission && incumbent_submission.underwriter} readOnly />
                            <br />
                            <input type="text" value={incumbent_submission && incumbent_submission.sub_division} readOnly />
                        </div>

                        <div className="input-label flex">
                            <div>
                                BOR Submission
                                <span className="info-label dsply-blk">Broker:</span>
                                <span className="info-label dsply-blk">{getSubmissionText(bor_submission)}</span>
                                <span className="info-label dsply-blk">Underwriter:</span>
                                <span className="info-label dsply-blk">Sub Division:</span>
                            </div>
                        </div>
                        <div className="input-static">
                            <br />
                            <input type="text" value={bor_submission && bor_submission.broker} readOnly />
                            <br />
                            <input type="text" value={getSubmissionId(bor_submission)} readOnly />
                            <br />
                            <input type="text" value={bor_submission && bor_submission.underwriter} readOnly />
                            <br />
                            <input type="text" value={bor_submission && bor_submission.sub_division} readOnly />
                        </div>

                        <hr />

                        <div className="input-label flex">
                            <div className="txt-overflow">Approve BOR Request?</div>
                        </div>
                        <div className="input-div flex tertiary-input">
                            <div className="data-entry-btn-wrapper">
                                <button className="button data-entry green" onClick={() => handleOnClickApproveOrDecline(true)}>
                                    Approve
                                </button>
                                <button className="button data-entry" onClick={() => handleOnClickApproveOrDecline(false)}>
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default BorDecision;
