import { get } from 'lodash';
import { memo, useContext, useEffect } from 'react';

import { useCurrentSubmission } from '../../hooks/submission';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import { TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { persistRowData_server, updateRowData } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { processGridRequestWithId } from '@archinsurance-viki/property-jslib/src/utils/api/RequestUtils';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';

import { SubmissionType, UnderwritingPlatformType } from '../../ts-types/DataTypes';
import BottomPanelApp from './BottomPanelApp';
import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import { ALTERNATIVE_NAMES_MODAL_WIDTH, BROKER_MATCHING_MODAL_WIDTH, ICUBED_MODAL_TYPES } from '../../constants/Modals';
import { DEFAULT_ZOOM_OUT, INBOX_IDS, SUBDIVISION_CODES } from '../../constants/Constants';
import {
    getBuildingDistribution,
    getStateFromZipcode,
    getSubmissionDataFromServer,
    runBotResults,
    updateAutoSelectingAccountMessage,
} from '../../actions/SubmissionActions';
import { getCurrentInbox } from '../../utils/inbox-helpers';
import { openCenteredModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { dismissModalInAFewSeconds } from '../../actions/EmailActions';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import {
    VMAC_ACCOUNT_SEARCH_CLICK_EVENT,
    VMAC_BROKER_ADD_CLICK_EVENT,
    VMAC_CLIENT_MATCHING_CLICK_EVENT,
    VMAC_PRODUCER_SEARCH_CLICK_EVENT,
} from '../../constants/AnalyticsConstants';

import { getSubmissionBuildingLocations } from '../../utils/submission-helpers';
import { isHotfix, isProduction } from '@archinsurance-viki/property-jslib/src/utils/environment-helpers';
import { createOrUpdateArchAccountForSubmission, deselectArchAccountForSubmission } from '../../actions/ArchActions';
import { getTaskPath } from '@archinsurance-viki/property-jslib/src/utils/pusher-helpers';
import { showConfirmPopupThen } from '@archinsurance-viki/property-jslib/src/utils/ui-helpers';
import { TaskItem } from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasksPanel';
import { useForm } from 'react-hook-form';
import { useChangeEffect } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { createSelector } from '@reduxjs/toolkit';
import { referenceDataApiEnpoints } from '@archinsurance-viki/property-jslib/src/services/referenceDataApi';

const FETCHED_SUBMISSIONS = {};

const BottomPanelContainer = ({ tableGlossary }: { tableGlossary: TableGlossaryType }) => {
    const context = useContext(AppContext);
    const dispatch = useAppDispatch();
    const currentSubmission = useCurrentSubmission();

    const currentInbox = useAppSelector(state => getCurrentInbox(state));
    const tableData = useAppSelector(state => state.submissionsLog);
    const me = useAppSelector(state => state.global.me);
    const tableConfig = PAGED_TABLES.SUBMISSIONS;
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const ENV: any = useAppSelector(state => state.global.ENV);
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const taskProgressInfo = useAppSelector(state => {
        if (!currentSubmission?.id) {
            return {};
        }
        return get(state.uiState, getTaskPath('PUBLISH_TO_GATEWAY', currentSubmission.id));
    }) as TaskItem;

    const currentNotes = useAppSelector(state => state.notes.notesById[currentSubmission.id]);
    const disableAllEdits = currentSubmission.is_archived || (currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== me.id);

    const brokerFormDefaultValues: Record<string, string | number> = {
        panp: currentSubmission.account_agent_name_as_parsed,
        pai: currentSubmission.account_agent_id,
        lai: currentSubmission.licensed_broker_id,
        pppc: currentSubmission.office_parent_company,
        rte: currentSubmission.reply_to_email,
        pid: currentSubmission.office_id,
        rui: currentSubmission.request_underwriter_id,
        arui: currentSubmission.arch_requested_underwriter_id,
        oui: currentSubmission.originating_underwriter_id,
        aoui: currentSubmission.arch_originating_underwriter_id,
    };

    const formMethods = useForm({ defaultValues: brokerFormDefaultValues });

    const { resetField, setError } = formMethods;

    const createGetUnderwritersSelector = createSelector(
        (id: number) => id,
        id => referenceDataApiEnpoints.getUnderwriters.select(id)
    );

    let inboxDrivenSubdiv = null;
    let pasDrivenSubdiv = null;
    switch (currentSubmission.inbox_id) {
        case INBOX_IDS.VENTUS:
            if (!featureFlags.enable_ouw_global_list_ff) {
                inboxDrivenSubdiv = parseInt(SUBDIVISION_CODES.VENTUS_HO); // removing for 17993 as do not need filter for orig underwriter list
            }
            pasDrivenSubdiv =
                currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI
                    ? parseInt(SUBDIVISION_CODES.VENTUS_HO)
                    : parseInt(SUBDIVISION_CODES.ES_PROPERTY);
            break;
        case INBOX_IDS.ARCH_E_AND_S:
            if (!featureFlags.enable_ouw_global_list_ff) {
                inboxDrivenSubdiv = parseInt(SUBDIVISION_CODES.ES_PROPERTY);
            }
            pasDrivenSubdiv =
                currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI
                    ? parseInt(SUBDIVISION_CODES.VENTUS_HO)
                    : parseInt(SUBDIVISION_CODES.ES_PROPERTY);
            break;
        case INBOX_IDS.SMMS:
            if (!featureFlags.enable_ouw_global_list_ff) {
                inboxDrivenSubdiv = pasDrivenSubdiv = parseInt(SUBDIVISION_CODES.SMMS);
            }
            break;
        default:
            if (!featureFlags.enable_ouw_global_list_ff) {
                inboxDrivenSubdiv = pasDrivenSubdiv = parseInt(SUBDIVISION_CODES.RETAIL_PROPERTY);
            }
    }

    console.log(`PAS_DRIVEN: ${pasDrivenSubdiv}, INBOX_DRIVEN: ${inboxDrivenSubdiv}`);
    const { data: originatingUnderwriters, isLoading: isOriginatingUWLoading } = useAppSelector(state =>
        createGetUnderwritersSelector(inboxDrivenSubdiv)(state)
    );
    const { data: assignedUnderwriters, isLoading: isAssignedUWLoading } = useAppSelector(state => createGetUnderwritersSelector(pasDrivenSubdiv)(state));
    dispatch(referenceDataApiEnpoints.getUnderwriters.initiate(inboxDrivenSubdiv));
    dispatch(referenceDataApiEnpoints.getUnderwriters.initiate(pasDrivenSubdiv));

    const getUnderwriterDetail = (id: number) => {
        return dispatch(referenceDataApiEnpoints.getUnderwriterDetail.initiate(id));
    };

    useEffect(() => {
        if ([CONSTANTS.INBOX_IDS.SMMS, CONSTANTS.INBOX_IDS.RETAIL].includes(currentSubmission.inbox_id)) {
            if (!currentSubmission.arch_requested_underwriter_id) {
                setError('arui', {
                    type: 'required',
                });
            }
            if (!currentSubmission.arch_originating_underwriter_id) {
                setError('aoui', {
                    type: 'required',
                });
            }
            if (!currentSubmission.reply_to_email) {
                setError('rte', {
                    type: 'required',
                });
            }
        } else {
            if (!currentSubmission.account_agent_id) {
                setError('pai', {
                    type: 'required',
                });
            }
            if (!currentSubmission.office_id) {
                setError('pid', {
                    type: 'required',
                });
            }
            if (!currentSubmission.arch_requested_underwriter_id) {
                setError('arui', {
                    type: 'required',
                });
            }
            if (!currentSubmission.arch_originating_underwriter_id) {
                setError('aoui', {
                    type: 'required',
                });
            }
            if (!currentSubmission.reply_to_email) {
                setError('rte', {
                    type: 'required',
                });
            }
        }
    }, [CONSTANTS, currentSubmission, setError]);

    useChangeEffect(brokerFormDefaultValues, (nextDefaults, previousDefaults) => {
        Object.keys(nextDefaults).forEach(key => {
            if (!previousDefaults || previousDefaults[key] !== nextDefaults[key]) {
                if (key === 'rte' && !nextDefaults[key]) {
                    resetField('rte', { defaultValue: '' });
                    return;
                }
                resetField(key, { defaultValue: nextDefaults[key] });
            }
        });
    });

    usePusherEvent(`SUBMISSIONS-${currentSubmission.id}`, 'auto-selecting-arch-account', _message => {
        updateAutoSelectingAccountMessage(dispatch);
        dismissModalInAFewSeconds(dispatch);
    });
    const onUnlinkArchAccount = (discardCurrentAccountData: boolean) => {
        dispatch(deselectArchAccountForSubmission(currentSubmission.id, discardCurrentAccountData));
    };

    const onCreateOrUpdateArchAccount = () => {
        dispatch(createOrUpdateArchAccountForSubmission(currentSubmission.id));
    };

    const logDisplayConditions = (id: string, currentSubmission: SubmissionType, me: Record<string, any>) => {
        if (isProduction() || isHotfix()) {
            context.analytics.track(`${id}-pressed`, {
                userId: me.id,
                assignedToId: currentSubmission.assigned_to_id,
                isSubmissionEditable: currentSubmission.is_editable,
                isSubmissionArchived: currentSubmission.is_archived,
                shouldModalOpen: !(
                    currentSubmission.is_archived ||
                    !currentSubmission.is_editable ||
                    (!!currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== me.id)
                ),
            });
        }
    };

    const onOpenCenteredModal = (modalData, modalType) => {
        dispatch(openCenteredModal(modalData, modalType));
    };

    const onOpenMessageModal = (modalData, modalType) => {
        dispatch(openMessageModal(modalData, modalType));
    };

    const isCurrentSubmissionEditable = () => {
        return (
            !currentSubmission.is_archived &&
            !me.is_underwriter_assistant &&
            currentSubmission.is_editable &&
            (!currentSubmission.assigned_to_id || currentSubmission.assigned_to_id === me.id)
        );
    };
    const handleAccountSearch = () => {
        if (!isCurrentSubmissionEditable()) {
            return;
        }
        context.analytics.track(VMAC_ACCOUNT_SEARCH_CLICK_EVENT, {
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
        const modalProps = {
            tableData,
            currentSubmission,
        };
        onOpenCenteredModal(modalProps, ICUBED_MODAL_TYPES.ACCOUNT_SEARCH);
    };

    const handleProducerSearch = () => {
        context.analytics.track(VMAC_PRODUCER_SEARCH_CLICK_EVENT, {
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
        const modalProps = {
            tableData,
            currentSubmission,
        };
        onOpenCenteredModal(modalProps, ICUBED_MODAL_TYPES.PRODUCER_SEARCH);
    };

    const onLoadSubmissionBuildingData = (submissionId: number) => {
        return dispatch(getSubmissionDataFromServer(submissionId, ['buildings.*'])).then(() => {
            dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { id: submissionId, locationsAreLoading: false }));
        });
    };

    const onGetBuildingDistribution = (submissionId: number) => {
        return dispatch(getBuildingDistribution(submissionId));
    };

    const onGetBuildingData = (submissionId: number) => {
        if (submissionId && !FETCHED_SUBMISSIONS[submissionId]) {
            FETCHED_SUBMISSIONS[submissionId] = true;
            dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { id: submissionId, locationsAreLoading: true }));
            onGetBuildingDistribution(submissionId);
            onLoadSubmissionBuildingData(submissionId);
        }
    };

    const onRunBotResults = (submissionID: number) => {
        dispatch(runBotResults(submissionID));
    };

    const handleUnlinkAccount = () => {
        showConfirmPopupThen({
            onConfirmed: () => {
                onUnlinkArchAccount(true);
            },
            onClose: () => {
                onUnlinkArchAccount(false);
            },
            title: 'Start from scratch with a blank account?',
            confirmButtonTitle: 'From Scratch',
            cancelButtonCaption: 'Keep Current Data',
        })(null, event.target);
    };

    const handleCreateAccount = () => {
        showConfirmPopupThen({
            onConfirmed: () => {
                onCreateOrUpdateArchAccount();
            },
            onClose: () => {
                return;
            },
            title: 'Create a new account?',
        })(null, event.target);
    };

    const handleConflictResolution = () => {
        logDisplayConditions('lookup-insured', currentSubmission, me);
        if (!isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            tableData,
            currentSubmission,
        };
        context.analytics.track(VMAC_CLIENT_MATCHING_CLICK_EVENT, {
            submission_id: currentSubmission.id,
            inbox_id: currentInbox.id,
        });
        onOpenCenteredModal(props, ICUBED_MODAL_TYPES.CLIENT_MATCHING);
    };

    const handleBrokerMatching = () => {
        logDisplayConditions('lookup-broker', currentSubmission, me);
        if (!isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            tableData: tableData,
            width: BROKER_MATCHING_MODAL_WIDTH,
            currentSubmission: currentSubmission,
        };

        const isRetailSubmission = currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.RETAIL;
        const modalType = isRetailSubmission ? ICUBED_MODAL_TYPES.BROKER_OFFICE_MATCHING : ICUBED_MODAL_TYPES.BROKER_MATCHING;
        onOpenCenteredModal(props, modalType);
    };

    const handleOpenAlternativeNames = () => {
        if (!isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            tableData,
            width: ALTERNATIVE_NAMES_MODAL_WIDTH,
            currentSubmission,
        };

        return dispatch(openCenteredModal(props, ICUBED_MODAL_TYPES.ALTERNATIVE_NAMES));
    };

    const onSaveFn = (field, value) => {
        if (field === 'assigned_to_id' && value === null && currentSubmission.assigned_to_id) {
            dispatch(
                openCenteredModal(
                    {
                        title: 'Unassign Submission',
                        description: 'Assigned Submission can never be unassigned.',
                        modalData: {
                            confirmLabelTitle: 'Ok',
                            onOk: () => {
                                return;
                            },
                        },
                    },
                    CENTERED_MODAL_TYPES.CONFIRM
                )
            );
        } else {
            return dispatch(
                persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(currentSubmission.id, { [field]: value }, tableData.rowData))
            ).then(_data => {
                if (field === 'arch_account_zipcode') {
                    dispatch(getStateFromZipcode(currentSubmission.id, value));
                }
                dispatch(getSubmissionDataFromServer(currentSubmission.id, ['arch_account.*']));
            });
        }
    };

    const handleAddAccountBroker = () => {
        if (!isCurrentSubmissionEditable()) {
            return;
        }
        const props = {
            currentSubmissionId: currentSubmission.id,
            width: BROKER_MATCHING_MODAL_WIDTH,
        };
        context.analytics.track(VMAC_BROKER_ADD_CLICK_EVENT, { submission_id: currentSubmission.id, inbox_id: currentInbox.id });
        onOpenCenteredModal(props, ICUBED_MODAL_TYPES.ADD_ACCOUNT_BROKER);
    };
    const handleViewBuildingDistributions = () => {
        if (me.is_underwriter_assistant) {
            return;
        }
        onGetBuildingData(currentSubmission.id);
        let props = {
            currentSubmission: currentSubmission,
            tableData: tableData,
        };
        onOpenCenteredModal(props, ICUBED_MODAL_TYPES.BUILDING_DISTRUBTIONS);
    };
    const handleAddOrEditNote = note => {
        const isNewNote = note.id === undefined;
        const isDraftNote = note.is_draft === true;

        const props = {
            modalData: {
                saveParams: {
                    submission_id: currentSubmission.id,
                    note_type: 'UN',
                },
                idKey: currentSubmission.id,
                includeSubject: true,
                note: note,
                readOnly: !isNewNote && !isDraftNote,
                draftModeEnaled: isNewNote || isDraftNote,
            },
        };
        onOpenCenteredModal(props, ICUBED_MODAL_TYPES.ADD_NOTE);
    };
    const handleRunBot = _note => {
        onRunBotResults(currentSubmission?.id);
    };
    const handleScheduleView = () => {
        if (me.is_underwriter_assistant) {
            return;
        }
        const { rowData } = tableData;
        onGetBuildingData(currentSubmission.id);
        const locations = getSubmissionBuildingLocations(currentSubmission.id, rowData);
        const locationEditorProps = {
            locations,
            zoom: DEFAULT_ZOOM_OUT,
            editable: false,
            showNavArrows: false,
            itemTypeName: 'Building',
            showClose: true,
        };
        onOpenCenteredModal(locationEditorProps, ICUBED_MODAL_TYPES.MAP_SCHEDULE_VIEW);
    };

    const handleViewOfacResults = () => {
        let ofac_data = 'There is no OFAC data to display.';
        if (currentSubmission.arch_account && currentSubmission.arch_account.cached_ofac_api_response) {
            ofac_data = JSON.stringify(currentSubmission.arch_account.cached_ofac_api_response, null, 2);
        }
        let ofacResultsProps = {
            title: ofac_data,
        };
        onOpenMessageModal(ofacResultsProps, CENTERED_MODAL_TYPES.CONFIRM);
    };

    const bottomPanelProps = {
        currentInbox,
        tableData,
        disableAllEdits,
        tableConfig,
        featureFlags,
        ENV,
        currentNotes,
        currentSubmission,
        CONSTANTS,
        tableGlossary,
        me,
        taskProgressInfo,
        onSaveFn,
        formMethods,
        assignedUnderwriters,
        originatingUnderwriters,
        isAssignedUWLoading,
        isOriginatingUWLoading,
        getUnderwriterDetail,
    };

    return (
        <BottomPanelApp
            {...bottomPanelProps}
            onHandleAccountSearch={handleAccountSearch}
            onHandleUnlinkAccount={handleUnlinkAccount}
            onHandleCreateAccount={handleCreateAccount}
            onHandleConflictResolution={handleConflictResolution}
            onHandleBrokerMatching={handleBrokerMatching}
            onHandleAddAccountBroker={handleAddAccountBroker}
            onHandleAddOrEditNote={handleAddOrEditNote}
            onHandleViewOfacResults={handleViewOfacResults}
            onHandleViewBuildingDistributions={handleViewBuildingDistributions}
            onHandleOpenAlternativeNames={handleOpenAlternativeNames}
            onHandleRunBot={handleRunBot}
            onPopScheduleView={handleScheduleView}
            onHandleProducerSearch={handleProducerSearch}
            onCreateOrUpdateArchAccount={onCreateOrUpdateArchAccount}
            onUnlinkArchAccount={onUnlinkArchAccount}
        />
    );
};

export default memo(BottomPanelContainer);
