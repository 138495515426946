import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import BrokerMatchingApp from '../apps/BrokerMatchingApp';
import { getMatchedBrokers_server } from '../../../../actions/ClientActions';

import { PAGED_TABLES } from '../../../../constants/PagedTableConfigs';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { MAXIMUM_BROKER_MATCHES } from '../../../../constants/Constants';

import { persistRowData_server, resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { processGridRequestWithId } from '@archinsurance-viki/property-jslib/src/utils/api/RequestUtils';
import { Types } from '../../../../ts-types/icubed-types';
import { VoidFn } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

type propTypes = {
    onPersistRowData: (field: string, value: any) => void;
    uiState: Record<string, any>;
    onGetMatchedBrokers: (searchString: string) => void;
    currentProducingAgentName: string | null;
    resetTable: VoidFn;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { currentSubmission } = ownProps;
    const { account_agent_first_name, account_agent_last_name } = currentSubmission;
    const account_agent_name = account_agent_first_name && account_agent_last_name ? `${account_agent_first_name} ${account_agent_last_name}` : null;
    return {
        uiState: state.uiState,
        tableData: state.brokerMatches,
        currentProducingAgentName: account_agent_name,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    // note, this tableData is parentTableData, not the tableData powering the litetable used in the modal.
    let { currentSubmission, tableData } = ownProps;
    return {
        onGetMatchedBrokers: (searchString: string) => {
            return dispatch(getMatchedBrokers_server(searchString, currentSubmission.inbox_sub_division_id, MAXIMUM_BROKER_MATCHES));
        },
        onPersistRowData: (field: string, value: any) => {
            return dispatch(
                persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(currentSubmission.id, { [field]: value }, tableData.rowData))
            );
        },
        resetTable: () => {
            dispatch(resetTable(LITE_TABLES.BROKERMATCHES));
        },
    };
};

class BrokerMatching extends React.Component<propTypes> {
    render() {
        return <BrokerMatchingApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BrokerMatching);
export default connected;
