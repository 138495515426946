import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { InboxType, VoidFn } from '../../ts-types/DataTypes';
import SelectComponent from '@archinsurance-viki/property-jslib/src/components/select/SelectComponent';
import { PANELS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { Types } from '../../ts-types/icubed-types';
import NavPanel from './NavPanel';

type propTypes = {
    inboxes: InboxType[];
    currentInbox: InboxType;
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    onTogglePanel: (panel: string) => void;
    onLogOut: VoidFn;
    openPanels: Record<string, any>;
    featureFlags: Types.FeatureFlags;
};

const LeftPanel = (props: propTypes) => {
    const userSettings = useSelector((state: Types.RootState) => state.global.userSettings);
    const togglePanel = () => {
        props.onTogglePanel(PANELS.SETTINGS);
    };

    const { inboxes, currentInbox, openPanels } = props;
    const inboxChoices: [any, string][] = props.inboxes.map(inbox => [inbox.id, inbox.short_name]);
    const selectedIndex = inboxes.indexOf(currentInbox);

    const inboxPicker = <SelectComponent className="override-select" onSelected={props.onSelectInbox} choices={inboxChoices} selectedIndex={selectedIndex} />;

    const settingsIsOpen = openPanels[PANELS.SETTINGS];

    return (
        <div className={classNames('main-left-panel main-panel', { closed: !userSettings.left_panel_opened })}>
            <div className="main-left-content">
                <NavPanel featureFlags={props.featureFlags} />

                {/* <GlobalStatusPanel isLoading={isLoading} status={globalStatusData?.status} description={globalStatusData?.description} /> */}

                <div className="left-bottom-content">
                    <div className="info-label dsply-blk">INBOX:</div>

                    <div className="viki-select flex centered wrap ellipsis">
                        <span className="info-data">{inboxPicker}</span>
                    </div>

                    <button
                        onClick={togglePanel}
                        className={classNames('blue', {
                            active: settingsIsOpen,
                        })}
                    >
                        Table Menu
                    </button>
                    <button
                        onClick={() => {
                            props.onLogOut();
                        }}
                        className="blue"
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LeftPanel;
