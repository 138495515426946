import { useEffect, useState } from 'react';
import { DISPLAY_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ComboBoxInput, DateInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import PanelArea from './PanelArea';
import { CURRENT_DATE, ONE_MONTH_BEHIND_DATE } from './utils';
import MultiAxisChart from '@archinsurance-viki/property-jslib/src/components/widgets/MultiAxisChart';
import { Types } from '../../ts-types/icubed-types';
import { useActionReportQuery } from '../../services/apiSlice';

type propTypes = {
    featureFlags: Types.FeatureFlags;
};

const AnalyticsApp = (props: propTypes) => {
    const [timeRange, setTimeRange] = useState({ unit: 'DAILY', startDate: ONE_MONTH_BEHIND_DATE, endDate: CURRENT_DATE });
    const [reportData, setReportData] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const { data, isLoading } = useActionReportQuery({ start: timeRange.startDate, end: timeRange.endDate, units: timeRange.unit, action: 'B' });

    useEffect(() => {
        if (!isLoading) {
            setReportData(data['data']);
            setSubmissions(data['submissions']);
        } else {
            setReportData([]);
            setSubmissions([]);
        }
    }, [isLoading, data]);
    const format = { dateDisplayFormat: DISPLAY_DATE_FORMAT };

    const handleOnChange = (k, v) => {
        if (v) {
            setTimeRange(prevState => ({ ...prevState, [k]: v }));
        }
    };
    return (
        <>
            <div className="tw-overflow-auto flex flex-row tw-w-full">
                <PanelArea>
                    <div>
                        <h4>Unit</h4>
                        <ComboBoxInput
                            name="unit"
                            label={null}
                            object={timeRange}
                            onChange={handleOnChange}
                            selectProps={{
                                choices: [
                                    { value: 'DAILY', display: 'Daily' },
                                    { value: 'WEEKLY', display: 'Weekly' },
                                    { value: 'MONTHLY', display: 'Monthly' },
                                ],
                                noSort: true,
                            }}
                        />
                        <br />
                        <h4>Start Date</h4>
                        <DateInput format={format} key="start_date" name="startDate" label={null} onChange={handleOnChange} object={timeRange} />
                        <br />
                        <h4>End Date</h4>
                        <DateInput format={format} key="end_date" name="endDate" label={null} onChange={handleOnChange} object={timeRange} />
                        <br />
                        <h4>Submissions</h4>
                        <div className="id-table-wrapper tw-h-full">
                            <table className="submission-id-table">
                                <thead></thead>
                                <tbody>
                                    {submissions.map(item => (
                                        <tr key={item.submission_id}>
                                            <td>
                                                <a href={`/dashboard/${item.submission_id}`} target="_blank">
                                                    {item.submission_id}
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </PanelArea>
                {isLoading ? (
                    'Loading...'
                ) : (
                    <div className="tw-flex-1 tw-w-[1200px]">
                        <MultiAxisChart data={reportData} stackType={'100%'} title="Recommend Block Final Action Rate" />
                        <MultiAxisChart data={reportData} title="Recommended Block Final Action Count" />
                    </div>
                )}
            </div>
        </>
    );
};

export default AnalyticsApp;
