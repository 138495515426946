import React, { useState } from 'react';
import { useBorApprovalQuery } from '../../services/apiSlice';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';

import { CheckInput } from '@archinsurance-viki/property-jslib/src/components/inputs';

const approvalSteps = [
    'Scheduling BOR Task',
    'Starting BOR Task',
    'Validating',
    'Recording BOR in VMAC',
    'Writing to ArchLink',
    'Writing to VIKI/RAE',
    'Sending emails',
    'Success!',
];
const declinationSteps = ['Scheduling declination email notification', 'Sending email', 'Sent!'];

const BorApproval: React.FC = () => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const number = url.pathname.split('/')[2];
    const [currentStep, setCurrentStep] = useState(0);
    const [didSucceed, setDidSucceed] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [successfulEmailCount, setSuccessfulEmailCount] = useState(0);
    const { data, isLoading } = useBorApprovalQuery({ id: number, action: params.get('action') });

    const {
        status = null,
        submission_ids = null,
        status_display = null,
        last_mark_pending_bor_task_status = null,
        approval_email_to_submitter_status = null,
        approval_email_to_incumbent_status = null,
        declination_email_to_submitter_status = null,
    } = isLoading ? {} : data;

    const isApproval = status === 'A';
    const isDeclination = status === 'D';
    const desiredEmailCount = isApproval ? 2 : 1;

    const taskSteps = isApproval ? approvalSteps : declinationSteps;

    const didApprovalTaskSucceed = last_mark_pending_bor_task_status === 'SUCCESS';
    const didFail = !didApprovalTaskSucceed && failureMessage.length > 0;

    if (isApproval) {
        let computedStep = currentStep;
        if (approval_email_to_submitter_status === 'S' && approval_email_to_incumbent_status === 'S') computedStep = taskSteps.length;
        else if (didApprovalTaskSucceed) computedStep = 6;

        if (currentStep < computedStep) {
            setCurrentStep(computedStep);
            if (computedStep === taskSteps.length && !didSucceed) setDidSucceed(true);
        }
    } else if (isDeclination && declination_email_to_submitter_status === 'S' && currentStep < 3) {
        setCurrentStep(3);
        setDidSucceed(true);
    }

    if (!isLoading && !isApproval && !isDeclination && !didSucceed) {
        setDidSucceed(true);
    }

    usePusherEvent(`icubed-global-channel`, 'task', (message: Record<string, any>) => {
        console.log(message);

        const { progress_description, status, fail_message } = message;

        const taskSid = message['submission_id'];
        const isEmailTask = message['task_type'] === 'SEND_EMAIL';
        const isBorTask = message['task_type'] === 'TAKE_BOR_ACTION';

        // ignore irrelevant task events
        if (
            isLoading ||
            (!isLoading && !isApproval && !isDeclination) ||
            (isBorTask && !progress_description) ||
            !(isBorTask || isEmailTask) ||
            submission_ids.indexOf(`${taskSid}`) < 0
        ) {
            return;
        }

        console.log('registered');

        let newStep = currentStep;

        if (isBorTask) {
            if (status === 'FAIL') {
                if (fail_message.includes('does not have authority to approve this BOR.')) {
                    setFailureMessage(`${fail_message} Please reach out to the development team`);
                } else {
                    setFailureMessage(`${fail_message} Please try again later`);
                }
            } else if (progress_description.indexOf('Starting') >= 0) {
                newStep = 2;
            } else if (progress_description.indexOf('Valid') >= 0) {
                newStep = 3;
            } else if (progress_description.indexOf('VMAC') >= 0) {
                newStep = 4;
            } else if (progress_description.indexOf('ArchLink') >= 0) {
                newStep = 5;
            } else if (progress_description.indexOf('VIKI/RAE') >= 0) {
                newStep = 6;
            }
        } else {
            if (status === 'SUCCESS') {
                if (successfulEmailCount === desiredEmailCount - 1) {
                    setDidSucceed(true);
                    setCurrentStep(taskSteps.length);
                }
                setSuccessfulEmailCount(successfulEmailCount + 1);
            } else if (status === 'FAIL' && failureMessage.length === 0) {
                setFailureMessage(`Failed to send email for submission_id ${taskSid}. Please try again later.`);
            }
        }

        if (newStep > currentStep) {
            console.log(`Setting next step ${newStep}`);
            setCurrentStep(newStep);
        }
    });

    if (isLoading) {
        return <h2>Loading... please wait</h2>;
    }

    const renderFinalStatusMessage = () => {
        let message = null;

        if (didFail) {
            message = failureMessage;
        } else if (didSucceed) {
            message = status_display;
        }

        return message ? (
            <div style={{ lineHeight: '20px' }}>
                <p style={{ color: didSucceed ? 'green' : 'red' }}>{message}</p>
            </div>
        ) : null;
    };

    let minContainerHeight = '115px';

    if (isApproval) {
        minContainerHeight = '380px';
    } else if (isDeclination) {
        minContainerHeight = '300px';
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div>
                <div
                    style={{
                        width: '600px',
                        padding: '30px',
                        borderWidth: '1px',
                        background: 'rgb(250, 250, 250)',
                        borderColor: 'rgb(229, 231, 235)',
                        minHeight: minContainerHeight,
                    }}
                >
                    {(isApproval || isDeclination) && (
                        <div>
                            <div style={{ fontSize: '1.5rem', marginBottom: '30px' }}>BOR Decision</div>
                            <div style={{ marginBottom: '30px' }}>
                                {taskSteps.map((step, idx) => {
                                    let currentLabel = step;

                                    if (currentStep - 1 === idx && didFail) {
                                        currentLabel = `${step}. Failed!`;
                                    } else if (currentStep === idx && !didFail) {
                                        currentLabel = `${step}...`;
                                    }

                                    return (
                                        <CheckInput
                                            key={step}
                                            name={step}
                                            object={{}}
                                            disabled={false}
                                            labelAfter={true}
                                            label={currentLabel}
                                            onChange={() => null}
                                            getValue={() => idx < currentStep}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {renderFinalStatusMessage()}
                </div>
            </div>
        </div>
    );
};

export default BorApproval;
