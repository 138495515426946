import classNames from 'classnames';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { InboxType, VoidFn } from '../../ts-types/DataTypes';
import { PANELS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { Types } from '../../ts-types/icubed-types';

type propTypes = {
    documents: DocumentDataType[];
    selectedDocumentIndex: number;
    selectedDocumentId: number | null;
    onDownloadDocument: (document: any) => void;
    onDownloadAllDocuments?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    selectDocument: (documentId, selectedDocIndex) => void;
    inboxes: InboxType[];
    onSelectInbox: (inboxId: number, archEnabled: boolean) => void;
    onLogOut: VoidFn;
    openPanels: Record<string, any>;
    featureFlags: Record<string, any>;
};

const PreviewLeftPanel = (props: propTypes) => {
    const userSettings = useSelector((state: Types.RootState) => state.global.userSettings);

    const onDownloadDocument = () => {
        const slide = props.documents[props.selectedDocumentIndex];
        props.onDownloadDocument(slide.id);
    };
    const selectDocument = (index: number) => {
        const { documents } = props;
        if (index < 0 || index >= documents.length) {
            throw new Error('Selected document out of bounds');
        }
        props.selectDocument(documents[index].id, index);
    };

    const renderDocsList = (slides: Record<string, any>[]) => {
        return _.map(slides, (slide: any, i: number) => {
            return (
                <li
                    key={i}
                    className={classNames(
                        {
                            'tw-border-blue': i === props.selectedDocumentIndex,
                        },
                        'tw-m-[10px] tw-text-sm tw-p-[10px] tw-border tw-border-grey-border'
                    )}
                    onClick={(_e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                        selectDocument(i);
                    }}
                >
                    {slide.title}
                </li>
            );
        });
    };
    const onClose = () => {
        window.close();
    };

    const { openPanels } = props;

    const settingsIsOpen = openPanels[PANELS.SETTINGS];

    return (
        <div className={classNames('main-left-panel main-panel', { closed: !userSettings.left_panel_opened })}>
            <div className="main-left-content">
                <div className="left-top-content">
                    {!props.featureFlags.hide_navigation && <ul className="documents-list-panel">{renderDocsList(props.documents)}</ul>}
                </div>
                <div className="left-bottom-content">
                    <div className="info-label dsply-blk">FILE ACTIONS:</div>

                    <button
                        onClick={onDownloadDocument}
                        className={classNames('blue', {
                            active: settingsIsOpen,
                        })}
                    >
                        Download
                    </button>
                    <button onClick={props.onDownloadAllDocuments} className="blue">
                        Download All
                    </button>
                    <button
                        onClick={onClose}
                        className={classNames('red', {
                            active: settingsIsOpen,
                        })}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PreviewLeftPanel;
