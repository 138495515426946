import * as React from 'react';

import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import ResolvePendingBORApp from '../apps/ResolvePendingBorApp';

type headerPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
};

class ResolvePendingBorHeader extends React.Component<headerPropTypes> {
    render() {
        let { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Release Pending BOR - Account ID: A${currentSubmission.arch_account_arch_id}`);
    }
}

const modalTypeObj = {
    Body: ResolvePendingBORApp,
    Header: ResolvePendingBorHeader,
    className: 'debug-info-modal wide',
};

registerType(ICUBED_MODAL_TYPES.RESOLVE_PENDING_BOR, modalTypeObj);
export default modalTypeObj;
