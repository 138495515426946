import * as React from 'react';

import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';
import ProducerSearchApp from '../apps/ProducerSearchApp';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    CONSTANTS: Types.Constants;
};

const ProducerSearchBody = (props: bodyPropTypes) => <ProducerSearchApp {...props} isContact={false} />;

const modalTypeObj = {
    Body: ProducerSearchBody,
    Header: DefaultHeaderWithProps('Arch Producer Search'),
    className: 'client-matching-modal fullscreen',
};

registerType(ICUBED_MODAL_TYPES.PRODUCER_SEARCH, modalTypeObj);
export default modalTypeObj;
