import * as React from 'react';

import ClientMatching from '../containers/ClientMatching.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    archTableData: LiteTableDataType;
};

class ClientMatchingBody extends React.Component<bodyPropTypes> {
    render() {
        return <ClientMatching {...this.props} />;
    }
}

const modalTypeObj = {
    Body: ClientMatchingBody,
    Header: DefaultHeaderWithProps('Account Conflict Resolution'),
    className: 'client-matching-modal fullscreen',
};

registerType(ICUBED_MODAL_TYPES.CLIENT_MATCHING, modalTypeObj);
export default modalTypeObj;
