import * as React from 'react';
import u from 'updeep';

import { TextAreaInput, TagInput, TextInput, CheckInput, ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { validateEmail } from '../../../../utils/validators';
import {
    REPLY_ACTION,
    DECLINED_BOR_ACTION,
    CLEARED_BOR_ACTION,
    BOR_RELEASED_PRIOR_TO_APPROVAL_CLEAR_BOR,
    BOR_RELEASED_PRIOR_TO_APPROVAL_DECLINE_INCUMBENT,
} from '../../../../constants/Constants';
import { VoidFn, PromiseDispatchType, InboxType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';

type propTypes = {
    hideButtons: boolean;
    hideReplyToEmail?: boolean;
    sendButtonText: string;
    onClose: (close: boolean) => void;
    disableCCEmails?: boolean;
    uiState: {
        RESPONSE: Record<string, any>;
        showSecondaryEmailTemplate?: boolean;
    };
    modalData: {
        currentSubmissionId: number | string;
        action: string;
        replyToEmail: string;
        additionalReplyToEmails?: string[];
        additionalCCEmails?: string[];
        currentInbox?: InboxType;
        vikiSid?: number;
        archSid?: string;
        submissionWasActioned?: boolean;
    };

    onClearFormData: VoidFn;
    onGetTemplate: VoidFn;
    onGetReplyTemplates?: VoidFn;
    onPutTemplate: (body: string, subject?: string, baseTemplateSubject?: string) => void;
    onResetTemplate?: (subject?: string) => void;
    onSendResponse: (params) => PromiseDispatchType;
    onTakeAction: (action: string, emailParams: any, ccEmails?: string[], replyToEmails?: string[]) => PromiseDispatchType;
    onSearchInbox: (searchTerm: string) => void;
    handleAdditionalEmailsChange?: (emails: string[], submissionId: number) => void;
    handleToggleSecondaryEmailTemplate?: VoidFn;
    isDisabled?: boolean;
    templateKey?: string;
    featureFlags?: Types.FeatureFlags;
};

type stateTypes = {
    isEditing: boolean;
    additionalEmails: string[];
    replyToEmail: string;
    replyToEmails: string[];
};

export default class AddResponseApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    static defaultProps = {
        hideButtons: false,
        sendButtonText: 'Send Email',
        isDisabled: false,
        hideReplyToEmail: false,
        disableCCEmails: false,
        templateKey: 'RESPONSE',
    };

    constructor(props: propTypes) {
        super(props);
        let replyToEmails = [];
        if (props.modalData?.additionalReplyToEmails?.length > 0) {
            replyToEmails = [props.modalData.replyToEmail, ...props.modalData.additionalReplyToEmails];
        }
        this.state = {
            isEditing: false,
            replyToEmails: replyToEmails,
            replyToEmail: props.modalData.replyToEmail,
            additionalEmails: props.modalData.additionalCCEmails || [],
        };
        this.props.onGetTemplate();
        this.props.onGetReplyTemplates?.();
    }

    componentDidMount() {
        const { templateKey, modalData } = this.props;
        const { vikiSid, archSid, submissionWasActioned } = modalData;
        const defaultSid = vikiSid || archSid || null;
        if (submissionWasActioned) {
            this.context.setUiState({ [templateKey]: u({ arch_clearance_api_related_sid: defaultSid }, this.props.uiState.RESPONSE) });
        }
    }

    componentDidUpdate(_prevProps: Readonly<propTypes>, _prevState: Readonly<stateTypes>, _snapshot?: any): void {
        if (
            ![CLEARED_BOR_ACTION, DECLINED_BOR_ACTION, BOR_RELEASED_PRIOR_TO_APPROVAL_CLEAR_BOR, BOR_RELEASED_PRIOR_TO_APPROVAL_DECLINE_INCUMBENT].includes(
                this.props.modalData?.action
            )
        )
            return;

        if (this.props.modalData?.replyToEmail !== this.state.replyToEmail) {
            this.setState({ replyToEmail: this.props.modalData.replyToEmail });
            this.props.onGetTemplate();
        }
        if (this.props.modalData?.additionalReplyToEmails !== this.state.replyToEmails) {
            this.setState({ replyToEmails: this.props.modalData.additionalReplyToEmails });
        }
        if (this.props.modalData?.additionalCCEmails && this.props.modalData?.additionalCCEmails !== this.state.additionalEmails) {
            this.setState({ additionalEmails: this.props.modalData.additionalCCEmails });
        }
    }

    handleChange = (fieldName: string, value: any) => {
        const { templateKey, uiState } = this.props;
        const { RESPONSE } = uiState;
        this.context.setUiState({
            [templateKey]: u({ [fieldName]: value }, RESPONSE),
        });
    };

    handleOnChange = (fieldName: string, value: any) => {
        const { templateKey, uiState } = this.props;
        const { RESPONSE } = uiState;
        const body = RESPONSE.templates.find(template => template.value === value).body;
        this.context.setUiState({
            [templateKey]: u({ ['body']: body, ['subject']: value, ['base_template_subject']: value }, RESPONSE),
        });
    };

    handleSendReply = () => {
        const { action } = this.props.modalData;
        const { RESPONSE } = this.props.uiState;
        const { additionalEmails, replyToEmails } = this.state;

        this.props.onTakeAction(action, RESPONSE, additionalEmails, replyToEmails).then(
            _response => {
                this.props.onClearFormData();
                this.props.onClose(true);
            },
            _xhr => {
                this.props.onClose(true);
            }
        );
    };

    handleCancel = () => {
        this.props.onClearFormData();
        this.props.onClose(true);
    };

    componentWillUnmount() {
        const { RESPONSE } = this.props.uiState;
        this.props.onPutTemplate(RESPONSE.body, RESPONSE.subject, RESPONSE.base_template_subject);
    }

    handleAddEmail = (email: string) => {
        const { replyToEmails } = this.state;
        const newReplyToEmails = [email, ...replyToEmails];
        this.setState({ replyToEmails: newReplyToEmails });
    };

    handleRemoveEmail = (email: string) => {
        const { replyToEmails } = this.state;
        const newReplyToEmails = replyToEmails.filter(e => e !== email);
        this.setState({ replyToEmails: newReplyToEmails });
    };

    handleAddAdditionalEmail = (email: string) => {
        const { additionalEmails } = this.state;
        const { modalData, handleAdditionalEmailsChange } = this.props;
        const newAdditionalEmails = [email, ...additionalEmails];
        this.setState({ additionalEmails: newAdditionalEmails });
        handleAdditionalEmailsChange && handleAdditionalEmailsChange(newAdditionalEmails, +modalData.currentSubmissionId);
    };

    handleRemoveAdditionalEmail = (email: string) => {
        const { additionalEmails } = this.state;
        const { modalData, handleAdditionalEmailsChange } = this.props;
        const newAdditionalEmails = additionalEmails.filter(e => e !== email);
        this.setState({ additionalEmails: newAdditionalEmails });
        handleAdditionalEmailsChange && handleAdditionalEmailsChange(newAdditionalEmails, +modalData.currentSubmissionId);
    };

    renderSidTextInput = (vikiSid, archSid) => {
        let { RESPONSE } = this.props.uiState;
        const defaultSid = vikiSid || archSid || null;
        const inputProps = {
            maxLength: 5000,
            style: { minHeight: '30px' },
            className: 'tall-textarea',
            name: 'arch_clearance_api_related_sid',
            label: 'SID for ImageRight(Archlink/VIKI)',
            onChange: this.handleChange,
            value: null,
            object: RESPONSE,
        };
        if (this.props.modalData.submissionWasActioned && defaultSid) {
            inputProps.value = defaultSid;
        }
        return <TextInput {...inputProps} />;
    };

    render() {
        const { modalData, uiState, hideButtons, sendButtonText, hideReplyToEmail, disableCCEmails } = this.props;
        const didInitializeWithAdditionalEmails = this.props.modalData?.additionalReplyToEmails?.length > 0;
        let { isDisabled, featureFlags } = this.props;
        if (!modalData) {
            return null;
        }
        let { RESPONSE, showSecondaryEmailTemplate } = uiState;
        const { action, currentInbox, vikiSid, archSid } = this.props.modalData;
        isDisabled = isDisabled || RESPONSE.body === '';
        const sendButtonDisabled = isDisabled || (didInitializeWithAdditionalEmails && this.state.replyToEmails.length === 0);
        return (
            <div className="standard-modal-content flex column jc_sb">
                {hideReplyToEmail ? null : didInitializeWithAdditionalEmails ? (
                    <TagInput
                        tags={this.state.replyToEmails}
                        onRemoveTag={this.handleRemoveEmail}
                        onAddTag={this.handleAddEmail}
                        placeholder={'Reply To Emails'}
                        fuzzySearch={'inbox'}
                    />
                ) : (
                    <TextInput
                        maxLength={50}
                        style={{ minHeight: '30px' }}
                        disabled={true}
                        className="tall-textarea"
                        name="replyToEmail"
                        label="Reply To Email"
                        object={this.state}
                    />
                )}
                <If condition={action === REPLY_ACTION && currentInbox.imageright_enabled}>{this.renderSidTextInput(vikiSid, archSid)}</If>

                <TagInput
                    tags={this.state.additionalEmails}
                    onRemoveTag={this.handleRemoveAdditionalEmail}
                    onAddTag={this.handleAddAdditionalEmail}
                    placeholder="CC Emails"
                    onValidateTag={validateEmail}
                    fuzzySearch={'inbox'}
                    disabled={disableCCEmails}
                />
                {action === REPLY_ACTION && featureFlags.enable_new_email_templates ? (
                    <>
                        <div style={{ lineHeight: '20px' }}>
                            <ComboBoxInput
                                // key={selectedSlide.id}
                                object={RESPONSE}
                                label="Reply Template"
                                name="base_template_subject"
                                onChange={this.handleOnChange}
                                selectProps={{ choices: RESPONSE.templates }}
                                defaultValue={RESPONSE.base_template_subject || RESPONSE.subject}
                            />
                        </div>

                        <TextAreaInput
                            showValueTooltip={false}
                            maxLength={100}
                            style={{ minHeight: '20px' }}
                            disabled={isDisabled}
                            className="textarea"
                            name="subject"
                            label="Email Subject"
                            onChange={this.handleChange}
                            object={RESPONSE}
                            autoFocus={true}
                        />
                    </>
                ) : null}
                <TextAreaInput
                    showValueTooltip={false}
                    maxLength={5000}
                    disabled={isDisabled}
                    className="tall-textarea"
                    name="body"
                    label="Email Body"
                    onChange={this.handleChange}
                    object={RESPONSE}
                    autoFocus={true}
                />
                {this.props.handleToggleSecondaryEmailTemplate && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.75rem' }}>
                        <CheckInput
                            name="showSecondaryEmailTemplate"
                            label="Email Underwriter(s)"
                            className="width-20"
                            labelAfter={true}
                            disabled={false}
                            onChange={this.props.handleToggleSecondaryEmailTemplate}
                            object={{ showSecondaryEmailTemplate }}
                        />
                    </div>
                )}
                {!hideButtons && (
                    <div className="button-row grid align-single-to-right">
                        <button className="green" disabled={sendButtonDisabled} onClick={this.handleSendReply}>
                            {sendButtonText}
                        </button>
                        <div className="spacer wide" />
                        <button
                            className="red"
                            onClick={() => {
                                this.handleCancel();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className="orange"
                            disabled={sendButtonDisabled}
                            onClick={() => this.props.onResetTemplate(this.props.uiState.RESPONSE?.base_template_subject)}
                        >
                            Reset Template
                        </button>
                    </div>
                )}
                {/* I know this is a hack, ideally we'd have an invisible spacer when hiding the row*/}
                {hideButtons && <div className="button-row" style={{ height: '50px' }}></div>}
            </div>
        );
    }
}
