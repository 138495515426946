import React, { useState, useEffect } from 'react';
import { useApproveBorMutation, useDeclineBorMutation, useGetBorMetadataQuery } from '../../services/apiSlice';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';

const approvalSteps = [
    'Scheduling BOR Task',
    'Starting BOR Task',
    'Validating',
    'Recording BOR in VMAC',
    'Writing to ArchLink',
    'Writing to VIKI/RAE',
    'Sending emails',
    'Success!',
];
const declinationSteps = ['Scheduling declination email notification', 'Sending email', 'Success!'];

type BorApprovalProbs = {
    metadata_id: string;
};

const BorApproval = (props: BorApprovalProbs) => {
    const { metadata_id } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [didSucceed, setDidSucceed] = useState(false);
    const [failureMessage, setFailureMessage] = useState('');
    const [successfulEmailCount, setSuccessfulEmailCount] = useState(0);
    const [approveBorMutation] = useApproveBorMutation();
    const [declineBorMutation] = useDeclineBorMutation();
    const { data, isLoading } = useGetBorMetadataQuery({ metadata_id: metadata_id });

    const {
        status = null,
        submission_ids = null,
        last_mark_pending_bor_task_status = null,
        approval_email_to_submitter_status = null,
        approval_email_to_incumbent_status = null,
        declination_email_to_submitter_status = null,
        send_email = null,
        last_bor_attemped_action = null,
        decisioned_date_part = null,
        decisioned_time_part = null,
        ua_full_name = null,
    } = isLoading ? {} : data;

    useEffect(() => {
        const args = { metadata_id, data: { send_email: send_email } };
        if (last_bor_attemped_action === 'A') {
            if (last_mark_pending_bor_task_status !== 'SUCCESS') {
                approveBorMutation(args);
            }
        } else if (last_bor_attemped_action === 'D') {
            if (!send_email || declination_email_to_submitter_status !== 'S') {
                declineBorMutation(args);
            }
        }
    }, [
        approveBorMutation,
        declineBorMutation,
        last_bor_attemped_action,
        last_mark_pending_bor_task_status,
        declination_email_to_submitter_status,
        metadata_id,
        send_email,
    ]);

    const isApproval = status === 'A';
    const isDeclination = status === 'D';
    const hasResolved = status === 'R';
    const desiredEmailCount = isApproval ? 2 : 1;

    const getTaskSteps = () => {
        if (isApproval) {
            return approvalSteps;
        } else if (isDeclination) {
            return declinationSteps;
        } else if (hasResolved) {
            if (last_bor_attemped_action === 'A') {
                return approvalSteps;
            } else if (last_bor_attemped_action === 'D') {
                return declinationSteps;
            }
        }
        return [];
    };
    const taskSteps = getTaskSteps();

    const didApprovalTaskSucceed = last_mark_pending_bor_task_status === 'SUCCESS';
    const didFail = !didApprovalTaskSucceed && failureMessage.length > 0;

    if (isApproval) {
        let computedStep = currentStep;
        if (approval_email_to_submitter_status === 'S' && approval_email_to_incumbent_status === 'S') computedStep = taskSteps.length;
        else if (didApprovalTaskSucceed) computedStep = send_email ? 6 : (computedStep = taskSteps.length);

        if (currentStep < computedStep) {
            setCurrentStep(computedStep);
            if (computedStep === taskSteps.length && !didSucceed) setDidSucceed(true);
        }
    } else if (isDeclination) {
        if (declination_email_to_submitter_status === 'S' && currentStep < taskSteps.length) {
            setCurrentStep(taskSteps.length);
            setDidSucceed(true);
        } else if (!send_email && currentStep < taskSteps.length) {
            setCurrentStep(taskSteps.length);
            setDidSucceed(true);
        }
    }

    if (!isLoading && !isApproval && !isDeclination && !didSucceed) {
        setCurrentStep(taskSteps.length);
        setDidSucceed(true);
    }

    usePusherEvent(`icubed-global-channel`, 'task', (message: Record<string, any>) => {
        console.log(message);

        const { progress_description, status, fail_message } = message;

        const taskSid = message['submission_id'];
        const isEmailTask = message['task_type'] === 'SEND_EMAIL';
        const isBorTask = message['task_type'] === 'TAKE_BOR_ACTION';

        // ignore irrelevant task events
        if (
            isLoading ||
            (!isLoading && !isApproval && !isDeclination) ||
            (isBorTask && !progress_description) ||
            !(isBorTask || isEmailTask) ||
            submission_ids.indexOf(`${taskSid}`) < 0
        ) {
            return;
        }

        console.log('registered');

        let newStep = currentStep;

        if (isBorTask) {
            if (status === 'FAIL') {
                if (fail_message.includes('does not have authority to approve this BOR.')) {
                    setFailureMessage(`${fail_message} Please reach out to the development team`);
                } else {
                    setFailureMessage(`${fail_message} Please try again later`);
                }
            } else if (progress_description.indexOf('Starting') >= 0) {
                newStep = 2;
            } else if (progress_description.indexOf('Valid') >= 0) {
                newStep = 3;
            } else if (progress_description.indexOf('VMAC') >= 0) {
                newStep = 4;
            } else if (progress_description.indexOf('ArchLink') >= 0) {
                newStep = 5;
            } else if (progress_description.indexOf('VIKI/RAE') >= 0) {
                if (send_email) {
                    newStep = 6;
                } else {
                    newStep = taskSteps.length;
                    setDidSucceed(true);
                }
            }
        } else {
            if (!send_email) {
                setDidSucceed(true);
                setCurrentStep(taskSteps.length);
            } else {
                if (status === 'SUCCESS') {
                    if (successfulEmailCount === desiredEmailCount - 1) {
                        setDidSucceed(true);
                        setCurrentStep(taskSteps.length);
                    }
                    setSuccessfulEmailCount(successfulEmailCount + 1);
                } else if (status === 'FAIL' && failureMessage.length === 0) {
                    setFailureMessage(`Failed to send email for submission_id ${taskSid}. Please try again later.`);
                }
            }
        }

        if (newStep > currentStep) {
            console.log(`Setting next step ${newStep}`);
            setCurrentStep(newStep);
        }
    });

    if (isLoading) {
        return <h2>Loading... please wait</h2>;
    }

    const renderFinalStatusMessage = () => {
        let message = null;
        let messageColor = 'gray';
        if (currentStep < taskSteps.length && !didFail) {
            message = 'BOR Decision Request In Process...';
            messageColor = 'gray';
        } else if (didFail) {
            message = failureMessage;
            messageColor = 'red';
        } else if (didSucceed) {
            message = 'BOR Decision Request Successful';
            messageColor = 'green';
        }

        return message ? (
            <div className="input-label flex">
                <p style={{ color: messageColor }}>{message}</p>
            </div>
        ) : null;
    };

    return (
        <div className="w-200 flex column overflow-y">
            <div className="flex fd-r jc_c">
                <div className="info-block row-editable extra-padding">
                    <div className="header">
                        <div className="header-text">Confirmation</div>
                    </div>
                    <div className="info-rows">
                        <div className="input-label flex mrg-bottom">
                            <div className="txt-overflow">UA</div>
                        </div>
                        <div className="input-static">
                            <div className="txt-overflow">{ua_full_name}</div>
                        </div>

                        <div className="input-label flex">
                            <div className="txt-overflow">Date</div>
                        </div>
                        <div className="input-static">{decisioned_date_part}</div>

                        <div className="input-label flex">
                            <div className="txt-overflow">Time</div>
                        </div>
                        <div className="input-static">{decisioned_time_part}</div>
                        <hr />
                        <div className="clmn-headers">
                            <label>BOR Decision Steps</label>
                        </div>
                        <div></div>

                        {taskSteps.map((step, idx) => {
                            let currentLabel = step;

                            if ((currentLabel === 'Sending emails' || currentLabel === 'Sending email') && !send_email) return;

                            if (currentStep - 1 === idx && didFail) {
                                currentLabel = `${step}. Failed!`;
                            } else if (currentStep === idx && !didFail) {
                                currentLabel = `${step}...`;
                            }

                            let className = idx < currentStep ? 'badge badge-success w-fit material-icons' : 'badge badge-grey w-fit material-icons';

                            return (
                                <>
                                    <div className="input-label flex">
                                        <div className="txt-overflow">{currentLabel}</div>
                                    </div>
                                    <div className="input-static">
                                        <div className={className}>done</div>
                                    </div>

                                    {idx === taskSteps.length - 1 && renderFinalStatusMessage()}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BorApproval;
