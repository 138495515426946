import u from 'updeep';
import _ from 'lodash';
// import { AxiosResponse } from 'axios';

import { API, AppConstants } from '@archinsurance-viki/property-jslib';
import * as jslibActions from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { openMessageModal, closeCenteredModal, openCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';

import { updateRowData, loadLiteTableRows, processTableRowUpdate } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';

import { applyCustomDocumentOrdering } from '../utils/document-helpers';

import { updateEmailSentStatus, dismissModalInAFewSeconds } from './EmailActions';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import { EMPTY_FWD_OBJECT, CLEARANCE_STATUS } from '../constants/Constants';
import { ICUBED_MODAL_TYPES } from '../constants/Modals';
import { TableConfigType, TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getAccountConflicts } from './ArchActions';
import { RootState } from '../store';

export const updateAutoSelectingAccountMessage = (dispatch: DispatchType) => {
    dispatch(openCenteredModal({}, ICUBED_MODAL_TYPES.SPINNER));

    _.delay(() => {
        dispatch(closeCenteredModal());
    }, 5 * 1000);
};

export const processSubmissionValidations = (dispatch: DispatchType, { id, _validations }: TableRowDataType) => {
    let constValidations = {};
    for (let [k, v] of Object.entries(_validations ?? {})) {
        constValidations[k] = u.constant(v);
    }

    return dispatch(
        updateRowData(PAGED_TABLES.SUBMISSIONS, {
            id,
            _validations: constValidations,
        })
    );
};

export const processSubmissionData = (dispatch: DispatchType, { submission, documents = undefined, buildings = [] }: any) => {
    const newRowData: any = {
        id: submission.id,
        SUPPLEMENTED: true,
        buildings: buildings,
        _is_dirty: false,
        arch_account: submission.arch_account,
        arch_account_name: submission.arch_account.name,
        arch_account_street: submission.arch_account.street,
        arch_account_city: submission.arch_account.city,
        arch_account_state: submission.arch_account.state,
        arch_account_zipcode: submission.arch_account.zipcode,
        arch_account_phone_number: submission.arch_account.phone_number,
        arch_account_fein: submission.arch_account.fein,
        account_agent_id: submission.account_agent_id,
        arch_clearance_api_submission_id: submission.arch_clearance_api_submission_id,
        office_id: submission.office_id,
        licensed_broker_id: submission.licensed_broker_id,
        office_parent_company_id: submission.office_parent_company_id,
        reply_to_email_disabled: submission.reply_to_email_disabled,
        sov_was_parsed: submission.sov_was_parsed,
        possible_duplicate_sids: submission.possible_duplicate_sids,
        blocking_submission_ids: submission.blocking_submission_ids,
        incumbent_submission_ids: submission.incumbent_submission_ids,
        pending_bor_submission: submission.pending_bor_submission,
        arch_account_passes_ofac_checks: submission.arch_account_passes_ofac_checks,
        arch_account_last_ofac_received_on: submission.arch_account_last_ofac_received_on,
        arch_account_last_ofac_submit_by_name: submission.arch_account_last_ofac_submit_by_name,
        arch_account_last_ofac_submit_status: submission.arch_account_last_ofac_submit_status,
        is_renewal_linked: submission.is_renewal_linked,
        // NOTE: block & decline validations are the same right now so keeping it simple here
    };
    if (documents !== undefined) {
        newRowData.documents = applyCustomDocumentOrdering(
            documents.map(doc => {
                return {
                    ...doc,
                    title: doc.type === 'SUBMISSION_EMAIL' ? 'CORRESPONDENCE' : doc.description || doc.filename,
                    secure_url: `${AppConstants.FILE_PROXY_BASE_URL}?document_id=${doc.id}`,
                };
            })
        );
    }

    return dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, newRowData));
};

export const removeSubmissionIsDirtyFlag = (submissionData: Record<string, any>) => {
    return (dispatch: DispatchType) => dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { ...submissionData, _is_dirty: false }));
};

export const getSubmissionDataFromServer = (submissionId: number, customQueryParams?: string[], pullConflicts = false): any => {
    return (dispatch: DispatchType) => {
        let request = {
            id: submissionId,
            showProgress: true,
            customQueryParams,
        };

        return API.doRequest(API.endpoints.submission.get, request, dispatch).then(submissionData => {
            processSubmissionData(dispatch, submissionData.data);
            if (pullConflicts) {
                return getAccountConflicts(submissionId)(dispatch);
            }
        });
    };
};

export const getSubmissionDataForUiState = (submissionId: number, customQueryParams?: string[]): any => {
    return (dispatch: DispatchType, getState: () => RootState) => {
        let request = {
            id: submissionId,
            showProgress: true,
            customQueryParams,
        };
        return API.doRequest(API.endpoints.submission.get, request, dispatch).then(submissionData =>
            updeepUi(submissionData.data.submission, 'SUBMISSION')(dispatch, getState)
        );
    };
};

export const saveDocumentData = (submissionId: number, documentId: number, data: any): any => {
    return (dispatch: DispatchType) => {
        let request = {
            id: documentId,
            params: data,
            showProgress: true,
        };
        return API.doRequest(API.endpoints.submission.saveDocumentData, request, dispatch).then(() => {
            dispatch(getSubmissionDataFromServer(+submissionId));
        });
    };
};

export const getBuildingDistribution = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            submission_id: submissionId,
            hideDialogForErrors: true,
        };
        return API.doRequest(API.endpoints.submission.getBuildDistributions, request, dispatch).then(({ data }) => {
            dispatch(
                jslibActions.updateRowData(PAGED_TABLES.SUBMISSIONS, {
                    id: submissionId,
                    distribution: data,
                    SUPPLEMENTED: true,
                })
            );
        });
    };
};

export const getSubmissionDuplicates = (submissionId: number, tableConfig: TableConfigType) => {
    return (dispatch: DispatchType) => {
        const request = {
            submission_id: submissionId,
            hideDialogForErrors: true,
        };
        return API.doRequest(API.endpoints.submission.getDuplicates, request, dispatch).then(({ data }) => {
            dispatch(loadLiteTableRows(tableConfig, data));
        });
    };
};

export const selectNewClient = (submissionId: number, newClientId: number) => {
    return (dispatch: DispatchType) => {
        // dispatch(updateRowData(PAGED_TABLES.SUBMISSIONS, { id: submissionId, status: newStatus }));
        return API.doRequest(
            API.endpoints.submission.selectNewClient,
            {
                submissionId,
                params: {
                    new_client_id: newClientId,
                },
                showProgress: true,
            },
            dispatch
        )
            .catch(xhr => {
                // reload the status
                dispatch(getSubmissionDataFromServer(submissionId));
                throw xhr;
            })
            .finally(() => {
                dispatch(getSubmissionDataFromServer(submissionId));
            });
    };
};

export const selectProducer = (submissionId: number, producerId: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.submission.selectProducer,
            {
                submissionId,
                params: {
                    arch_producer_arch_id: producerId,
                },
                showProgress: true,
            },
            dispatch
        )
            .catch(xhr => {
                // reload the status
                dispatch(getSubmissionDataFromServer(submissionId));
                throw xhr;
            })
            .finally(() => {
                dispatch(getSubmissionDataFromServer(submissionId));
            });
    };
};

export const linkSubmissionAction = (submissionId: number, params: Record<string, any>) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(API.endpoints.submission.linkToSubmission, { submissionId: submissionId, params: params }, dispatch).then(
            () => dispatch(getSubmissionDataFromServer(submissionId)),
            xhr => {
                throw xhr;
            }
        );
    };
};

export const unlinkSubmissionAction = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(API.endpoints.submission.unlinkFromSubmission, { submissionId: submissionId }, dispatch).then(
            () => {
                dispatch(getSubmissionDataFromServer(submissionId));
            },
            xhr => {
                throw xhr;
            }
        );
    };
};
export const takeAction = (
    submissionId: number | string,
    newStatus: string,
    emailParams: any,
    ccEmails?: string[],
    sendEmailNotification?: boolean,
    archClearanceAPIRelatedSID?: string,
    relatedSubmissionIds?: string[],
    relatedSubmissionEmailParams?: Record<string, any>,
    relatedCcSubmissionCcEmails?: string[],
    emailRequestedUnderwriter?: boolean,
    emailOriginatingUnderwriter?: boolean
) => {
    return (dispatch: DispatchType, getState: () => RootState) => {
        return API.doRequest(
            API.endpoints.submission.changeStatus,
            {
                submissionId,
                params: {
                    action: newStatus,
                    email_params: emailParams,
                    cc_emails: ccEmails,
                    related_submission_ids: relatedSubmissionIds,
                    related_submission_cc_emails: relatedCcSubmissionCcEmails,
                    related_submission_email_params: relatedSubmissionEmailParams,
                    send_email_notification: sendEmailNotification,
                    email_requested_underwriter: emailRequestedUnderwriter,
                    email_originating_underwriter: emailOriginatingUnderwriter,
                    arch_clearance_api_related_sid: archClearanceAPIRelatedSID,
                },
                showProgress: true,
            },
            dispatch
        ).then(
            ({ data }) => {
                if (data.message) {
                    updateEmailSentStatus(dispatch, data.message);
                    dismissModalInAFewSeconds(dispatch);
                }
                if (newStatus === CLEARANCE_STATUS.FORWARDED) {
                    updeepUi(EMPTY_FWD_OBJECT, 'FWD')(dispatch, getState);
                    dispatch(closeCenteredModal());
                }
                return data;
            },
            xhr => {
                // reload the status
                if (newStatus === CLEARANCE_STATUS.FORWARDED) {
                    updeepUi({
                        FWD: { _errors: xhr.data.errors },
                    })(dispatch, getState);
                }
                dispatch(getSubmissionDataFromServer(+submissionId));
                throw xhr;
            }
        );
    };
};

export const downloadAllSubmissionDocs = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.submission.downloadDocuments,
            {
                id: submissionId,
                showProgress: true,
            },
            dispatch
        ).then(({ data }) => {
            if (data.is_partial_download) {
                dispatch(
                    openMessageModal(
                        {
                            title: 'Warning: some files are missing from this download',
                            modalData: {
                                confirmLabelTitle: 'Download',
                                onOk: () => {
                                    window.location.href = data.url;
                                },
                            },
                        },
                        CENTERED_MODAL_TYPES.CONFIRM
                    )
                );
            } else {
                window.location.href = data.url;
            }
        });
    };
};

export const getStateFromZipcode = (submissionId: number, zipCode: string) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.insured.getStateFromZipcode,
            {
                zipCode: zipCode,
                submissionId: submissionId,
            },
            dispatch
        ).then(function (response) {
            let { status } = response;
            if (status === 200) {
                return dispatch(
                    updateRowData(PAGED_TABLES.SUBMISSIONS, {
                        id: submissionId,
                    })
                );
            }
        });
    };
};

export const runBotResults = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        dispatch(
            openMessageModal(
                {
                    title: 'Do you want to re-run the bot results?',
                    modalData: {
                        confirmLabelTitle: 'Yes',
                        onOk: () => {
                            let request = {
                                params: {
                                    submission_id: submissionId,
                                },
                            };
                            API.doRequest(API.endpoints.submission.runSovBot, request, dispatch).then();
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
};

export const runSOVParser = (documentId: number) => {
    return (dispatch: DispatchType) => {
        dispatch(
            openMessageModal(
                {
                    title: 'Do you want to re-run the SOV Parser?',
                    modalData: {
                        confirmLabelTitle: 'Yes',
                        onOk: () => {
                            let request = {
                                params: {
                                    document_id: documentId,
                                },
                            };
                            API.doRequest(API.endpoints.submission.runSovBotWithDocumentId, request, dispatch).then();
                        },
                    },
                },
                CENTERED_MODAL_TYPES.CONFIRM
            )
        );
    };
};

export const setOverrideReason = (submissionId: number, reason: string, blockingSubmissionIds: number[]) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.submission.setOverrideReason,
            {
                submissionId,
                params: {
                    reason: reason,
                    blocking_submission_ids: blockingSubmissionIds,
                },
                showProgress: false,
                hideDialogForErrors: true,
            },
            dispatch
        )
            .catch(xhr => {
                // reload the status
                dispatch(getSubmissionDataFromServer(submissionId));
                throw xhr;
            })
            .finally(() => {
                dispatch(getSubmissionDataFromServer(submissionId));
            });
    };
};

export const overrideAutoAction = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.submission.overrideAutoAction,
            {
                params: { submissionId: submissionId },
            },
            dispatch
        ).then(function (response) {
            let { status } = response;
            if (status === 201) {
                return dispatch(
                    processTableRowUpdate(PAGED_TABLES.SUBMISSIONS, submissionId, [
                        {
                            field: 'unactioned_auto_suggested_action',
                            old_value: true,
                            new_value: false,
                        },
                    ])
                );
            }
        });
    };
};
