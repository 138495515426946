import { useGetBorMetadataQuery, useUpdateBorMetadataMutation } from '../../services/apiSlice';
import { BorState } from '../../ts-types/DataTypes';

import BorDecision from './BorDecision';
import BorDecisionEmail from './BorDecisionEmail';
import BorPermission from './BorPermission';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { openMessageModal, closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { dismissModalInAFewSeconds } from '../../actions/EmailActions';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import BorApproval from './BorApproval';

const BorDecisionApp = () => {
    const url = new URL(window.location.href);
    const metadataId = url.pathname.split('/')[2];
    const { data, isLoading, isError, error } = useGetBorMetadataQuery({ metadata_id: metadataId });
    const [updateMetadataServer] = useUpdateBorMetadataMutation();
    const dispatch = useAppDispatch();

    const centeredModalData = useAppSelector(state => state.global.centeredModalData);
    const centeredModalType = useAppSelector(state => state.global.centeredModalType);
    const onCloseCenteredModal = () => {
        dispatch(closeCenteredModal());
    };
    const {
        effective_date = null,
        incumbent_submission = null,
        bor_submission = null,
        arch_account_name = null,
        bor_decision_to_email = null,
        incumbent_decision_to_email = null,
        bor_decision_cc_emails = null,
        incumbent_decision_cc_emails = null,
        progress_state = null,
    } = isLoading || isError ? {} : data;

    const mapProgressStateToBORState = progress_state => {
        if (progress_state === 0) {
            return BorState.DECISION;
        } else if (progress_state === 1) {
            return BorState.APPROVAL_EMAILS;
        } else if (progress_state === 2) {
            return BorState.DECLINE_EMAIL;
        } else if (progress_state === 3) {
            return BorState.CONFIRMATION;
        } else if (progress_state === 4) {
            return BorState.PERMISSION_ERROR;
        } else if (progress_state === null) {
            return BorState.DECISION;
        }
    };

    const currentState = mapProgressStateToBORState(progress_state);

    const handleOnClickApproveOrDecline = isApproved => {
        let newBorState = isApproved ? BorState.APPROVAL_EMAILS : BorState.DECLINE_EMAIL;
        updateMetadataServer({
            metadata_id: metadataId,
            data: {
                progress_state: newBorState,
            },
        });
    };

    const renderCurrentBorStage = () => {
        if (isError && 'status' in error && error.status === 403) {
            return <BorPermission />;
        }

        if (currentState === BorState.DECISION) {
            return (
                <BorDecision
                    effective_date={effective_date}
                    bor_submission={bor_submission}
                    arch_account_name={arch_account_name}
                    incumbent_submission={incumbent_submission}
                    handleOnClickApproveOrDecline={handleOnClickApproveOrDecline}
                />
            );
        } else if (currentState === BorState.APPROVAL_EMAILS || currentState === BorState.DECLINE_EMAIL) {
            return (
                <BorDecisionEmail
                    metadata_id={metadataId}
                    currentState={currentState}
                    bor_submission={bor_submission}
                    incumbent_submission={incumbent_submission}
                    bor_decision_to_email={bor_decision_to_email}
                    incumbent_decision_to_email={incumbent_decision_to_email}
                    bor_decision_cc_emails={bor_decision_cc_emails}
                    incumbent_decision_cc_emails={incumbent_decision_cc_emails}
                />
            );
        } else if (currentState === BorState.CONFIRMATION) {
            return <BorApproval metadata_id={metadataId} />;
        }
    };

    const renderNav = () => {
        const steps = [
            ['1. Decision', [BorState.DECISION]],
            ['2. Email', [BorState.APPROVAL_EMAILS, BorState.DECLINE_EMAIL]],
            ['3. Confirmation', [BorState.CONFIRMATION]],
        ];

        return (
            <div className="filter-panel no-mrg">
                {steps.map(step => {
                    const stepLabel = step[0];
                    const activeStates: BorState[] = step[1] as BorState[];
                    let isActive = activeStates.indexOf(currentState) >= 0;
                    const className = isActive ? 'table-pref active' : 'table-pref';
                    return (
                        <div key={stepLabel[0]} className={className}>
                            {stepLabel}
                        </div>
                    );
                })}
            </div>
        );
    };

    function unsecuredCopyToClipboard(url: string) {
        let textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    const copyBorLinkToClipboard = (url: string) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(url);
        } else {
            unsecuredCopyToClipboard(url);
        }
        dispatch(openMessageModal({ description: 'Saved to Clipboard', disableModalResizable: true }));
        dismissModalInAFewSeconds(dispatch);
    };

    return (
        <>
            <CenteredModal modalData={centeredModalData} modalType={centeredModalType} onClose={onCloseCenteredModal} />
            <div id="js-viki-app">
                <div className="root-container">
                    <div className="main-content">
                        <div className="main-content-center data-entry-wrapper bor-decision">
                            <div className="viki-tasks">
                                <div className="flex column w-100 padding-standard">
                                    <div className="flex fd-r padding-none jc_c">{renderNav()}</div>
                                </div>
                            </div>

                            <div className="ventus-content fd-r content-hidden">{isLoading ? <Spinner /> : renderCurrentBorStage()}</div>
                        </div>
                    </div>

                    <div className="header-main env-not-production bor-decision">
                        <a className="viki-logo" href="/">
                            VMAC<span className="subtitle caps-style">Clearance</span>
                        </a>

                        {/* TODO: What is this?? */}
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <div className="submission-info" main-content-center="true">
                            <div className="submission-details">
                                <div className="submission-id-details ellipsize status-wrapper">
                                    <div className="info-figure marg-lft-adjst">
                                        <span className="info-data status-badge">BOR Decision Report</span>

                                        <a className="info-data status-badge w-icon">
                                            <span className="material-icons tw-cursor-pointer" onClick={() => copyBorLinkToClipboard(url.toString())}>
                                                ios_share
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BorDecisionApp;
