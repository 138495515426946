import React from 'react';

import { SubmissionType, ValidationErrorType } from '../../ts-types/DataTypes';
import { ICONS } from '../../constants/Constants';

type propTypes = {
    label?: string;
    items: ValidationErrorType[];
    currentSubmission: SubmissionType;
    classNames?: Record<string, string | number>;
};

export default class ErrorsAndWarningsPanel extends React.Component<propTypes> {
    /**
     * Formats the name of the ValidationError received for cases it needs to be formatted.
     * For example, in the case of an HTTP timeout error.
     *
     * @param error The validation error to be processed.
     * @returns The original error if no formatting was required.
     * @returns The formatted error in case formatting was required.
     */
    formatError(error: ValidationErrorType): ValidationErrorType {
        if (error.name.toLowerCase().includes('httpconnectionpool')) {
            return { type: error.type, name: 'The Archlink API timed out. Please retry in a little while.' };
        }
        return error;
    }

    renderErrors() {
        return this.props.items.map((error: ValidationErrorType, index: number) => {
            const errorType = error?.name.indexOf('Arch Account Conflict.') === 0 ? 'WARN' : error.type;
            const rowDisplayInfo = ICONS[errorType];
            if (!rowDisplayInfo) {
                console.error('error format not supported', error);
                return <></>;
            }
            return (
                <div key={index} className={`errors-content-item`}>
                    <div className={`status-icons ${rowDisplayInfo.color}`}>
                        <span className="material-icons">{rowDisplayInfo.icon}</span>
                    </div>

                    <div className="message">{this.formatError(error).name}</div>
                </div>
            );
        });
    }

    render() {
        return <>{this.renderErrors()}</>;
    }
}
