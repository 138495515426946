export enum GridBadgeType {
    RENEWAL = 'RENEWAL',
}

export type GridBadgePropType = {
    badgeType: GridBadgeType;
    clickCallback?: () => void | null;
};

export const GridBadge = (props: GridBadgePropType) => {
    switch (props.badgeType) {
        case GridBadgeType.RENEWAL:
            return (
                <div key="is-renewal-linked" onClick={props.clickCallback} className="badge badge-success margin-left-05 w-fit" title="Is Linked Renewal">
                    RE
                </div>
            );
        default:
            return null;
    }
};
