import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';

import AddResponseApp from '../apps/AddResponseApp';
import { takeAction } from '../../../../actions/SubmissionActions';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { getTemplate_server, putTemplate_server } from '../../../../actions/EmailActions';
import {
    ACTIONS_MAPPING,
    EMPTY_RESPONSE_OBJECT,
    BOR_RELEASED_PRIOR_TO_APPROVAL_CLEAR_BOR,
    BOR_RELEASED_PRIOR_TO_APPROVAL_DECLINE_INCUMBENT,
} from '../../../../constants/Constants';
import { PromiseDispatchType, SubmissionType } from '../../../../ts-types/DataTypes';
import { useGetBorDataQuery } from '../../../../services/apiSlice';

type propsType = {
    currentSubmission: SubmissionType;
};

const ResolvePendingBORApp = (props: propsType) => {
    const dispatch = useAppDispatch();
    const { currentSubmission } = props;
    const { id } = currentSubmission;

    const { data: borData } = useGetBorDataQuery({ submissionId: id });

    const borTemplateKey = 'RELEASEBORRESPONSE';
    const incumbentTemplateKey = 'RELEASEINCUMBENTRESPONSE';

    const incumbentSubmissionResponse = useAppSelector(state => {
        return state.uiState[incumbentTemplateKey];
    });
    const borSubmissionResponse = useAppSelector(state => state.uiState[borTemplateKey]);

    const sendEmailNotification = useAppSelector(state => state.uiState?.emailNotification?.shouldSend);

    const borSubmissionData = borData?.submissions[borData?.submissions?.length - 1];
    const incumbentSubmissionData = borData?.submissions?.filter(subData => subData.id === borData?.incumbent_submission_id)[0];
    const [incumbentSubmissionAdditionalEmails, setIncumbentSubmissionAdditionalEmails] = useState([]);
    const [borSubmissionAdditionalEmails, setBorSubmissionAdditionalEmails] = useState([]);

    const borAction = BOR_RELEASED_PRIOR_TO_APPROVAL_CLEAR_BOR;
    const getBorTemplate = (params = {}) =>
        borSubmissionData && dispatch(getTemplate_server({ submissionId: borSubmissionData?.id, action: borAction, ...params }, borTemplateKey));
    const putBorTemplate = body => dispatch(putTemplate_server({ submissionId: borSubmissionData?.id, action: borAction, body }));

    const incumbentAction = BOR_RELEASED_PRIOR_TO_APPROVAL_DECLINE_INCUMBENT;
    const getIncumbentTemplate = (params = {}) =>
        incumbentSubmissionData &&
        dispatch(getTemplate_server({ submissionId: incumbentSubmissionData?.id, action: incumbentAction, ...params }, incumbentTemplateKey));
    const putIncumbentTemplate = body => dispatch(putTemplate_server({ submissionId: incumbentSubmissionData?.id, action: incumbentAction, body }));

    useEffect(() => {
        if (borSubmissionData) {
            setBorSubmissionAdditionalEmails([borSubmissionData?.underwriter_email]);
        }
        if (incumbentSubmissionData) {
            setIncumbentSubmissionAdditionalEmails([incumbentSubmissionData?.underwriter_email]);
        }
    }, [borSubmissionData, incumbentSubmissionData]);

    const onResetTemplate = () => {
        const params = { reset: true };
        getIncumbentTemplate(params);
        getBorTemplate(params);
    };

    const onClose = () => {
        dispatch(closeCenteredModal());
    };
    const onClearFormData = () => {
        dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, borTemplateKey));
        dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, incumbentTemplateKey));
    };

    const onTakeAction = (_action: string, _emailParams: any, _ccEmails?: string[], _replyToEmails?: string[]): PromiseDispatchType => {
        onClose();
        return onReleasePendingApproval();
    };

    const handleAdditionalEmailsChange = (emails: string[], submissionId: number) => {
        if (String(submissionId) === borSubmissionData?.id) {
            setBorSubmissionAdditionalEmails(emails);
        } else {
            setIncumbentSubmissionAdditionalEmails(emails);
        }
    };

    const baseProps = {
        onClose,
        onTakeAction,
        onClearFormData,
        onSearchInbox: null,
        onSendResponse: null,
        handleAdditionalEmailsChange,
        onResetTemplate,
    };

    const borProps = {
        ...baseProps,
        uiState: {
            RESPONSE: borSubmissionResponse,
        },
        sendButtonText: 'Send Emails',
        onGetTemplate: getBorTemplate,
        onPutTemplate: putBorTemplate,
        modalData: {
            replyToEmail: borSubmissionData?.reply_to_email,
            currentSubmissionId: borSubmissionData?.id,
            action: borAction,
            additionalCCEmails: borSubmissionAdditionalEmails,
        },
        templateKey: borTemplateKey,
    };

    const incumbentProps = {
        ...baseProps,
        uiState: {
            RESPONSE: incumbentSubmissionResponse,
        },
        hideButtons: true,
        onGetTemplate: getIncumbentTemplate,
        onPutTemplate: putIncumbentTemplate,
        modalData: {
            action: incumbentAction,
            currentSubmissionId: incumbentSubmissionData?.id,
            replyToEmail: incumbentSubmissionData?.reply_to_email,
            additionalCCEmails: incumbentSubmissionAdditionalEmails,
        },
        templateKey: incumbentTemplateKey,
    };

    const onReleasePendingApproval = (): any => {
        return dispatch(
            takeAction(
                borSubmissionData?.id,
                ACTIONS_MAPPING.submission_release_pending_approval,
                { ...borSubmissionResponse, replyToEmail: borSubmissionData?.reply_to_email },
                borSubmissionAdditionalEmails,
                sendEmailNotification,
                null,
                [String(incumbentSubmissionData?.id)],
                { ...incumbentSubmissionResponse, replyToEmail: incumbentSubmissionData?.reply_to_email },
                incumbentSubmissionAdditionalEmails
            )
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
            {sendEmailNotification && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AddResponseApp {...incumbentProps} />
                    <AddResponseApp {...borProps} />
                </div>
            )}
            {!sendEmailNotification && (
                <div className="button-row">
                    <button className="green" onClick={() => onTakeAction(null, null, null, null)}>
                        Release
                    </button>
                    <div className="spacer wide" />
                    <button
                        className="red"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResolvePendingBORApp;
