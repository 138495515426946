import * as React from 'react';

import OcrResults from '../containers/OcrResults.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { Types } from '../../../../ts-types/icubed-types';

type bodyPropTypes = {
    ENV: Types.Env;
    modalData: {
        submission_id: number;
        parsed_data: Record<string, any>;
        rowData: any;
    };
    onClose: (close: boolean) => void;
};

class OcrResultsBody extends React.Component<bodyPropTypes> {
    render() {
        return <OcrResults {...this.props} />;
    }
}

const modalTypeObj = {
    Body: OcrResultsBody,
    Header: DefaultHeaderStatic('OCR Results'),
    className: 'ocr-results-modal',
};

registerType(ICUBED_MODAL_TYPES.OCR_RESULTS, modalTypeObj);
export default modalTypeObj;
