import ImageRightLog from '../containers/ImageRightLog.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { Types } from '../../../../ts-types/icubed-types';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

const ImageRightLogBody = (props: bodyPropTypes) => {
    return <ImageRightLog {...props} />;
};

type headerPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

const ImageRightLogHeader = (props: headerPropTypes) => {
    let { currentSubmission } = props;
    return DefaultHeaderStatic(`ImageRight Log for ${currentSubmission.id}`);
};

const modalTypeObj = {
    Body: ImageRightLogBody,
    Header: ImageRightLogHeader,
    className: 'imageright-log-modal wide',
};

registerType(ICUBED_MODAL_TYPES.IMAGERIGHT_LOG, modalTypeObj);
export default modalTypeObj;
