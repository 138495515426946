import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { getMatchedBrokerOffices_server } from '../../../../actions/ClientActions';

import { PAGED_TABLES } from '../../../../constants/PagedTableConfigs';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';

import { persistRowData_server, resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { processGridRequestWithId } from '@archinsurance-viki/property-jslib/src/utils/api/RequestUtils';
import { Types } from '../../../../ts-types/icubed-types';
import { VoidFn } from '../../../../ts-types/DataTypes';
import BrokerOfficeMatchingApp from '../apps/BrokerOfficeMatchingApp';
import { SITE_ID } from '../../../../constants/Constants';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

type propTypes = {
    onPersistRowData: (field: string, value: any) => void;
    uiState: Record<string, any>;
    onGetMatchedBrokerOffices: (searchString: string, usState: string) => void;
    currentBrokerOfficeName: string | null;
    resetTable: VoidFn;
    CONSTANTS: Types.Constants;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { currentSubmission } = ownProps;
    const { CONSTANTS } = state.global;
    return {
        uiState: state.uiState,
        tableData: state.brokerOfficeMatches,
        currentBrokerOfficeName: currentSubmission.office_name,
        CONSTANTS: CONSTANTS,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    // note, this tableData is parentTableData, not the tableData powering the litetable used in the modal.
    let { currentSubmission, tableData } = ownProps;
    return {
        onGetMatchedBrokerOffices: (searchString: string, usState: string) => {
            return dispatch(getMatchedBrokerOffices_server(searchString, SITE_ID.RAE, currentSubmission.inbox_id, usState));
        },
        onPersistRowData: (field: string, value: any) => {
            return dispatch(
                persistRowData_server(PAGED_TABLES.SUBMISSIONS, processGridRequestWithId(currentSubmission.id, { [field]: value }, tableData.rowData))
            );
        },
        resetTable: () => {
            dispatch(resetTable(LITE_TABLES.BROKEROFFICEMATCHES));
        },
    };
};

class BrokerOfficeMatching extends React.Component<propTypes> {
    render() {
        return <BrokerOfficeMatchingApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BrokerOfficeMatching);
export default connected;
