import '@archinsurance-viki/property-jslib/ts-typings/conditionals';
require('./styles-index.scss');
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// TODO: Upgrade to actual react 18
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { initializeAPI, initializeConstants, initializePusherEvents, initializePusherUserActivityEvents } from '@archinsurance-viki/property-jslib';
import * as API_CONFIG from './utils/setup-endpoints';
initializeAPI(API_CONFIG);
import CONSTANTS from './constants/InitAppConstants';
initializeConstants(CONSTANTS);
import PUSHER_EVENTS from './constants/InitAppPusherEvents';
initializePusherEvents(PUSHER_EVENTS['global_channel']);
initializePusherUserActivityEvents(PUSHER_EVENTS['user_activity_channel']);

import DashboardRoutes from './components/dashboard/DashboardRoutes';
import RootContainer from '@archinsurance-viki/property-jslib/src/containers/RootContainer';
import store from './store';

// Require these manually as the types register themselves.
import './components/common/modals/types';

let rootElement = document.getElementById('icubed-application');
render(
    <Provider store={store}>
        <RootContainer fetchUserSettings={true}>
            <DashboardRoutes />
        </RootContainer>
    </Provider>,
    rootElement
);
