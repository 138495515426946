import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import AddResponse from '../containers/AddResponse.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    modalData: {
        currentSubmissionId: number;
        action: string;
        replyToEmail: string;
    };
    onClose: (close: boolean) => void;
};

class AddResponseBody extends React.Component<bodyPropTypes> {
    render() {
        return <AddResponse {...this.props} />;
    }
}

const modalTypeObj = {
    Body: AddResponseBody,
    Header: DefaultHeaderStatic('Add Email Response'),
    className: 'add-note-modal width-75',
};

registerType(ICUBED_MODAL_TYPES.ADD_RESPONSE, modalTypeObj);
export default modalTypeObj;
