import { API } from '@archinsurance-viki/property-jslib';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { updateEmailSentStatus } from './EmailActions';
import { openCenteredModal, closeMessageModal, openMessageModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';
import { ICUBED_MODAL_TYPES } from '../constants/Modals';
import { PagedTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
// import { VoidFn } from '../ts-types/DataTypes';

type OcrResultType = {
    submission_id: number;
    parsed_data: Record<string, string>;
    tableData: PagedTableDataType;
};

export const popOcrResultsModal = (ocrResults: OcrResultType, rowData: any) => {
    let defaultTypes = {
        rowData: rowData,
    };
    return (dispatch: DispatchType) => {
        dispatch(
            openCenteredModal(
                {
                    modalData: Object.assign({}, defaultTypes, ocrResults),
                },
                ICUBED_MODAL_TYPES.OCR_RESULTS
            )
        );
    };
};

export const ocrImage_server = (documentId: number, page: number, dims: Record<string, any>) => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        updateEmailSentStatus(dispatch, `Attemping to OCR your selection`);
        const rowData = getState().submissionsLog.rowData;
        return API.doRequest(
            API.endpoints.ocr.image,
            {
                params: {
                    document_id: documentId,
                    dims: dims,
                    page: page,
                },
            },
            dispatch
        )
            .then(response => {
                dispatch(popOcrResultsModal(response.data, rowData));
            })
            .catch(error => {
                console.error('Any error occured while trying to OCR.', error);
            })
            .finally(() => {
                dispatch(closeMessageModal(0));
            });
    };
};

export const classifySelectedText_server = (documentId: number, text: string) => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        updateEmailSentStatus(dispatch, `Attemping to OCR your selection`);
        const rowData = getState().submissionsLog.rowData;
        return API.doRequest(
            API.endpoints.classify_selected_text.post,
            {
                params: {
                    document_id: documentId,
                    text: text,
                },
            },
            dispatch
        )
            .then(response => {
                dispatch(popOcrResultsModal(response.data, rowData));
            })
            .catch(error => {
                console.error('Any error occured while trying to OCR.', error);
            })
            .finally(() => {
                dispatch(closeMessageModal(0));
            });
    };
};

export const createFakeSubmission = type => {
    return (dispatch: DispatchType) => {
        return API.doRequest(
            API.endpoints.fake_data.create_smms_submission,
            {
                params: { type },
            },
            dispatch
        ).then(
            ({ data }) => {
                dispatch(
                    openMessageModal(
                        {
                            title: 'Result',
                            description: data.message,
                            modalData: {
                                confirmLabelTitle: 'Ok',
                                onOk: () => {
                                    return;
                                },
                            },
                            disableModalResizable: true,
                        },
                        CENTERED_MODAL_TYPES.CONFIRM
                    )
                );
                return data;
            },
            xhr => {
                return xhr;
            }
        );
    };
};
