import _ from 'lodash';
import { CLEARANCE_STATUS, EXTERNAL_SOURCES } from '../constants/Constants';

export const determineRowColor = rowData => {
    /* VIKI-4310 */
    let backgroundColor = '';
    const hasValidations = rowData._validations && !_.isEmpty(rowData._validations.ERROR);
    const isBlocked = rowData.status === CLEARANCE_STATUS.BLOCKED;
    const isArchieved = rowData.status === CLEARANCE_STATUS.ARCHIVED;
    const isDeclined = rowData.status === CLEARANCE_STATUS.DECLINED;

    if (rowData.is_likely_blocked) {
        backgroundColor = 'orange';
    } else if (!hasValidations && !rowData.date_cleared && !(isBlocked || isArchieved || isDeclined)) {
        backgroundColor = 'blue';
    }

    // VIKI-6477
    backgroundColor = '';
    return `background-${backgroundColor}`;
};

const ALL_VALIDATION_FIELDS = [
    'account_agent_id',
    'licensed_broker_id',
    'office_id',
    'office_parent_company_id',
    'request_underwriter_id',
    'reply_to_email',
    'insured_name',
    'insured_street',
    'insured_city',
    'insured_state',
    'insured_zipcode',
    'inception_date',
    'expiration_date',
    'description_of_operations',
];

export const hasClearValidationErrors = (obj, validations) => {
    let errors = _.intersection(Object.keys(validations || {}), ALL_VALIDATION_FIELDS);
    if (errors.length === 0) {
        return false;
    }

    return true;
};

export const hasBlockDeclineValidationErrors = (obj, validations) => {
    let errors = _.intersection(Object.keys(validations || {}), ALL_VALIDATION_FIELDS);
    if (errors.length === 0) {
        return false;
    }

    if (errors.length === 1 && errors.includes('insured_name') && !_.isNull(obj.insured_name)) {
        return false;
    }

    return true;
};

export const getExternalSubmissionUrl = (source, sid, ENV) => {
    let submissionUrl = null;
    if (source === EXTERNAL_SOURCES.VIKI) {
        submissionUrl = `${ENV.VIKI_BASE_URL}/submissions/${sid}/current/overview`;
    } else if (source === EXTERNAL_SOURCES.ARCH) {
        submissionUrl = `${ENV.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${sid}`;
    } else {
        console.error(`getExternalSubmissionUrl Unknown source for SID=${sid}`);
    }
    return submissionUrl;
};

export const getBlockingExternalSubmissionUrl = (currentSubmission, ENV) => {
    let { blocking_external_submission_source, blocking_external_submission_id } = currentSubmission;

    if (!blocking_external_submission_id) {
        return null;
    }
    let submissionUrl = getExternalSubmissionUrl(blocking_external_submission_source, blocking_external_submission_id, ENV);
    return submissionUrl;
};
