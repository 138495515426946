import * as React from 'react';
import { MouseEventHandler } from 'react';

import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { DataInfoBlock } from '@archinsurance-viki/property-jslib/src/components/blocks/DataInfoBlock';
import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import { PagedTableDataType, TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import ConfirmPromptButton from '@archinsurance-viki/property-jslib/src/components/popups/ConfirmPrompt';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { VMAC_BOTTOM_PANEL_EVENT } from '../../constants/AnalyticsConstants';
import { InboxType, SubmissionType } from '../../ts-types/DataTypes';
import { CLEARANCE_INFOBLOCKS } from '../../constants/InfoBlocks';
import { blockPropTypes, NoteType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import ThrottledButton from '@archinsurance-viki/property-jslib/src/components/buttons/ThrottledButton';
import { TaskItem } from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasksPanel';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';
import { FormSelect } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormSelect';
import { CHOOSE_A_VALUE } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { FormInput } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormInput';
import { Choice } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { GetReferenceDataUnderwritersResultType } from '../../ts-types/ApiTypes';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import { ReferenceDataUnderwritersResultType } from '@archinsurance-viki/property-jslib/src/ts-types/ApiTypes';

type propTypes = {
    header?: string;
    readonly?: boolean;
    defs?: any;
    currentSubmission: SubmissionType;
    currentNotes: NoteType[];
    currentInbox: InboxType;
    onSaveFn?: (key: string, value: any) => void;
    onPopScheduleView?: MouseEventHandler<HTMLButtonElement>;
    onHandleAccountSearch?: MouseEventHandler<HTMLButtonElement>;
    onHandleConflictResolution?: MouseEventHandler<HTMLButtonElement>;
    onHandleUnlinkAccount?: MouseEventHandler<HTMLButtonElement>;
    onHandleCreateAccount?: MouseEventHandler<HTMLButtonElement>;
    onHandleBrokerMatching?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddAccountBroker?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddOrEditNote?: MouseEventHandler<HTMLButtonElement>;
    onHandleViewOfacResults?: MouseEventHandler<HTMLButtonElement>;
    onHandleRunBot: MouseEventHandler<HTMLButtonElement>;
    onHandleViewBuildingDistributions: MouseEventHandler<HTMLButtonElement>;
    onHandleProducerSearch?: MouseEventHandler<HTMLButtonElement>;
    onHandleOpenAlternativeNames?: MouseEventHandler<HTMLButtonElement>;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    featureFlags: Types.FeatureFlags;
    tableData: PagedTableDataType;
    tableGlossary: TableGlossaryType;
    disableAllEdits: boolean;
    me: Record<string, any>;
    taskProgressInfo: TaskItem;
    onCreateOrUpdateArchAccount: () => void;
    onUnlinkArchAccount: (discardCurrentAccountData: boolean) => void;
    formMethods: UseFormReturn<FieldValues, any>;
    originatingUnderwriters: GetReferenceDataUnderwritersResultType[];
    assignedUnderwriters: GetReferenceDataUnderwritersResultType[];
    isOriginatingUWLoading: boolean;
    isAssignedUWLoading: boolean;
    getUnderwriterDetail: (
        id: number
    ) => QueryActionCreatorResult<
        QueryDefinition<
            string | number,
            BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
            'Underwriters',
            ReferenceDataUnderwritersResultType,
            'referenceDataApi'
        >
    >;
};

const INFO_BLOCKS: blockPropTypes[] = [
    CLEARANCE_INFOBLOCKS.broker,
    CLEARANCE_INFOBLOCKS.retail_broker,
    CLEARANCE_INFOBLOCKS.arch_broker,
    CLEARANCE_INFOBLOCKS.insured,
    CLEARANCE_INFOBLOCKS.dates,
    CLEARANCE_INFOBLOCKS.sov_information,
    CLEARANCE_INFOBLOCKS.general_notes,
    CLEARANCE_INFOBLOCKS.submission_assignment,
    CLEARANCE_INFOBLOCKS.ofac,
    CLEARANCE_INFOBLOCKS.target_terms,
    CLEARANCE_INFOBLOCKS.conflicting_information,
];

type stateTypes = {
    insuredEditableOverride: boolean;
};

export default class BottomPanelApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    constructor(props: propTypes) {
        super(props);

        this.state = {
            insuredEditableOverride: false,
        };
    }

    defs = processColumnsForInfoBlocks(CLEARANCE_INFOBLOCKS, this.props.tableGlossary, this.props.CONSTANTS);

    OTHER_REQUESTED_UNDERWRITER_FIELDS = ['aruao', 'arue', 'arufn', 'aruia', 'aruu', 'arupn'];
    OTHER_ORIGINATING_UNDERWRITER_FIELDS = ['aouao', 'aoue', 'aouu', 'aoufn'];
    FIELD_MAPPINGS = {
        // Maps to their equivalent fields Archlink
        aruao: 'administrativeOffice',
        aouao: 'administrativeOffice',
        arue: 'email',
        aoue: 'email',
        arufn: 'name',
        aoufn: 'name',
        aruia: 'isActive',
        aruu: 'userName',
        aouu: 'userName',
        arupn: 'phoneNumber',
    };

    selectChoices = <Value,>(choices: [Value, string][]) => {
        return choices.map(([value, display]) => ({ value, display }));
    };

    sortChoices = <Value,>(choices: Choice<Value>[]) => {
        return [...choices].sort(({ value: _va, display: displayA }, { value: _vb, display: displayB }) => {
            if (displayA < displayB) {
                return -1;
            } else if (displayA > displayB) {
                return 1;
            } else {
                return 0;
            }
        });
    };

    getProducers = () => {
        const account_agent_id = this.props.currentSubmission.account_agent_id;
        const officesForAgent = this.props.CONSTANTS.PRODUCERS_BY_PRODUCING_AGENT;
        if (!account_agent_id || !officesForAgent?.[account_agent_id]) {
            return [];
        }
        return this.sortChoices(this.selectChoices(officesForAgent[account_agent_id]));
    };

    getLicensedBrokersByOffice = () => {
        const {
            formMethods: { watch },
            CONSTANTS,
        } = this.props;
        const office = watch().pid as number;
        const licensedBrokersByOffice = CONSTANTS.LICENSED_BROKERS_BY_OFFICE;
        if (!office || !licensedBrokersByOffice?.[office]) {
            return [];
        }
        return this.sortChoices(this.selectChoices(licensedBrokersByOffice[office]));
    };

    getProducingAgents = () => {
        const { CONSTANTS } = this.props;
        return this.sortChoices(this.selectChoices(CONSTANTS.PRODUCING_AGENTS));
    };

    getFormattedUnderwriters = (underwriters: GetReferenceDataUnderwritersResultType[], isLoading: boolean): Types.DataInfoBoxSelectionType[] => {
        if (isLoading || !underwriters) {
            return [];
        }
        if (this.props.featureFlags.enable_ouw_global_list_ff) {
            return this.sortChoices(underwriters.map(uw => ({ value: parseInt(uw.userNumber ? uw.userNumber.substring(1) : '00000'), display: uw.name }))); //check when uw number is null
        } else {
            return this.sortChoices(underwriters.map(uw => ({ value: parseInt(uw.userNumber.substring(1)), display: uw.name })));
        }
    };

    switchOverride = () => {
        this.setState({ insuredEditableOverride: !this.state.insuredEditableOverride });
    };

    handleOnSave = (field: string, value: any) => {
        const { onSaveFn, currentSubmission, currentInbox } = this.props;
        this.context.analytics.track(VMAC_BOTTOM_PANEL_EVENT, {
            [field]: value,
            value_before: this.props.tableData.rowData[currentSubmission.id][field],
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
        onSaveFn(field, value);
    };

    handleSaveUnderwriterFields = async (underwriterId: number, fieldsToUpdate: string[]) => {
        const { getUnderwriterDetail } = this.props;

        if (!underwriterId) {
            return;
        }

        const underwriterDetail = (await getUnderwriterDetail(underwriterId)).data;
        if (underwriterDetail) {
            fieldsToUpdate.forEach(field => {
                const fieldName = this.defs[field]?.field_name;
                const formData = underwriterDetail[this.FIELD_MAPPINGS[field]];
                if (!formData) {
                    if (field === 'aruia' || field === 'aouia') {
                        this.handleOnSave(fieldName, false);
                    } else {
                        this.handleOnSave(fieldName, 'null@null.null'); // Doing this because Viewgridset does not like null or an empty string
                    }
                } else {
                    this.handleOnSave(fieldName, formData);
                }
            });
        }
    };

    submit = this.props.formMethods.handleSubmit(async data => {
        const {
            formMethods: {
                formState: { dirtyFields },
            },
        } = this.props;

        let shouldUpdateRequestedUwFields = dirtyFields['arui'] ? dirtyFields['arui'] : false;
        let shouldUpdateOriginalUwFields = dirtyFields['aoui'] ? dirtyFields['aoui'] : false;
        Object.keys(dirtyFields).forEach((field: string) => {
            const fieldName = this.defs[field]?.field_name;
            const formData = data[field];
            this.handleOnSave(fieldName, formData);
        });

        let underwriterId = null;
        let otherFieldsToUpdate: string[] = null;

        if (shouldUpdateRequestedUwFields) {
            underwriterId = data['arui'];
            otherFieldsToUpdate = this.OTHER_REQUESTED_UNDERWRITER_FIELDS;
            await this.handleSaveUnderwriterFields(underwriterId, otherFieldsToUpdate);
        }

        if (shouldUpdateOriginalUwFields) {
            underwriterId = data['aoui'];
            otherFieldsToUpdate = this.OTHER_ORIGINATING_UNDERWRITER_FIELDS;
            await this.handleSaveUnderwriterFields(underwriterId, otherFieldsToUpdate);
        }
    });

    renderBlocks() {
        const {
            ENV,
            taskProgressInfo,
            disableAllEdits,
            onSaveFn,
            CONSTANTS,
            currentSubmission,
            onHandleConflictResolution,
            onHandleAddOrEditNote,
            me,
            featureFlags,
            formMethods,
        } = this.props;

        const readonly = currentSubmission.props && !!currentSubmission.props.readonly;
        const blocks = [];

        for (let block of INFO_BLOCKS) {
            if (block.hideBlock && block.hideBlock(this.props)) {
                continue;
            }

            const isInsuredBlock = block.enableClientMatching && currentSubmission.arch_account_id && currentSubmission.arch_account_arch_id;

            const customHeaderButtons = [];
            let { headerButtons } = block;
            if (headerButtons) {
                headerButtons = typeof headerButtons === 'function' ? headerButtons(this.props) : headerButtons;
                for (let i = 0; i < headerButtons.length; i++) {
                    const isDisabled = headerButtons[i].disabledFn && headerButtons[i].disabledFn(currentSubmission);
                    if (this.props[headerButtons[i].callbackFn] && headerButtons[i].buttonName && !isDisabled) {
                        customHeaderButtons.push(
                            <ThrottledButton
                                throttleSeconds={headerButtons[i].throttleSeconds}
                                key={i}
                                label={headerButtons[i].buttonName}
                                onClick={this.props[headerButtons[i].callbackFn]}
                            />
                        );
                    }
                }
            }

            if (!disableAllEdits && isInsuredBlock && onHandleConflictResolution && currentSubmission.is_editable) {
                const isEarlyAccount = featureFlags.enable_early_account_alt_names_ff;
                if (!this.state.insuredEditableOverride) {
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="edit"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: this.switchOverride,
                                confirmButtonTitle: 'Edit',
                                title: 'Edit this insured to update information',
                            }}
                        >
                            Edit
                        </ConfirmPromptButton>
                    );
                } else if (isEarlyAccount) {
                    // update
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="update"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: () => {
                                    this.switchOverride();
                                    this.props.onCreateOrUpdateArchAccount();
                                },
                                confirmButtonTitle: 'Update',
                                title: 'Are you sure you want to update this account?',
                            }}
                        >
                            Update
                        </ConfirmPromptButton>
                    );
                    // cancel and create a new account
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="cancel"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: () => {
                                    this.switchOverride();
                                    this.props.onUnlinkArchAccount(true);
                                },
                                confirmButtonTitle: 'Confirm',
                                title: 'Cancel reverts to empty account fields. Do you want to proceed?',
                            }}
                        >
                            Cancel
                        </ConfirmPromptButton>
                    );
                }
            }

            const isAssignmentBlock = block.header === 'Assignment';
            const isConflictingInfoBlock = block.header === 'Conflicting Info';
            const isBrokerBlock = block.header === 'Broker';
            const isRetailBrokerBlock = block.type === 'Retail';
            const isDatesBlock = block.header === 'Dates';
            const smmsSubmissionFlag = currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS;

            const disabled =
                (disableAllEdits && !isAssignmentBlock && !isConflictingInfoBlock && !taskProgressInfo?.in_progress) ||
                me.is_underwriter_assistant ||
                (smmsSubmissionFlag && !isAssignmentBlock && !isBrokerBlock) ||
                (currentSubmission.is_renewal_linked && !isAssignmentBlock && !isBrokerBlock && !isDatesBlock && !(block.header === 'Insured'));

            // disable adding notes if it's an SMMS submission
            if (smmsSubmissionFlag) {
                block.showAddRowIcon = false;
                block.alwaysShowAddRowIcon = false;
            }
            // add extraBeforeElement as an indicator for newly created arch account
            let extraBeforeElement = null;
            if (
                currentSubmission.arch_account &&
                currentSubmission.arch_account.arch_id === null &&
                currentSubmission.arch_account.status === null &&
                typeof currentSubmission.arch_clearance_api_submission_id !== undefined &&
                currentSubmission.status === 'U'
            ) {
                const content = 'Creating New Account';
                extraBeforeElement = (
                    <Tooltip content={content} attachment="right">
                        <div className="status-badge" style={{ minWidth: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                            {content}
                        </div>
                    </Tooltip>
                );
            }

            // Broker infoblock uses DataInfoBlock for underwriter list
            if (isBrokerBlock && featureFlags.enable_uw_list_ff && !currentSubmission.request_underwriter_id) {
                const {
                    assignedUnderwriters,
                    originatingUnderwriters,
                    isAssignedUWLoading,
                    isOriginatingUWLoading,
                    formMethods: {
                        formState: { errors },
                    },
                } = this.props;

                blocks.push(
                    <FormProvider {...formMethods} key={block.header}>
                        <DataInfoBlock
                            header="Broker"
                            active={true}
                            errors={errors}
                            className="tw-grid tw-grid-cols-2 tw-gap-1"
                            glossary={this.defs}
                            readonly={false}
                            headerButtons={customHeaderButtons}
                            showError={false}
                        >
                            <FormInput name="panp" className="border-0 tw-bg-white" disabled />
                            <FormSelect name="pai" selections={this.getProducingAgents()} rules={{ required: !isRetailBrokerBlock }} disabled />
                            <FormSelect
                                name="lai"
                                selections={this.getLicensedBrokersByOffice()}
                                nullLabel={CHOOSE_A_VALUE}
                                onSubmit={this.submit}
                                className={!isRetailBrokerBlock && !currentSubmission.licensed_broker_id ? 'tw-bg-[#fed89f]' : ''}
                            />
                            <FormInput name="pppc" className="tw-bg-white" disabled />
                            <FormSelect
                                name="pid"
                                selections={this.getProducers()}
                                nullLabel={CHOOSE_A_VALUE}
                                onSubmit={this.submit}
                                rules={{ required: !isRetailBrokerBlock }}
                            />
                            <FormSelect
                                name="arui"
                                selections={this.getFormattedUnderwriters(assignedUnderwriters, isAssignedUWLoading)}
                                nullLabel={CHOOSE_A_VALUE}
                                onSubmit={this.submit}
                                rules={{ required: true }}
                            />
                            <FormSelect
                                name="aoui"
                                selections={this.getFormattedUnderwriters(originatingUnderwriters, isOriginatingUWLoading)}
                                nullLabel={CHOOSE_A_VALUE}
                                onSubmit={this.submit}
                                rules={{ required: true }}
                            />
                            <FormInput name="rte" rules={{ required: true }} onBlur={this.submit} />
                        </DataInfoBlock>
                    </FormProvider>
                );
            } else {
                blocks.push(
                    <InfoBlock
                        ENV={ENV}
                        key={block.header}
                        rowFn={block.rowFn}
                        disabled={disabled}
                        readonly={!onSaveFn || readonly || disabled}
                        glossary={this.defs}
                        rowObjectGetter="currentSubmission"
                        parentProps={this.props}
                        customHeaderButtons={customHeaderButtons.length ? <div className="header-buttons">{customHeaderButtons}</div> : null}
                        {...block}
                        extraData={{ ...this.state, me: this.props.me, featureFlags }}
                        helperFunctions={{
                            handleSave: this.handleOnSave,
                            handleAddRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                            handleEditRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                            handleOpenAlternativeNames: this.props.onHandleOpenAlternativeNames,
                        }}
                        extraBeforeElement={rowKey => (rowKey === 'aanid' ? extraBeforeElement : null)}
                    />
                );
            }
        }

        return blocks;
    }

    render() {
        let { header, currentSubmission } = this.props;

        if (!currentSubmission) {
            return null;
        }

        return (
            <React.Fragment>
                <If condition={header}>
                    <div className="abs top-left flex handles">
                        <div className="panel-label handle">
                            <div className="text">{header}</div>
                        </div>
                    </div>
                </If>
                <div className="panel-area w-padding">
                    <div className="grid-layout gl-2" key="infoblocks">
                        {this.renderBlocks()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
