import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '.';
import api from '../services/apiSlice';
import { processSubmissionValidations } from '../actions/SubmissionActions';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    matcher: api.endpoints.validate.matchFulfilled,
    effect: ({ meta, payload }, listenerApi) => {
        if (!payload.validations) {
            return;
        }

        // dispatch validations to redux state for legacy reasons
        const id = meta.arg.originalArgs.submissionId;
        processSubmissionValidations(listenerApi.dispatch as AppDispatch, {
            id,
            _validations: { ...payload.validations },
        });
    },
}) as TypedStartListening<RootState, AppDispatch>;

export default listenerMiddleware;
