import { commonApi } from '@archinsurance-viki/property-jslib/src/services/commonApi';
import {
    BorDataQueryOptions,
    GetBorResultType,
    GlobalStatusResultType,
    UserSettingQueryOptions,
    ValidationQueryOptions,
    ValidationQueryResult,
    BorMetadataResultType,
    EmailTemplateResultType,
    GetEmailTemplateQueryOptions,
    UpdateBorMetadataQueryOptions,
    PutEmailTemplateMutationOptions,
    PutResetEmailTemplateMutationOptions,
    GetAlternateNamesQueryOptions,
    PostAlternateNamesMutationOptions,
} from '../ts-types/ApiTypes';
import { TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { loadLiteTableRows } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { AlternateNameEditType } from '../components/common/modals/apps/AlternativesNamesApp';

type Result = any;
type QueryOptions = any;

interface ArchStatus {
    name: string;
    status: string;
}

interface ArchStatusResponse {
    account?: any[];
    api_status: 'ERROR' | 'IN_PROGRESS' | 'SUCCESS' | 'NOT_CALLED';
    arch_clearance_api_status: string;
    data_check: ArchStatus[];
    submission: ArchStatus[];
}

const apiWithTags = commonApi.enhanceEndpoints({
    addTagTypes: ['ArchStatus', 'SubmissionValidation', 'Producer', 'ProducerContact', 'BorMetadata', 'EmailTemplate', 'AlternateNames'],
});

const api = apiWithTags.injectEndpoints({
    endpoints: builder => ({
        updateUserSettings: builder.mutation<Result, UserSettingQueryOptions>({
            query: ({ data }) => ({ url: `v1/user_settings/toggle_left_panel/`, method: 'POST', data }),
        }),
        getGlobalStatus: builder.query<GlobalStatusResultType, QueryOptions>({
            query: () => ({ url: `v1/global/status`, method: 'GET' }),
        }),
        validate: builder.query<ValidationQueryResult, ValidationQueryOptions>({
            query: ({ submissionId }) => ({ url: `v1/submission_data/${submissionId}/validate/`, method: 'GET' }),
            providesTags: (_result, _err, { submissionId }) => [{ type: 'SubmissionValidation', id: submissionId }],
        }),
        getBorData: builder.query<GetBorResultType, BorDataQueryOptions>({
            query: ({ submissionId }) => ({
                method: 'GET',
                url: `v1/submission/${submissionId}/get_bor_data/`,
            }),
        }),
        getDuplicates: builder.query<any, any>({
            query: ({ submissionId }) => ({ url: `v1/conflicts?submission_id=${submissionId}`, method: 'GET' }),
            providesTags: (_result, _err, { submissionId }) => [{ type: 'SubmissionDuplicates', id: submissionId }],
        }),
        searchProducers: builder.query<
            any,
            { name: string; subdivisions: string; submission_id: number; state: string; city: string; searchApprovedOnly: boolean }
        >({
            query: ({ name, subdivisions, submission_id, state, city, searchApprovedOnly }) => ({
                url: 'v1/producer/search_producer/',
                method: 'GET',
                params: {
                    name,
                    subdivisions,
                    submission_id,
                    state,
                    city,
                    searchApprovedOnly,
                },
            }),
            providesTags: ['Producer'],
        }),
        searchProducerContacts: builder.query<any, { name: string; subdivisions: string; submission_id: number; producer_id: string }>({
            query: ({ name, subdivisions, submission_id, producer_id }) => ({
                url: 'v1/producer/search_producer_contact/',
                method: 'GET',
                params: {
                    name,
                    producer_id,
                    subdivisions,
                    submission_id,
                },
            }),
            providesTags: ['ProducerContact'],
        }),
        getArchStatus: builder.query<ArchStatusResponse, QueryOptions>({
            query: ({ submissionId }) => ({ url: `v1/arch_status/${submissionId}`, method: 'GET' }),
            providesTags: (_result, _err, { submissionId }) => [{ type: 'ArchStatus', id: submissionId }],
        }),
        getImageRightLog: builder.query<{ results: TableRowDataType[] }, { submission_id: number }>({
            query: ({ submission_id }) => ({ url: `v1/imageright_request/`, method: 'GET', params: { submission_id } }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(loadLiteTableRows(LITE_TABLES.IMAGERIGHTLOGS, data.results));
            },
        }),
        borApproval: builder.query<any, any>({
            query: ({ id, action }) => ({ url: `v1/pending_bor_approval_decision/${id}/?action=${action}`, method: 'GET' }),
        }),
        getBorMetadata: builder.query<BorMetadataResultType, any>({
            query: ({ metadata_id }) => ({ url: `v1/bor_decision/${metadata_id}/`, method: 'GET', hideAllModalErrors: true }),
            providesTags: (_result, _err, { metadata_id }) => [{ type: 'BorMetadata', id: metadata_id }],
        }),
        updateBorMetadata: builder.mutation<BorMetadataResultType, UpdateBorMetadataQueryOptions>({
            query: ({ metadata_id, data }) => ({ url: `v1/bor_decision/${metadata_id}/`, method: 'PATCH', data }),
            invalidatesTags: (_result, _err, { metadata_id }) => [{ type: 'BorMetadata', id: metadata_id }],
        }),
        approveBor: builder.mutation<any, UpdateBorMetadataQueryOptions>({
            query: ({ metadata_id, data }) => ({ url: `v1/bor_decision/${metadata_id}/approve/`, method: 'POST', data }),
            invalidatesTags: ['BorMetadata'],
        }),
        declineBor: builder.mutation<any, UpdateBorMetadataQueryOptions>({
            query: ({ metadata_id, data }) => ({ url: `v1/bor_decision/${metadata_id}/decline/`, method: 'POST', data }),
            invalidatesTags: ['BorMetadata'],
        }),
        actionReport: builder.query<any, any>({
            query: ({ start, end, units, action }) => ({ url: `v1/action_report?start=${start}&end=${end}&action=${action}&units=${units}`, method: 'GET' }),
        }),
        getEmailTemplate: builder.query<EmailTemplateResultType, GetEmailTemplateQueryOptions>({
            query: ({ action, submission_id }) => ({ url: `v1/email/template/${action}/${submission_id}`, method: 'GET' }),
            providesTags: (_result, _err, { submission_id }) => [{ type: 'EmailTemplate', id: submission_id }],
        }),
        putEmailTemplate: builder.mutation<any, PutEmailTemplateMutationOptions>({
            query: ({ action, submission_id, subject, body }) => ({
                url: `v1/email/template/${action}/${submission_id}/`,
                method: 'PUT',
                data: {
                    submission_id,
                    action,
                    subject,
                    body,
                },
                showProgress: true,
                hideAllModalErrors: true,
            }),
            invalidatesTags: (_result, _err, { submission_id }) => [{ type: 'EmailTemplate', id: submission_id }],
        }),
        putResetEmailTemplate: builder.mutation<any, PutResetEmailTemplateMutationOptions>({
            query: ({ action, submission_id }) => ({
                url: `v1/email/template/${action}/${submission_id}/reset/`,
                method: 'PUT',
                data: {
                    submission_id,
                    action,
                },
                showProgress: true,
                hideAllModalErrors: true,
            }),
            invalidatesTags: (_result, _err, { submission_id }) => [{ type: 'EmailTemplate', id: submission_id }],
        }),
        getAlternateNames: builder.query<AlternateNameEditType[], GetAlternateNamesQueryOptions>({
            query: ({ submission_id }) => ({
                url: 'v1/arch_account/alternate_names/',
                method: 'GET',
                params: {
                    submission_id,
                },
            }),
            async onQueryStarted(_args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (data) {
                        dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, data));
                    } else {
                        dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, []));
                    }
                } catch (err) {
                    // Error is already reported in the UI
                    dispatch(loadLiteTableRows(LITE_TABLES.ALTERNATIVENAMES, []));
                }
            },
            providesTags: (result, error, { submission_id }) => [{ type: 'AlternateNames', id: submission_id }],
        }),
        postAlternateNames: builder.mutation<any, PostAlternateNamesMutationOptions>({
            query: ({ submission_id, edits }) => ({
                url: 'v1/arch_account/update_alternate_names/',
                method: 'POST',
                data: {
                    submission_id,
                    edits,
                },
                showProgress: true,
                hideAllModalErrors: true,
            }),
        }),
    }),
});

export default api;

export const {
    useGetArchStatusQuery,
    useGetDuplicatesQuery,
    usePrefetch,
    useUpdateUserSettingsMutation,
    useGetGlobalStatusQuery,
    useGetBorDataQuery,
    useValidateQuery,
    useSearchProducersQuery,
    useLazySearchProducersQuery,
    useLazySearchProducerContactsQuery,
    useGetImageRightLogQuery,
    useBorApprovalQuery,
    useGetBorMetadataQuery,
    useActionReportQuery,
    useGetEmailTemplateQuery,
    useUpdateBorMetadataMutation,
    useApproveBorMutation,
    useDeclineBorMutation,
    usePutEmailTemplateMutation,
    usePutResetEmailTemplateMutation,
    useGetAlternateNamesQuery,
    useLazyGetAlternateNamesQuery,
    usePostAlternateNamesMutation,
} = api;
