import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';

const SpinnerBody = () => {
    return (
        <div className="centered-modal centered-content standard-modal-content">
            <Spinner color="blue" size="lg" />
        </div>
    );
};

const modalTypeObj = {
    Body: SpinnerBody,
};

registerType(ICUBED_MODAL_TYPES.SPINNER, modalTypeObj);
export default modalTypeObj;
