/* clients */
export const SET_CURRENT_CLIENT_MATCHES = 'SET_CURRENT_CLIENT_MATCHES';

/* Inbox Types */
export const SET_CURRENT_INBOX = 'SET_CURRENT_INBOX';
export const RECEIVE_INBOXES = 'RECEIVE_INBOXES';
export const SET_REPLY_TEMPLATE = 'SET_REPLY_TEMPLATE';

/* Debug Types */
export const SET_GLOSSARIES = 'SET_GLOSSARIES';
export const SET_INSURED_REFERENCES = 'SET_INSURED_REFERENCES';

export const SET_ARCH_API_STATUS = 'SET_ARCH_API_STATUS';
