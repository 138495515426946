import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

import ImageRightLogApp from '../apps/ImageRightLogApp';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { COMBINED_TABLE_GLOSSARY } from '../../../../constants/CombinedTableGlossary';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

const ImageRightLog = (props: ownPropTypes) => {
    const dispatch = useAppDispatch();
    const CONSTANTS = useAppSelector(state => state.global.CONSTANTS);
    const tableData = useAppSelector(state => state.imageRightLogs);
    const tableGlossary = COMBINED_TABLE_GLOSSARY.submission;
    const onResetTable = useCallback(() => dispatch(resetTable(LITE_TABLES.IMAGERIGHTLOGS)), [dispatch]);

    return <ImageRightLogApp {...props} resetTable={onResetTable} tableData={tableData} CONSTANTS={CONSTANTS} tableGlossary={tableGlossary} />;
};

export default ImageRightLog;
