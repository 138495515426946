import * as React from 'react';

import { connect } from 'react-redux';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { SubmissionType, ApiStatusType, InboxType, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import AccountSearchApp from '../apps/AccountSearchApp';
import { getArchAccountMatches_server } from '../../../../actions/ClientActions';

import { MAXIMUM_CLIENT_MATCHES } from '../../../../constants/Constants';

import { Types } from '../../../../ts-types/icubed-types';
import { markRowAsSelected, getApiStatuses, selectArchAccountForSubmission } from '../../../../actions/ArchActions';
import { expandApiStatus } from '../../../../utils/account-and-submission-conflct-utils';
import { getCurrentInbox } from '../../../../utils/inbox-helpers';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

type propTypes = {
    onPersistRowData: (field: string, value: any) => void;
    onSearchArchAccounts: (name: string, street: string, fein: string, state: string) => PromiseDispatchType;
    onSelectNewAccount: (newClientId: string) => PromiseDispatchType;
    onGetAccountConflicts: (overrideId?: string) => PromiseDispatchType;
    onMarkRowAsSelected: (accountId: number) => void;
    resetTable: () => void;
    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    onGetApiStatuses: () => void;
    onSaveFn: (id: number, data: any) => void;
    currentInbox: InboxType;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, ownProps: ownPropTypes) => {
    const { ENV, CONSTANTS } = state.global;

    // this is needed here because it fixes the issue rows in the left grid of the insured
    // modal were not being selected.
    const currentSubmission = state.submissionsLog.rowData[ownProps.currentSubmission.id];
    const { apiStatuses } = currentSubmission;
    const currentInbox = getCurrentInbox(state);

    return {
        ENV,
        currentSubmission,
        CONSTANTS,
        currentInbox,
        uiState: state.uiState,
        tableData: state.accountSearchResults,
        _arch_integration:
            {
                submissionStatuses: expandApiStatus(apiStatuses && apiStatuses['submission'], CONSTANTS),
                accountStatuses: expandApiStatus(apiStatuses && apiStatuses['account'], CONSTANTS),
                apiStatuses,
            } || {},
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    // note, this tableData is parentTableData, not the tableData powering the litetable used in the modal.
    let { currentSubmission } = ownProps;
    return {
        onSearchArchAccounts: (name: string, street: string, fein: string, state: string) => {
            return dispatch(getArchAccountMatches_server(currentSubmission.id, { name, street, fein, state }, MAXIMUM_CLIENT_MATCHES));
        },
        onGetApiStatuses: () => {
            dispatch(getApiStatuses(currentSubmission.id));
        },
        onMarkRowAsSelected: (accountId: number) => {
            dispatch(markRowAsSelected(accountId));
        },

        onSelectNewAccount: (archId: string) => {
            return dispatch(selectArchAccountForSubmission(currentSubmission.id, archId));
        },
        resetTable: () => {
            dispatch(resetTable(LITE_TABLES.ACCOUNT_SEARCH_RESULTS));
        },
    };
};

class AccountSearch extends React.Component<propTypes> {
    render() {
        return <AccountSearchApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AccountSearch);
export default connected;
