import _ from 'lodash';
import moment from 'moment';
import { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { PANELS, LABEL_DATE_FORMAT } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import NavIcon from '@archinsurance-viki/property-jslib/src/components/widgets/NavIcon';
import VikiLogo from '@archinsurance-viki/property-jslib/src/components/widgets/VikiLogo';
import DebugInfo from '@archinsurance-viki/property-jslib/src/components/widgets/DebugInfo';
import SubmissionLinkOptionApp from '../common/modals/apps/SubmissionLinkOptionApp';
import OtherUsersViewingPanel from '@archinsurance-viki/property-jslib/src/components/panels/OtherUsersViewingPanel';
import { setUserSettings } from '@archinsurance-viki/property-jslib/src/actions/UserActions';
import { getAccountNumberFromString } from '@archinsurance-viki/property-jslib/src/utils/arch-helpers';

import { SubmissionType, UnderwritingPlatformType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { useUpdateUserSettingsMutation } from '../../services/apiSlice';
import { CLEARANCE_STATUS, INBOX_IDS } from '../../constants/Constants';
import { useCurrentSubmission } from '../../hooks/submission';
import { useAppSelector } from '../../hooks/redux';

type propTypes = {
    currentSubmission: SubmissionType;
    ENV: Types.Env;
    uiState: any;
    openPanels: any;
    featureFlags: Types.FeatureFlags;
    isFullScreenMode?: boolean;
    filename?: string;
    topPanelHeader?: string;
};

const TopPanel = (props: propTypes) => {
    const dispatch = useDispatch();
    const debugOpen = useAppSelector(state => state.uiState.TopBar.debugOpen);
    const userSettings = useSelector((state: Types.RootState) => state.global.userSettings);
    const sharedContext = useContext(AppContext);
    const [updateUserSettings /*{ isLoading: isUpdatingUserSettings }*/] = useUpdateUserSettingsMutation();
    const [isSIDOptionVisible, setIsSIDOptionVisible] = useState(false);
    const currentSubmission = useCurrentSubmission();
    const handleToggleLeftPanel = () => {
        // http://stackoverflow.com/a/31899998/237091
        sharedContext.onTogglePanel(PANELS.LEFT);
        setTimeout(() => {
            updateUserSettings({ data: { left_panel_opened: !props.openPanels[PANELS.LEFT] } }).then(({ data }: any) => {
                dispatch(setUserSettings([data]));
            });
        }, 500);
    };

    const renderSubmissionInfo = () => {
        const { ENV, featureFlags } = props;

        if (!currentSubmission || _.isEmpty(currentSubmission) || !currentSubmission.id) {
            return (
                <div className="submission-info">
                    <div className="status-area main-label">Submissions</div>
                </div>
            );
        }
        const { status, date_cleared, forwarded_by, date_forwarded, submission_cleared_by, last_user_action_failed, last_user_action_failed_action_type } =
            currentSubmission;
        const clearByInfo =
            submission_cleared_by && date_cleared ? `${moment(date_cleared).format(LABEL_DATE_FORMAT)} / ${submission_cleared_by}` : 'NOT YET CLEARED';
        const fwdByInfo = date_forwarded && forwarded_by ? `${moment(date_forwarded).format(LABEL_DATE_FORMAT)} / ${forwarded_by}` : 'NOT YET';
        const overViewUrl = `submissions/${currentSubmission.external_sid}/current/overview`;
        const archLink = `${ENV.ARCH_BASE_URL}BizShell/Web/Source/DomainShell.aspx?DomainID=1&AppID=${getAccountNumberFromString(
            currentSubmission.arch_clearance_api_submission_id
        )}`;

        let lastActionFailedBadge = null;
        const actionFailedDueToConflict = currentSubmission.arch_clearance_api_status === 'ACCOUNT_CONFLICT';
        if ((last_user_action_failed || actionFailedDueToConflict) && featureFlags.needs_reconciliation) {
            const isPendingSubmissionClear =
                currentSubmission.arch_clearance_api_status === 'PENDING_SUBMISSION_CLEAR' || currentSubmission.arch_clearance_api_status === 'PROCESSING';

            if (actionFailedDueToConflict) {
                lastActionFailedBadge = <div className="status-badge orange">{`CONFLICT DETECTED`}</div>;
            } else if (!isPendingSubmissionClear) {
                let lastFailedUserAction = {
                    [CLEARANCE_STATUS.CLEARED]: 'clear',
                    [CLEARANCE_STATUS.BLOCKED]: 'block',
                    [CLEARANCE_STATUS.DECLINED]: 'decline',
                }[last_user_action_failed_action_type];
                lastActionFailedBadge = <div className="status-badge red">{`FAILED ${lastFailedUserAction}`}</div>;
            }
        }

        let vikiUrl = null;
        let externalPlatformLabel = 'VIKI';
        if (currentSubmission.external_sid) {
            vikiUrl = ENV.VIKI_BASE_URL;
            if (featureFlags.write_to_rae_enabled && currentSubmission.underwriting_platform === UnderwritingPlatformType.POLICYWRITER) {
                externalPlatformLabel = 'RAE';
                vikiUrl = ENV.RAE_BASE_URL;
            }
            vikiUrl += `/${overViewUrl}`;
        }
        const smmsRenewalCheck = currentSubmission.inbox_id === INBOX_IDS.SMMS;
        const retailSubmissionCheck = currentSubmission.inbox_id === INBOX_IDS.RETAIL;
        const renewalText = smmsRenewalCheck ? 'Link Submission' : 'Link Renewal';
        const linkText = currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI ? 'VIKI SID' : 'ARCH ID';
        const linkValue =
            currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI
                ? currentSubmission.external_sid
                : currentSubmission.arch_clearance_api_submission_id;
        const renewalEnabled =
            currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI ? featureFlags.link_renewal_viki_ff : featureFlags.link_renewal_enabled;
        const isVikiRenewal =
            currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED &&
            !currentSubmission.is_archived &&
            !retailSubmissionCheck &&
            currentSubmission.underwriting_platform === UnderwritingPlatformType.VIKI &&
            featureFlags.link_renewal_viki_ff;
        const isSMMSRenewalDisabled = smmsRenewalCheck && featureFlags.enable_notouch_clearance;

        const getArchID = () => {
            if (
                currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED &&
                !currentSubmission.is_archived &&
                !retailSubmissionCheck &&
                !isSMMSRenewalDisabled &&
                renewalEnabled
            ) {
                return (
                    <>
                        <span className="info-label" data-testid="renewalTopPanelArchId">
                            {linkText}
                        </span>
                        <span className="info-data link-button" onClick={() => setIsSIDOptionVisible(!isSIDOptionVisible)}>
                            <button className="orange-light text-button">{linkValue || renewalText}</button>
                            <button className="orange icon-link small"></button>
                        </span>
                    </>
                );
            } else {
                if (isSIDOptionVisible) {
                    setIsSIDOptionVisible(false);
                }

                if (currentSubmission.arch_clearance_api_submission_id) {
                    return (
                        <>
                            <span className="info-label" data-testid="regularTopPanelArchId">
                                ARCH ID:
                            </span>
                            <span className="info-data">
                                <a className="text-button-simple" target="_blank" href={archLink}>
                                    {currentSubmission.arch_clearance_api_submission_id}
                                </a>
                            </span>
                        </>
                    );
                }

                return <></>;
            }
        };

        return (
            <div className="submission-info">
                <div className="submission-details">
                    <If condition={status !== undefined}>
                        <div className="status-badge">{currentSubmission.status_display}</div>
                    </If>
                    {lastActionFailedBadge}
                    <div className="submission-id-details">
                        <div className="info-figure">
                            <span className="info-label">ID:</span>
                            <span className="info-data">{currentSubmission.id}</span>
                        </div>
                        <If condition={currentSubmission.external_sid && !isVikiRenewal}>
                            <div className="info-figure">
                                <span className="info-label">{externalPlatformLabel} SID:</span>
                                <span className="info-data">
                                    <a className="text-button-simple" target="_blank" href={vikiUrl}>
                                        {currentSubmission.external_sid}
                                    </a>
                                </span>
                            </div>
                        </If>
                        <div className="info-figure">
                            {getArchID()}
                            <SubmissionLinkOptionApp
                                ENV={ENV}
                                isVikiRenewal={isVikiRenewal}
                                id={isVikiRenewal ? currentSubmission.external_sid : currentSubmission.arch_clearance_api_submission_id}
                                setVisibility={setIsSIDOptionVisible}
                                isVisible={isSIDOptionVisible}
                            />
                        </div>
                        <div className="info-figure">
                            <span className="info-label">DATE CLEARED / BY:</span>
                            <span className="info-data">{clearByInfo}</span>
                        </div>
                        <div className="info-figure">
                            <span className="info-label">DATE SENT / BY:</span>
                            <span className="info-data">{fwdByInfo}</span>
                        </div>
                    </div>
                </div>
                <If condition={currentSubmission.arch_account_name !== undefined}>
                    <div className="main-label ellipsize">{currentSubmission.arch_account_name}</div>
                </If>
            </div>
        );
    };

    const renderDocumentInfo = () => {
        const { date_forwarded } = currentSubmission;
        const fwdByInfo = date_forwarded ? `${moment(date_forwarded).format(LABEL_DATE_FORMAT)} ` : '';

        return (
            <div className="submission-info">
                <div className="submission-details">
                    <div className="status-badge">DOCUMENT VIEWER</div>
                    <div className="submission-id-details">
                        <div className="info-figure">
                            <span className="info-label">FILE NAME:</span>
                            <span className="info-data">{props.filename ?? ''}</span>
                        </div>
                        <div className="info-figure">
                            <span className="info-label">DATE ADDED:</span>
                            <span className="info-data">{fwdByInfo}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const submissionInfo = props.isFullScreenMode ? renderDocumentInfo() : renderSubmissionInfo();
    const currentViewers = props.currentSubmission._current_row_viewers;
    const displayFullHeader = currentSubmission && currentSubmission.status_display;

    return (
        <>
            <div
                key="header-main"
                className={classNames('header-main', {
                    'env-not-production': true, // TODO: showDebugPanel,
                })}
            >
                <div className="header-left" key="header-logo">
                    <NavIcon open={userSettings.left_panel_opened} onClick={handleToggleLeftPanel} />
                    <VikiLogo title="VMAC" subtitle="Clearance" />
                </div>
                <div className="header-right">
                    {props.topPanelHeader ? <div className="status-area main-label">{props.topPanelHeader}</div> : submissionInfo}
                    <If condition={displayFullHeader}>
                        <OtherUsersViewingPanel currentViewers={currentViewers} />
                    </If>
                </div>
            </div>
            <DebugInfo
                key="debug-info"
                ENV={props.ENV}
                open={props.uiState.TopBar.debugOpen}
                toggleDebugBar={() => sharedContext.setUiState({ debugOpen: !debugOpen }, 'TopBar')}
            />
        </>
    );
};

export default TopPanel;
