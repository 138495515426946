import * as React from 'react';
import _ from 'lodash';

import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import { SubmissionType, VoidFn } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { ButtonInput, TextInput, ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { Types } from '../../../../ts-types/icubed-types';

import { VMAC_BROKER_OFFICE_MATCHING_SEARCH_EVENT, VMAC_BROKER_OFFICE_MATCHING_SWITCHING_CLIENT } from '../../../../constants/AnalyticsConstants';

export type BrokerOfficeMathingAppProps = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
    onPersistRowData: (field: string, value: any) => void;
    onGetMatchedBrokerOffices: (s: string, usState: string) => void;
    resetTable: VoidFn;
    currentBrokerOfficeName: string;
    CONSTANTS: Types.Constants;
};

type stateTypes = {
    searchValue: string;
    state: string;
};

export default class BrokerOfficeMatchingApp extends React.Component<BrokerOfficeMathingAppProps, stateTypes> {
    static contextType = AppContext;

    constructor(props: BrokerOfficeMathingAppProps) {
        super(props);
        this.state = {
            searchValue: props.currentBrokerOfficeName,
            state: '',
        };

        this.getMatches();
    }

    componentWillUnmount() {
        this.props.resetTable();
    }

    handleCancel = () => {
        this.props.onClose(true);
    };

    getMatches = _.debounce(() => {
        this.props.onGetMatchedBrokerOffices(this.state.searchValue, this.state.state);
    }, 500);

    handleChange = (field: string, value: any) => {
        this.setState({ searchValue: value });
        this.getMatches();
        this.context.analytics.track(VMAC_BROKER_OFFICE_MATCHING_SEARCH_EVENT, { searchTerm: value });
    };

    handleChangeState = (_key: string, value: any) => {
        this.setState({ state: value });
        this.getMatches();
    };

    onSelectNewBrokerMatch = (newBrokerId: number) => {
        if (newBrokerId !== this.props.currentSubmission.office_id) {
            this.props.onPersistRowData('office_id', newBrokerId);
            this.context.analytics.track(VMAC_BROKER_OFFICE_MATCHING_SWITCHING_CLIENT, {
                oldBrokerId: this.props.currentSubmission.office_id,
                submission_id: this.props.currentSubmission.id,
                newBrokerId,
            });
        } else {
            console.log('Already selected');
        }

        this.props.onClose(true);
    };

    resetSearchValue = () => {
        this.setState({ searchValue: this.props.currentSubmission.office_name });
        this.getMatches();
    };

    render() {
        let { currentSubmission, tableData, CONSTANTS } = this.props;
        if (!currentSubmission) {
            return null;
        }
        return (
            <div data-testid="broker-office-matching-app">
                <div className="row padding-right">
                    <TextInput
                        maxLength={100}
                        className="client-matches-search grow label-above"
                        name="searchValue"
                        label="Broker Office Name (edit to do a new search)"
                        onEveryChange={this.handleChange}
                        object={this.state}
                    />
                    <ComboBoxInput
                        name="state"
                        label="Filter By State (Optional)"
                        className="search-bar  border-adjust label-above"
                        object={this.state}
                        onChange={this.handleChangeState}
                        selectProps={{ choices: CONSTANTS.STATES, nullable: true }}
                    />
                </div>
                <ButtonInput
                    label="Broker Office Name as parsed from email (click to reset search)"
                    className="matches-as-parsed label-above"
                    name="reset"
                    disabled={this.state.searchValue === currentSubmission.office_name}
                    onClick={this.resetSearchValue}
                    object={currentSubmission}
                />
                <LiteTableContainer
                    onRowSelected={null}
                    hasActionPanel={false}
                    tableConfig={LITE_TABLES.BROKEROFFICEMATCHES}
                    selectedRowId={currentSubmission.office_id}
                    containerClass="standard-modal-content"
                    tableData={tableData}
                    actionHelperFns={{
                        onSelectNewMatch: this.onSelectNewBrokerMatch,
                    }}
                    extraCellRenderData={{
                        currentSubmission,
                    }}
                />
            </div>
        );
    }
}
