import { TableConfigType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { LITE_TABLE_VIEW_SETTINGS } from './LiteTableViewSettings';
import { COMBINED_TABLE_GLOSSARY } from './CombinedTableGlossary';

export const LITE_TABLES: { [s: string]: TableConfigType } = {
    CLIENTMATCHES: {
        tableName: 'clientmatches',
        tableReducerPath: 'clientMatches',
        rowDataName: 'Client Matches',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.clientmatches,
        tableGlossary: COMBINED_TABLE_GLOSSARY.clientmatches,
    },
    BROKERMATCHES: {
        tableName: 'brokermatches',
        tableReducerPath: 'brokerMatches',
        rowDataName: 'Broker Matches',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.brokermatches,
        tableGlossary: COMBINED_TABLE_GLOSSARY.brokermatches,
    },
    BROKEROFFICEMATCHES: {
        tableName: 'brokerofficematches',
        tableReducerPath: 'brokerOfficeMatches',
        rowDataName: 'Broker Office Matches',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.brokerofficematches,
        tableGlossary: COMBINED_TABLE_GLOSSARY.brokerofficematches,
    },
    DUPLICATESUBMISSIONS: {
        tableName: 'duplicatesubmissions',
        tableReducerPath: 'duplicateSubmissions',
        rowDataName: 'Duplicate Submissions',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.duplicatesubmissions,
        tableGlossary: COMBINED_TABLE_GLOSSARY.duplicatesubmissions,
    },
    CONFLICTTABLE: {
        tableName: 'conflicttable',
        tableReducerPath: 'conflictTable',
        rowDataName: 'Conflict Table',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.conflicttable,
        tableGlossary: COMBINED_TABLE_GLOSSARY.conflicttable,
    },
    DELTARECORDS: {
        tableName: 'deltarecords',
        tableReducerPath: 'deltaRecords',
        rowDataName: 'Delta Records',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.deltarecords,
        tableGlossary: COMBINED_TABLE_GLOSSARY.deltarecords,
    },
    SUBMISSIONCONFLICTS: {
        tableName: 'submissionConflicts',
        tableReducerPath: 'submissionConflicts',
        rowDataName: 'Submission Conflicts',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.conflictingsubmissions,
        tableGlossary: COMBINED_TABLE_GLOSSARY.conflictingsubmissions,
    },
    ACCOUNTCONFLICTS: {
        tableName: 'accountConflicts',
        tableReducerPath: 'accountConflicts',
        rowDataName: 'Account Conflicts',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.accountconflicts,
        tableGlossary: COMBINED_TABLE_GLOSSARY.accountconflicts,
    },
    BORSUBMISSIONS: {
        tableName: 'borSubmissions',
        tableReducerPath: 'borSubmissions',
        rowDataName: 'BOR Submissions',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.borsubmissionmatches,
        tableGlossary: COMBINED_TABLE_GLOSSARY.borsubmissionmatches,
    },
    ACCOUNT_SEARCH_RESULTS: {
        tableName: 'clientmatches',
        tableReducerPath: 'clientMatches',
        rowDataName: 'Client Matches',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.accountsearchresults,
        tableGlossary: COMBINED_TABLE_GLOSSARY.clientmatches,
    },
    PRODUCER_SEARCH_RESULTS: {
        tableName: 'producersearchresults',
        tableReducerPath: 'producerSearchResults',
        rowDataName: 'Producer Matches',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.producersearchresults,
        tableGlossary: COMBINED_TABLE_GLOSSARY.producersearchresults,
    },
    IMAGERIGHTLOGS: {
        tableName: 'imagerightlogs',
        tableReducerPath: 'imagerightLogs',
        rowDataName: 'ImageRight Log',
        noActiveRow: true,
        columnsStatic: true,
        tableFitsColumns: true,
        dontUseURL: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.imagerightlogs,
        tableGlossary: COMBINED_TABLE_GLOSSARY.imagerightlogs,
    },
    ALTERNATIVENAMES: {
        tableName: 'alternativenames',
        tableReducerPath: 'alternativeNames',
        rowDataName: 'Alternative Names',
        noActiveRow: true,
        columnsStatic: false,
        tableFitsColumns: true,
        dontUseURL: true,
        noViewSettings: true,
        viewSettingsConfig: LITE_TABLE_VIEW_SETTINGS.alternativenames,
        tableGlossary: COMBINED_TABLE_GLOSSARY.alternativenames,
    },
};
