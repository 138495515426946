import * as React from 'react';
import ConflictsTableApp from '../apps/ConflictsTableApp';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    onClickDuplicateInTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickSubmissionIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickAccountIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
    onClickRenewalSIDInConflictTable: (sid: number, rowIndex: number, rowData: any) => void;
};

type headerPropTypes = {
    currentSubmission: SubmissionType;
};

class ConflictTableBody extends React.Component<bodyPropTypes> {
    render() {
        const { onClickSubmissionIDInConflictTable, onClickDuplicateInTable, onClickAccountIDInConflictTable, onClickRenewalSIDInConflictTable } = this.props;
        const props = {
            onClickSubmissionIDInConflictTable,
            onClickDuplicateInTable,
            onClickAccountIDInConflictTable,
            onClickRenewalSIDInConflictTable,
        };

        return <ConflictsTableApp {...props} />;
    }
}

class ConflictTableHeader extends React.Component<headerPropTypes> {
    render() {
        const { currentSubmission } = this.props;
        return DefaultHeaderStatic(`Conflicts for: ${currentSubmission.id}`, false);
    }
}

const modalTypeObj = {
    Body: ConflictTableBody,
    Header: ConflictTableHeader,
    className: 'conflict-submission-modal',
};

registerType(ICUBED_MODAL_TYPES.CONFLICT_TABLE, modalTypeObj);
export default modalTypeObj;
