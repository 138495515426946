import { API } from '@archinsurance-viki/property-jslib';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import { updateDataByPath, loadLiteTableRows } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import * as types from '../constants/ActionTypes';
import { LITE_TABLES } from '../constants/LiteTableConfigs';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';

export const setClientMatches = (accountId: number, accountStatues: any[], submissionStatuses: any[]) => {
    return {
        type: types.SET_ARCH_API_STATUS,
        accountId,
        accountStatues,
        submissionStatuses,
    };
};

export const getSubmissionConflicts = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            submissionId,
        };
        return API.doRequest(API.endpoints.arch.getSubmissionConflicts, request, dispatch).then(({ data }) => {
            dispatch(loadLiteTableRows(LITE_TABLES.SUBMISSIONCONFLICTS, data));
        });
    };
};

export const getAccountConflicts = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            submissionId,
            params: {},
            hideDialogForErrors: false,
        };
        return API.doRequest(API.endpoints.arch.getAccountConflicts, request, dispatch).then(({ data }) => {
            dispatch(loadLiteTableRows(LITE_TABLES.ACCOUNTCONFLICTS, data));
            return data;
        });
    };
};

export const getApiStatuses = (accountId: number): any => {
    return (dispatch: DispatchType) => {
        let request = {
            accountId,
        };
        return API.doRequest(API.endpoints.arch.getStatuses, request, dispatch).then(({ data: { api_status, data_check, account, submission } }) => {
            dispatch(
                updateDataByPath(PAGED_TABLES.SUBMISSIONS, `rowData.${accountId}`, {
                    apiStatuses: { account, submission, dataCheckStatuses: data_check, globalStatus: api_status },
                })
            );
        });
    };
};

export const markRowAsSelected = (rowId: number): any => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        const rows = getState().accountConflicts.rows;
        for (let i = 0; i < rows.length; ++i) {
            dispatch(
                updateDataByPath(LITE_TABLES.ACCOUNTCONFLICTS, `rowData.${rows[i]}`, {
                    selected: false,
                })
            );
        }
        return dispatch(
            updateDataByPath(LITE_TABLES.ACCOUNTCONFLICTS, `rowData.${rowId}`, {
                selected: true,
            })
        );
    };
};

export const markProducerRowAsSelected = (rowId: number): any => {
    return (dispatch: DispatchType, getState: () => Record<string, any>) => {
        const rows = getState().producerSearchResults.rows;
        for (let i = 0; i < rows.length; ++i) {
            dispatch(
                updateDataByPath(LITE_TABLES.PRODUCER_SEARCH_RESULTS, `rowData.${rows[i]}`, {
                    selected: false,
                })
            );
        }
        return dispatch(
            updateDataByPath(LITE_TABLES.PRODUCER_SEARCH_RESULTS, `rowData.${rowId}`, {
                selected: true,
            })
        );
    };
};

export const retrySubmissionWithArch = (submissionId: number): any => {
    return (dispatch: DispatchType) => {
        const request = { submissionId };
        return API.doRequest(API.endpoints.arch.retrySubmissionWithArch, request, dispatch);
    };
};

export const resolveArchAccountConflict = (submissionId: number, accountId: string) => {
    return (dispatch: DispatchType) => {
        const request = { submissionId, params: { accountId } };
        return API.doRequest(API.endpoints.arch.resolveAccountConflict, request, dispatch);
    };
};

export const selectArchAccountForSubmission = (submissionId: number, archAccountId: string) => {
    return (dispatch: DispatchType) => {
        const request = { params: { submissionId, arch_account_arch_id: archAccountId } };
        return API.doRequest(API.endpoints.submission.selectArchAccount, request, dispatch);
    };
};

export const deselectArchAccountForSubmission = (submissionId: number, discardCurrentAccountData: boolean) => {
    return (dispatch: DispatchType) => {
        const request = { params: { submissionId, discard_current_account_data: discardCurrentAccountData } };
        return API.doRequest(API.endpoints.submission.deselectArchAccount, request, dispatch);
    };
};

export const createOrUpdateArchAccountForSubmission = (submissionId: number) => {
    return (dispatch: DispatchType) => {
        const request = { params: { submissionId } };
        return API.doRequest(API.endpoints.archAccount.create_or_update_account, request, dispatch);
    };
};

export const searchArchSubmissions = (account_id: string) => {
    return (dispatch: DispatchType) => {
        const request = { params: { account_id } };
        return API.doRequest(API.endpoints.arch.searchArchSubmissions, request, dispatch).then(response => {
            dispatch(loadLiteTableRows(LITE_TABLES.BORSUBMISSIONS, response.data));
            return response.data;
        });
    };
};
