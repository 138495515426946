import BorDecisionEmailConfirmApp from '../apps/BorDecisionEmailConfirmApp';
import { ICUBED_MODAL_TYPES, BOR_DECISION_EMAIL_CONFIRM_LABEL } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderStatic } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { VoidFn } from '../../../../ts-types/DataTypes';

type bodyPropTypes = {
    confirmAction: VoidFn;
};

type headerPropTypes = {
    isApproval: boolean;
};

const BorDecisionEmailConfirmBody = (props: bodyPropTypes) => {
    return <BorDecisionEmailConfirmApp {...props} />;
};

const ModalHeader = (props: headerPropTypes) => {
    const headerLabel = props.isApproval ? BOR_DECISION_EMAIL_CONFIRM_LABEL.APPROVE : BOR_DECISION_EMAIL_CONFIRM_LABEL.DECLINE;
    return DefaultHeaderStatic(headerLabel);
};

const modalTypeObj = {
    Body: BorDecisionEmailConfirmBody,
    Header: ModalHeader,
    className: 'bor-decision-email-confirm-modal width-25',
};

registerType(ICUBED_MODAL_TYPES.BOR_DECISION_EMAIL_CONFIRM, modalTypeObj);
export default modalTypeObj;
