import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { useLazySearchProducerContactsQuery, useLazySearchProducersQuery } from '../../../../services/apiSlice';
import { selectProducer } from '../../../../actions/SubmissionActions';
import { useDebounceCallback } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { loadLiteTableRows, resetTable } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { LITE_TABLES } from '../../../../constants/LiteTableConfigs';
import LiteTableContainer from '@archinsurance-viki/property-jslib/src/containers/LiteTableContainer';
import { ComboBoxInput, TextInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import { useCurrentSubmission } from '../../../../hooks/submission';
import { markProducerRowAsSelected } from '../../../../actions/ArchActions';
import { Types } from '../../../../ts-types/icubed-types';
import { SUBDIVISION_CODES, ARCH_STATUSES } from '../../../../constants/Constants';
import { closeCenteredModal } from '@archinsurance-viki/property-jslib/src/actions/GlobalActions';

type ProducerQueryParam = {
    name: string;
    producer_id: string;
    subdivisions: string;
    state: string;
    city: string;
    searchApprovedOnly: boolean;
};

type ProducerSearchAppProps = {
    isContact: boolean;
    CONSTANTS: Types.Constants;
};

const ProducerSearchApp = (props: ProducerSearchAppProps) => {
    const currentSubmission = useCurrentSubmission();

    const getInitialSubdivision = () => {
        switch (currentSubmission.inbox_id) {
            case props.CONSTANTS.INBOX_IDS.RETAIL:
                return [SUBDIVISION_CODES.RETAIL_PROPERTY];
            case props.CONSTANTS.INBOX_IDS.VENTUS:
                return [SUBDIVISION_CODES.VENTUS_HO];
            case props.CONSTANTS.INBOX_IDS.ARCH_E_AND_S:
                return [SUBDIVISION_CODES.ES_PROPERTY];
        }
    };

    const { isContact } = props;

    const joinSubdivisions = (subdivisions: string[]) => {
        return subdivisions.join(',');
    };

    const SEARCH_PROGRESS_TEXT = isContact ? 'Searching Arch Producer Contacts' : 'Searching Arch Producers...';

    const initialName = isContact ? currentSubmission.contact_arch_id && currentSubmission.account_agent_name : currentSubmission.office_name;
    const [name, setName] = useState<string>(initialName);
    const [producerId, setProducerId] = useState<string>(currentSubmission.office_arch_id);
    const [selectedSubdivisions, setSelectedSubdivisions] = useState<string[]>(getInitialSubdivision());
    const [progressText, setProgressText] = useState<string>(SEARCH_PROGRESS_TEXT);
    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [state, setState] = useState({ value: null });
    const [city, setCity] = useState<string>(null);
    const [searchApprovedOnly, setSearchApprovedOnly] = useState<boolean>(true);
    const states = useAppSelector(states => states.global.CONSTANTS.STATES);
    const [params, setParams] = useState<ProducerQueryParam>({
        name: initialName,
        producer_id: currentSubmission.office_arch_id,
        subdivisions: joinSubdivisions(getInitialSubdivision()),
        state: state.value,
        city: city,
        searchApprovedOnly: searchApprovedOnly,
    });
    const [overrideParams, setOverrideParams] = useState({ overrideProducerNumber: '' });

    const searchQuery = isContact ? useLazySearchProducerContactsQuery : useLazySearchProducersQuery;
    const liteTable = isContact ? LITE_TABLES.PRODUCER_CONTACT_SEARCH_RESULTS : LITE_TABLES.PRODUCER_SEARCH_RESULTS;

    const dispatch = useAppDispatch();

    const [triggerSearch, { data: producers, isFetching }] = searchQuery();

    const tableData = useAppSelector(state => state.producerSearchResults);
    const businessSubdivisionCodes = useAppSelector(state => state.global.CONSTANTS.BUSINESS_SUB_DIVISION_CODES);
    const codeAppendedBusinessSubdivisions = useMemo(() => {
        const newSubdivisionCodes = [];
        businessSubdivisionCodes.map(subdivision => {
            newSubdivisionCodes.push({ value: subdivision.value, display: `${subdivision.display} - ${subdivision.value}` });
        });
        return newSubdivisionCodes;
    }, [businessSubdivisionCodes]);

    const subdivisionMappings = useMemo(() => {
        const mappings = {};
        if (codeAppendedBusinessSubdivisions) {
            codeAppendedBusinessSubdivisions.map(mapping => {
                mappings[mapping.value] = mapping.display;
            });
        }
        return mappings;
    }, [codeAppendedBusinessSubdivisions]);

    const updateName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        debouncedGetMatches({ ...params, name: event.target.value });
    };

    const updateProducerId = (event: ChangeEvent<HTMLInputElement>) => {
        setProducerId(event.target.value);
        debouncedGetMatches({ ...params, producer_id: event.target.value });
    };

    const updateCity = (event: ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        debouncedGetMatches({ ...params, city: event.target.value });
    };

    const updateState = (_key: string, value: string) => {
        setState({ value: value });
        debouncedGetMatches({ ...params, state: value });
    };

    const updateSearchApprovedOnly = () => {
        setSearchApprovedOnly(!searchApprovedOnly);
        debouncedGetMatches({ ...params, searchApprovedOnly: !searchApprovedOnly });
    };

    const selectSubdivision = (_key: string, value: string) => {
        const index = selectedSubdivisions.indexOf(value);
        if (index === -1) {
            const newSubdivisions = [...selectedSubdivisions, value];
            setSelectedSubdivisions(newSubdivisions);
            debouncedGetMatches({ ...params, subdivisions: joinSubdivisions(newSubdivisions) });
        }
    };

    const unselectSubdivision = (event: MouseEvent<HTMLButtonElement>) => {
        const id = event.currentTarget.id;
        const index = selectedSubdivisions.indexOf(id);
        if (index > -1) {
            const subdivisions = selectedSubdivisions.slice(0);
            subdivisions.splice(index, 1);
            setSelectedSubdivisions(subdivisions);
            debouncedGetMatches({ ...params, subdivisions: joinSubdivisions(subdivisions) });
        }
    };

    const getMatches = (newParams: ProducerQueryParam) => {
        setParams(newParams);
        triggerSearch({ submission_id: currentSubmission.id, ...newParams });
    };

    const renderSelectedSubdivisions = () => {
        return (
            <div className="tbl-attachment flex flex-row flex-wrap-reverse">
                {selectedSubdivisions.map(subdivision => (
                    <div key={subdivision} className="padding-right">
                        <button id={subdivision} className="blue padding-right" onClick={event => unselectSubdivision(event)}>
                            {subdivisionMappings[subdivision]} [X]
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    const onProducerOverrideChange = (key: string, value: string) => {
        setOverrideParams({ overrideProducerNumber: value });
    };

    const handleOverrideProducerId = () => {
        let archId = overrideParams.overrideProducerNumber;
        if (archId && archId.length > 1) {
            if (archId[0].toUpperCase() === 'P') {
                archId = archId.substr(1);
            }
            onSelectProducer(parseInt(archId), -1, { name: '', arch_id: archId });
            dispatch(closeCenteredModal());
        }
    };

    const renderSearchInputs = () => {
        const label = isContact ? 'Contact' : 'Producer';
        return (
            <div className="width-100 producer-search">
                <div className="width-100 flex row tw-space-x-4">
                    <div className={isContact ? 'width-50' : 'width-100'}>
                        <label>{label} Name</label>
                        <div className="tbl-attachment margin-right">
                            <div className="attached-item search-bar grow">
                                <div className="icon icon-search"></div>
                                <input placeholder="Name" type="text" value={name} onChange={event => updateName(event)} />
                            </div>
                        </div>
                    </div>
                    {isContact && (
                        <div className="width-50">
                            <label>Producer Number:</label>
                            <div className="tbl-attachment margin-right">
                                <div className="attached-item search-bar grow">
                                    <div className="icon icon-search"></div>
                                    <input placeholder="Producer ID" value={producerId} onChange={event => updateProducerId(event)} type="number"></input>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {!isContact && (
                    <div>
                        <div className="container tw-w-full flex tw-space-x-4 mb-4">
                            <div className="tw-flex-grow">
                                <label>State</label>
                                <div className="tbl-attachment margin-right">
                                    <div className="attached-item search-bar grow">
                                        <ComboBoxInput
                                            name="value"
                                            object={state}
                                            noWrapper={true}
                                            onChange={updateState}
                                            selectProps={{ choices: states, nullable: true }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tw-flex-grow">
                                <label>City:</label>
                                <div className="tbl-attachment margin-right">
                                    <div className="attached-item search-bar grow">
                                        <div className="icon icon-search"></div>
                                        <input placeholder="City" value={city} onChange={event => updateCity(event)} type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container tw-w-full flex tw-space-x-8 mb-4">
                            <label className="tw-flex tw-items-center tw-gap-2 align-middle">
                                Display Approved Producers Only:
                                <input type="checkbox" checked={searchApprovedOnly} onChange={updateSearchApprovedOnly}></input>
                            </label>
                            <div className="tw-flex-grow">
                                <label>Subdivisions:</label>
                                <div className="tbl-attachment margin-right">
                                    <div className="attached-item search-bar grow border-adjust">
                                        <ComboBoxInput
                                            name="Subdivisions"
                                            noWrapper={true}
                                            onChange={selectSubdivision}
                                            selectProps={{ choices: codeAppendedBusinessSubdivisions, nullable: true }}
                                            object={selectedSubdivisions}
                                        />
                                    </div>
                                    <button onClick={onSearch} className="grey-dark">
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex f-row override-arch-id tw-space-x-4">
                            <label>Override Arch Producer ID :</label>
                            <TextInput
                                errors={null}
                                object={overrideParams}
                                onEveryChange={onProducerOverrideChange}
                                name="overrideProducerNumber"
                                noWrapper={true}
                                disabled={currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                            />
                            <button
                                disabled={!overrideParams.overrideProducerNumber || currentSubmission.arch_clearance_api_status === ARCH_STATUSES.SUCCESS}
                                onClick={handleOverrideProducerId}
                                className="grey-dark"
                            >
                                Override
                            </button>
                        </div>
                    </div>
                )}
                {isContact && (
                    <div>
                        <label>Subdivisions:</label>
                        <div className="tbl-attachment margin-right">
                            <div className="attached-item search-bar grow border-adjust">
                                <ComboBoxInput
                                    name="Subdivisions"
                                    noWrapper={true}
                                    onChange={selectSubdivision}
                                    selectProps={{ choices: codeAppendedBusinessSubdivisions, nullable: true }}
                                    object={selectedSubdivisions}
                                />
                            </div>
                            <button onClick={onSearch} className="grey-dark">
                                Search
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const debouncedGetMatches = useDebounceCallback(getMatches, 500);

    const onSearch = useCallback(() => {
        triggerSearch({ submission_id: currentSubmission.id, ...params });
    }, [currentSubmission.id, params, triggerSearch]);

    const onSelectProducer = (newBrokerId: number, newRowIndex: number, newRowData: any) => {
        setProgressText(`Selecting P${newRowData.arch_id} - ${newRowData.name}`);
        setIsSelecting(true);
        return dispatch(selectProducer(currentSubmission.id, newRowData.arch_id))
            .then(() => {
                setIsSelecting(false);
                setProgressText(SEARCH_PROGRESS_TEXT);
            })
            .catch(_e => {
                setIsSelecting(false);
                setProgressText(SEARCH_PROGRESS_TEXT);
            })
            .then(() => dispatch(markProducerRowAsSelected(newBrokerId)));
        // TODO: track this event in mixpanel like we do with account selection and legacy broker selection
    };

    useEffect(() => {
        dispatch(loadLiteTableRows(liteTable, producers));

        return () => {
            dispatch(loadLiteTableRows(liteTable, []));
            resetTable(liteTable);
        };
    }, [producers, dispatch, liteTable]);

    useEffect(() => {
        onSearch();
    }, [onSearch]);

    return (
        <div className="expanded-modal-body">
            <div className="padding-standard">
                <div className="flex-table-row row clmn-headers padding-right">{renderSearchInputs()}</div>
            </div>

            <div className="divider-line"></div>

            <div className="padding-standard">
                <div className="width-100 clmn-headers">
                    <label>Selected Subdivisions:</label>
                    <div className="border padding-standard padding-bottom-none">{renderSelectedSubdivisions()}</div>
                </div>
            </div>

            <div className="divider-line"></div>

            <div className="grid-layout h-100 gl-1-force no-gap flex-1">
                <div>
                    <div className="clmn-headers padding-standard padding-bottom-none">
                        <label>ARCH PRODUCER DATA</label>
                    </div>
                    <LiteTableContainer
                        disableTable={isFetching || isSelecting}
                        onRowSelected={null}
                        hasActionPanel={false}
                        tableConfig={liteTable}
                        containerClass="standard-modal-content width-100 no-padding-top h-adjust"
                        tableData={tableData}
                        selectedRowId={1}
                        showProgressText={isFetching || isSelecting}
                        progressText={progressText}
                        disableTableWithoutProgress={false}
                        actionHelperFns={{
                            onSelectNewMatch: onSelectProducer,
                        }}
                        extraCellRenderData={{
                            currentSubmission,
                            field_name: 'arch_id',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProducerSearchApp;
