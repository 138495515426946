import { Route, Switch, Redirect } from 'react-router-dom';

import { AppConstants } from '@archinsurance-viki/property-jslib';
import DashboardContainer from './DashboardContainer';
import BorApproval from './bor_approval';
import BorDecisionApp from '../bor/BorDecisionApp';

import AnalyticsContainer from '../analytics/Analytics.container';

export default function DashboardRoutes() {
    return (
        <Switch>
            <Route path="/bor_approval" component={BorApproval} />
            <Route path="/bor_decision" component={BorDecisionApp} />
            <Route path="/analytics" component={AnalyticsContainer} />
            {/* TODO: `/dashboard/` seems to be a broken route, the submissionId should not be optional. fix in VENT-12838 */}
            <Route path="/dashboard/:submissionId?" render={routeProps => <DashboardContainer match={routeProps.match} history={routeProps.history} />} />
            <Redirect from={'/'} to={AppConstants.DEFAULT_URL} />
        </Switch>
    );
}
