import * as React from 'react';

import classNames from 'classnames';

type propTypes = {
    documents: {
        id: number;
        title: string;
    }[];
    onSelectDocument: (document: Record<string, any>, selectedDocIndex: number) => void;
    selectedDocumentIndex: number;
};

export default class DocumentsNav extends React.Component<propTypes> {
    render() {
        let { documents, selectedDocumentIndex } = this.props;

        if (!documents) return null;

        return (
            <div className="filter-panel" style={{ margin: '1rem' }}>
                {documents.map((doc, idx) => {
                    return (
                        <div
                            key={doc.id}
                            onClick={() => {
                                this.props.onSelectDocument(doc, idx);
                            }}
                            className={classNames('table-pref', { active: selectedDocumentIndex === idx })}
                        >
                            {doc.title}
                        </div>
                    );
                })}
            </div>
        );
    }
}
