import * as React from 'react';

interface PanelAreaProps {
    children: React.ReactNode;
}

const PanelArea = (props: PanelAreaProps) => {
    return (
        <div className="grid-layout gl-pml">
            <fieldset className="panel-inline tw-w-[180px]">
                <h5 className="panel-label-header w-100 no-border-right">Data Filters</h5>
                <div className={`panel-area flex tw-p-2 tw-w-full`}>{props.children}</div>
            </fieldset>
        </div>
    );
};

export default PanelArea;
