import moment from 'moment';

import { COMMON_COLUMNS_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ViewSettingsConfig } from '@archinsurance-viki/property-jslib/src/utils/tables/view-settings';

export const SUMMARY_CONFIGS = {};

export const DEFAULT_TABLE_COLUMNS = {
    submission: [
        's', // status
        'uwpf', // underwritng_platform
        'pppcid', // office_parent_company_id
        'pid', // office_id
        'pai', // account_agent_id
        'lai', // licensed_broker_id
        'iname', // insured_name
        'dr', // date_received
        'inboxsrc', // inbox_source
        'dti', // days_till_inception
        'bp', // bind_propensity
    ],
    retailSubmission: [
        's', // status
        'uwpf', // underwritng_platform
        'pppcid', // office_parent_company_id
        'rbon', // office_name
        'rpol', // office_location
        'iname', // insured_name
        'dr', // date_received
        'inboxsrc', // inbox_source
        'dti', // days_till_inception
        'bp', // bind_propensity
    ],
};

export const ALL_COLUMNS_LIST = {
    submission: [
        COMMON_COLUMNS_TYPES.ROW_SELECTION,
        'i', // id
        's', // status
        'ie', // is_editable
        'igp', // is_gateway_product
        'inboxid', // inbox_id
        'inboxsrc', // inbox_source
        'inboxsubd', // inbox_sub_division_id
        'lsbro', // last_sov_bot_received_on
        'luaf', // last_user_action_failed
        'luaft', // last_user_action_failed_type
        'pppcid', // office_parent_company_id
        'pppc', // office_parent_company
        'pid', // office_id
        'pn', // office_name
        'proaoi', // office_arch_id
        'proimod', // office_import_object_id
        'paiobjid', //account_agent_import_object_id
        'hs', // home_state
        'iname', // insured_name
        'archaname', //arch accout name
        'istreet', // insured_street
        'icity', // insured_city
        'istate', // insured_street
        'iloro', // insured_last_ofac_received_on
        'iz', // insured_zipcode
        'if', // insured_fein
        'dr', // date_received
        'dti', // days_till_inception
        'pafn', // account_agent_first_name
        'paln', // account_agent_last_name
        'pai', // account_agent_id
        'lai', // licensed_broker_id
        'lafn', // licensed_broker_first_name
        'laln', // licensed_broker_last_name
        'iinception', // inception_date
        'iexpiration', // expiration_date
        'ibd', // insured_description_of_operations
        'aanid', // arch_account_arch_id
        'inid', // arch_account_id
        'inp', // insured_name_as_parsed
        'ilosbn', // insured_last_ofac_submit_by_name
        'iloss', // insured_last_ofac_submit_status
        'panp', // account_agent_name_as_parsed
        'bs', // business_strategy
        'rte', // reply_to
        'fe', // from_email
        'fs', // from_subject
        'rue', // requested_underwriter_email
        'rui', // requested_underwriter_id
        'oui', // original_underwriter_id
        'arui', // arch_requested_underwriter_id
        'aoui', // arch_originating_underwriter_id
        'aoue', // arch_originating_underwriter_email
        'arue', // arch_requested_underwriter_email
        'aruao', // arch_requested_underwriter_administrative_office
        'aruu', // arch_requested_underwriter_username
        'aouu', // arch_originating_underwriter_username
        'aouao', // arch_originating_underwriter_administrative_office
        'aruia', // arch_requested_underwriter_is_active
        'arupn', // arch_requested_underwriter_phone_number
        'oue', // original_underwriter_email
        'ir', // is_renewal
        'exsid', // external_sid
        'hssre', // has_sent_submission_received_email
        'hssce', // has_sent_submission_cleared_email
        'hssbe', // has_sent_submission_blocked_email
        'hssbd', // has_sent_submission_declined_email
        'scd', // date_cleared
        'scb', // cleared_by
        'la', // last_action
        'ian', // is_attention_needed
        'lbl', // is_likely_blocked
        'irb', // is_replied
        'ifb', // is_forwarded
        'iab', // is_archived
        'idb', // is_deleted
        'ste', // is_submitted_too_early
        'hnmb', // has_new_message
        'pbs', // pending_bor_status
        'pbsid', // pending_bor_submission_id
        // 'pi', // office_id
        'atid', // assigned_to_id
        'arf', // assigned_research

        'bbo', // blocking_broker_office
        'bbfn', // blocking_broker_first_name
        'bbln', // blocking_broker_last_name
        'bdtr', // blocking_datetime_received
        'bin', // blocking_insured_name
        'btiv', // blocking_tiv
        'beffdt', // blocking_effective_date
        'besid', // blocking_external_id
        'bess', // blocking_external_submission_source
        'bdsi', // blocking_duplicate_submission_id
        'bbiss', // blocked_by_incumbent_submission_source
        // 'pai', // account_agent_id
        // 'istreet', // insured_street
        // 'icity', // insured_city
        // 'istate', // insured_state
        // 'iz', // insured_zipcode
        // 'if', // insured_fein
        'itarget', // insured_target_premium
        'irate', // insured_target_rate
        // 'iexpiration', // insured_expiration_date
        'inb', // insured_need_by_date

        'saa', // system_auto_action
        'sdpas', // system_determined_pas
        'sfd', // date_forwarded
        'sfb', // forwarded_by
        'sad', // date_archived
        'sab', // archived_by
        'sdd', // date_declined
        'sdb', // declined_by
        'srd', // date_replied
        'srb', // replied_by
        'sbd', // date_blocked
        'sbb', // blocked_by
        'sovt', // sov_building_tiv
        'sovtc', //sov_total_contents
        'sovtbi', // sov_total_bi
        'sovtt', //sov_total_tiv
        'sovbc', // sov_building_count
        'sovbt',
        'sovtype', //sov_type
        'apoc', // arch_account_passes_ofac_checks

        'arche', // arch_error
        'archw', // arch_warning
        'archst', // arch_status
        'archsid', // arch_submission_id
        'archchng', //arch_changed_on
        'archas', //arch_clearance_api_account_status
        'archloc', //last_archlink_ofac_check_at
        'archrcvd', //arch_received_on
        'archlc', // arch_clearance_api_last_call
        'uwpf', // underwritng_platform
        'bp', //bind_propensity
        'sup', // supported_uw_platforms
        'luref', // last_user_response_email_failed
        'ibid', // bor_incumbent_broker
        'nbid', // new_broker_id
        'sablock', // suggested_auto_block
        'saclear', // suggested_auto_clear
        'sadecline', // suggested_auto_decline
        'saarchive', // suggested_auto_archive
        'sovi', // sov_identification
        'uasa', // unactioned_auto_suggested_action
        'cai', // contact_arch_id
        'ipbr', // is_pending_bor
        'dbord', // datetime_bor_decisioned
        'dbori', // datetime_bor_initiated
        'dborap', // datetime_bor_approved
        'dborde', // datetime_bor_declined
        'dborewp', // datetime_end_of_bor_wait_period
    ],
    retailSubmission: [
        COMMON_COLUMNS_TYPES.ROW_SELECTION,
        'i', // id
        's', // status
        'ie', // is_editable
        'igp', // is_gateway_product
        'inboxid', // inbox_id
        'inboxsrc', // inbox_source
        'inboxsubd', // inbox_sub_division_id
        'lsbro', // last_sov_bot_received_on
        'luaf', // last_user_action_failed
        'luaft', // last_user_action_failed_type
        'pppcid', // office_parent_company_id
        'pppc', // office_parent_company
        'rbon', // office_name
        'rpol', // office_location
        'rbonp', // broker_office_name_as_parsed
        'rabcn', // arch_broker_contact_name
        'proaoi', // office_arch_id
        'proimod', // office_import_object_id
        'hs', // home_state
        'iname', // insured_name
        'archaname', //arch accout name
        'istreet', // insured_street
        'icity', // insured_city
        'istate', // insured_street
        'iloro', // insured_last_ofac_received_on
        'iz', // insured_zipcode
        'if', // insured_fein
        'dr', // date_received
        'dti', // days_till_inception
        'lafn', // licensed_broker_first_name
        'laln', // licensed_broker_last_name
        'iinception', // inception_date
        'iexpiration', // expiration_date
        'ibd', // insured_description_of_operations
        'aanid', // arch_account_arch_id
        'inid', // arch_account_id
        'inp', // insured_name_as_parsed
        'ilosbn', // insured_last_ofac_submit_by_name
        'iloss', // insured_last_ofac_submit_status
        'panp', // account_agent_name_as_parsed
        'bs', // business_strategy
        'rte', // reply_to
        'fe', // from_email
        'fs', // from_subject
        'rue', // requested_underwriter_email
        'rui', // requested_underwriter_id
        'oui', // original_underwriter_id
        'oue', // original_underwriter_email
        'ir', // is_renewal
        'exsid', // external_sid
        'hssre', // has_sent_submission_received_email
        'hssce', // has_sent_submission_cleared_email
        'hssbe', // has_sent_submission_blocked_email
        'hssbd', // has_sent_submission_declined_email
        'scd', // date_cleared
        'scb', // cleared_by
        'la', // last_action
        'ian', // is_attention_needed
        'lbl', // is_likely_blocked
        'irb', // is_replied
        'ifb', // is_forwarded
        'iab', // is_archived
        'idb', // is_deleted
        'ste', // is_submitted_too_early
        'hnmb', // has_new_message
        'pbs', // pending_bor_status
        'pbsid', // pending_bor_submission_id
        // 'pi', // office_id
        'atid', // assigned_to_id
        'arf', // assigned_research

        'bbo', // blocking_broker_office
        'bbfn', // blocking_broker_first_name
        'bbln', // blocking_broker_last_name
        'bdtr', // blocking_datetime_received
        'bin', // blocking_insured_name
        'btiv', // blocking_tiv
        'beffdt', // blocking_effective_date
        'besid', // blocking_external_id
        'bess', // blocking_external_submission_source
        'bdsi', // blocking_duplicate_submission_id
        'bbiss', // blocked_by_incumbent_submission_source
        // 'pai', // account_agent_id
        // 'istreet', // insured_street
        // 'icity', // insured_city
        // 'istate', // insured_state
        // 'iz', // insured_zipcode
        // 'if', // insured_fein
        'itarget', // insured_target_premium
        'irate', // insured_target_rate
        // 'iexpiration', // insured_expiration_date
        'inb', // insured_need_by_date

        'saa', // system_auto_action
        'sdpas', // system_determined_pas
        'sfd', // date_forwarded
        'sfb', // forwarded_by
        'sad', // date_archived
        'sab', // archived_by
        'sdd', // date_declined
        'sdb', // declined_by
        'srd', // date_replied
        'srb', // replied_by
        'sbd', // date_blocked
        'sbb', // blocked_by
        'sovt', // sov_building_tiv
        'sovtc', //sov_total_contents
        'sovtbi', // sov_total_bi
        'sovtt', //sov_total_tiv
        'sovbc', // sov_building_count
        'sovbt',
        'sovtype', //sov_type
        'apoc', // arch_account_passes_ofac_checks

        'arche', // arch_error
        'archw', // arch_warning
        'archst', // arch_status
        'archsid', // arch_submission_id
        'archchng', //arch_changed_on
        'archas', //arch_clearance_api_account_status
        'archloc', //last_archlink_ofac_check_at
        'archrcvd', //arch_received_on
        'archlc', // arch_clearance_api_last_call
        'uwpf', // underwritng_platform
        'bp', //bind_propensity
        'sup', // supported_uw_platforms
        'luref', // last_user_response_email_failed
        'ibid', // bor_incumbent_broker
        'nbid', // new_broker_id
        'sablock', // suggested_auto_block
        'saclear', // suggested_auto_clear
        'sadecline', // suggested_auto_decline
        'saarchive', // suggested_auto_archive
        'sovi', // sov_identification
        'uasa', // unactioned_auto_suggested_action
        'cai', // contact_arch_id
        'ipbr', // is_pending_bor
        'dbord', // datetime_bor_decisioned
        'dbori', // datetime_bor_initiated
        'dborap', // datetime_bor_approved
        'dborde', // datetime_bor_declined
        'dborewp', // datetime_end_of_bor_wait_period
    ],
};

// each item in tabsList here is extended with the main properties and becomes
const submission: ViewSettingsConfig = {
    type: 'tabs',
    columnDefaults: {
        w: 120,
        sortable: true,
        readonly: true,
        resizable: true,
    },
    allColumnsList: ALL_COLUMNS_LIST.submission,
    tablePropsDefaults: {
        summaryCount: true,
        // summaryData: ['total'],
        fixedColumnList: ['i'],
        columnList: DEFAULT_TABLE_COLUMNS.submission,
        sortColumn: 'dr',
        sortDirection: 'DESC',
        apiParams: {
            date_received__gte: moment().subtract(1, 'years').format('YYYY-MM-DD HH:m:s'),
        },
    },
    tabsList: [
        {
            name: 'All',
            id: 'all',
            tableProps: {
                filters: {
                    la: 'B__C__D__N__F__R__A__M__P__S__Z',
                },
            },
        },
        {
            name: 'Unprocessed',
            id: 'unprocessed',
            tableProps: {
                sortDirection: 'ASC',
                customFilterOptions: { s: null, ian: null },
                filters: {
                    ia: false,
                    ian: true,
                    uasa: false,
                },
            },
        },
        {
            name: 'Assigned',
            id: 'assigned',
            tableProps: {
                sortDirection: 'ASC',
                customFilterOptions: { s: null },
                filters: {
                    ia: true,
                    ian: true,
                },
            },
        },
        {
            name: 'Cleared',
            id: 'cleared',
            tableProps: {
                customFilterOptions: { s: null },
                filters: {
                    s: 'C',
                    iab: false,
                    ifb: false,
                },
            },
        },
        {
            name: 'Pending BOR',
            id: 'pending_bor',
            tableProps: {
                customFilterOptions: { s: null, ian: null },
                filters: {
                    ipbr: true,
                },
                fixedColumnList: ['i', 'pbsid', 'pbs', 'ibid', 'nbid', 'dbord', 'dbori', 'dborap', 'dborde', 'dborewp'],
            },
        },
        {
            name: 'Needs Reconciliation',
            id: 'needs_reconciliation',
            tableProps: {
                customFilterOptions: { s: null, ian: null },
                filters: {
                    nr: true,
                },
            },
        },
        {
            name: 'Blocked',
            id: 'blocked',
            tableProps: {
                customFilterOptions: { s: null },
                filters: {
                    s: 'B',
                    iab: false,
                    ifb: false,
                },
            },
        },
        {
            name: 'Declined',
            id: 'declined',
            tableProps: {
                customFilterOptions: { s: null },
                filters: {
                    s: 'D',
                    iab: false,
                    ifb: false,
                },
            },
        },
        {
            name: 'Replied',
            id: 'dialogue',
            tableProps: {
                customFilterOptions: { la: null },
                filters: {
                    irb: true,
                    iab: false,
                    ifb: false,
                },
            },
        },
        {
            name: 'Archived',
            id: 'archived',
            tableProps: {
                customFilterOptions: { la: null },
                filters: {
                    iab: true,
                },
            },
        },
        {
            name: 'Forwarded',
            id: 'forwarded',
            tableProps: {
                customFilterOptions: { la: null },
                filters: {
                    ifb: true,
                    iab: false,
                },
            },
        },
        {
            name: 'Pending Auto Actions',
            id: 'pending_auto_actions',
            tableProps: {
                sortDirection: 'ASC',
                filters: {
                    uasa: true,
                },
            },
        },
    ],
};

export const PAGED_TABLE_VIEW_SETTINGS = {
    submission,
};
