import * as React from 'react';

import { Types } from '../../../../ts-types/icubed-types';

import BrokerOfficeMatching from '../containers/BrokerOfficeMatching.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { LiteTableDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';

type bodyPropTypes = {
    ENV: Types.Env;
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    tableData: LiteTableDataType;
};

class BrokerOfficeMatchingBody extends React.Component<bodyPropTypes> {
    render() {
        return <BrokerOfficeMatching {...this.props} />;
    }
}

const modalTypeObj = {
    Body: BrokerOfficeMatchingBody,
    Header: DefaultHeaderWithProps('Broker Office Matching'),
    className: 'client-matching-modal',
};

registerType(ICUBED_MODAL_TYPES.BROKER_OFFICE_MATCHING, modalTypeObj);
export default modalTypeObj;
