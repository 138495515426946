import * as React from 'react';
import BuildingDistributions from '../containers/BuildingDistributions.container';
import { ICUBED_MODAL_TYPES } from '../../../../constants/Modals';
import { registerType } from '@archinsurance-viki/property-jslib/src/components/modals/CenteredModal';
import { DefaultHeaderWithProps } from '@archinsurance-viki/property-jslib/src/components/modals/types/DefaultModal';
import { SubmissionType } from '../../../../ts-types/DataTypes';

type bodyPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
};

class BuildingDistributionsBody extends React.Component<bodyPropTypes> {
    render() {
        return <BuildingDistributions {...this.props} />;
    }
}
const modalTypeObj = {
    Body: BuildingDistributionsBody,
    Header: DefaultHeaderWithProps('Building Distributions'),
    className: 'info-block-modal wide',
};

registerType(ICUBED_MODAL_TYPES.BUILDING_DISTRUBTIONS, modalTypeObj);
export default modalTypeObj;
