import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Types } from '../ts-types/icubed-types';

export const getTaskSubtitles = (currentTaskInfo: Record<string, any>, _env: Types.Env) => {
    let { submission_id } = currentTaskInfo;
    let subtitles = [];
    if (submission_id) {
        subtitles.push(<NavLink className="badge badge-primary" key="submission-link" to={`/dashboard/${submission_id}`}>{`SID ${submission_id}`}</NavLink>);
    }
    return subtitles;
};
